import { Subject } from './Subject';

class ActiveRoundUpdater extends Subject {
    constructor() {
        super();
        this.activeRound = this.getActiveRound();
    }

    setActiveRound(value) {
        const currentRound = this.getActiveRound();
        const newRound     = parseInt(value, 10) > currentRound ? parseInt(value, 10) : currentRound;

        if (newRound !== this.activeRound) {
            localStorage.setItem('active_round', newRound.toString());
            this.activeRound = newRound;
            this.notifyObservers(this.activeRound);
        }
    }

    getActiveRound() {
        const activeRound = localStorage.getItem('active_round');
        return activeRound && !isNaN(activeRound) ? parseInt(activeRound, 10) : 0;
    }
}

const activeRoundUpdaterInstance = new ActiveRoundUpdater();
export { activeRoundUpdaterInstance };
