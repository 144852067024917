<template>
  <div class="modal-rollback">
    <div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <h3>Verificar cartela</h3>
          <button type="button" @click="close">
            <h3>&#x2715;</h3>
          </button>
        </div>
      </div>
      <hr />
      <div class="form-group">
        <label for="cartela_id">Id da cartela</label>
        <input type="text" class="form-control" v-model="cartela_id" id="cartela_id" placeholder="Id da cartela" value=""
          required autocomplete="off" />
      </div>

      <div v-if="card && card.bola_cartelas.length > 0">
        <p>Usuário: {{ card.user.name }}</p>
        <p>Rodada: {{ card.partida_id }}</p>
        <p>Criado em: {{ card.created_at | hora_partida_especial }}</p>
        <div class="cards" style="height: unset;">
          <div class="card mt-2">
            <p class="card-id">ID: {{ card.bola_cartelas[0].cartela_id }}</p>
            <div class="card-numbers">
              <div class="card-line line-1">
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[0].status, 'number-red': !card.bola_cartelas[0].status }">
                  {{ card.bola_cartelas[0].bola }}</div>
                <div :class="{ 'number-black': card.bola_cartelas[1].status, 'number-red': !card.bola_cartelas[1].status }">
                  {{ card.bola_cartelas[1].bola }}</div>
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[2].status, 'number-red': !card.bola_cartelas[2].status }">
                  {{ card.bola_cartelas[2].bola }}</div>
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[3].status, 'number-red': !card.bola_cartelas[3].status }">
                  {{ card.bola_cartelas[3].bola }}</div>
                <div :class="{ 'number-black': card.bola_cartelas[4].status, 'number-red': !card.bola_cartelas[4].status }">
                  {{ card.bola_cartelas[4].bola }}</div>
                <div></div>
              </div>
              <div class="card-line line-2">
                <div :class="{ 'number-black': card.bola_cartelas[5].status, 'number-red': !card.bola_cartelas[5].status }">
                  {{ card.bola_cartelas[5].bola }}</div>
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[6].status, 'number-red': !card.bola_cartelas[6].status }">
                  {{ card.bola_cartelas[6].bola }}</div>
                <div :class="{ 'number-black': card.bola_cartelas[7].status, 'number-red': !card.bola_cartelas[7].status }">
                  {{ card.bola_cartelas[7].bola }}</div>
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[8].status, 'number-red': !card.bola_cartelas[8].status }">
                  {{ card.bola_cartelas[8].bola }}</div>
                <div></div>
                <div></div>
                <div :class="{ 'number-black': card.bola_cartelas[9].status, 'number-red': !card.bola_cartelas[9].status }">
                  {{ card.bola_cartelas[9].bola }}</div>
              </div>
              <div class="card-line line-3">
                <div></div>
                <div
                  :class="{ 'number-black': card.bola_cartelas[10].status, 'number-red': !card.bola_cartelas[10].status }">
                  {{ card.bola_cartelas[10].bola }}</div>
                <div></div>
                <div
                  :class="{ 'number-black': card.bola_cartelas[11].status, 'number-red': !card.bola_cartelas[11].status }">
                  {{ card.bola_cartelas[11].bola }}</div>
                <div
                  :class="{ 'number-black': card.bola_cartelas[12].status, 'number-red': !card.bola_cartelas[12].status }">
                  {{ card.bola_cartelas[12].bola }}</div>
                <div></div>
                <div
                  :class="{ 'number-black': card.bola_cartelas[13].status, 'number-red': !card.bola_cartelas[13].status }">
                  {{ card.bola_cartelas[13].bola }}</div>
                <div
                  :class="{ 'number-black': card.bola_cartelas[14].status, 'number-red': !card.bola_cartelas[14].status }">
                  {{ card.bola_cartelas[14].bola }}</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ message }}</p>
      </div>

      <hr />
      <div class="form-group">
        <button type="button" class="btn btn-success float-right" @click="sendCardVerify" :disabled="isDisabled">
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "CardVerify",
  data() {
    return {
      cartela_id: null,
      message: "",
      isDisabled: false,
      info: "",
      card: {
        bola_cartelas: [],
        user: []
      }
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  filters: {
    hora_partida_especial: function (date) {
      moment.locale("pt-br");
      // return moment(date).fromNow();
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
  },
  methods: {
    sendCardVerify: function () {
      this.message = "";
      if (this.cartela_id > 0) {
        this.$http.get("get-cartela/" + this.cartela_id).then((res) => {
          if (!res.data.errors) {
            this.card = res.data;
            if (res.data == '') {
              this.message = "Cartela não encontrada";
            }
          } else {
            console.error(res.data);
          }
        });
      } else {
        this.message = "Id da cartela invalido";
      }
    },

    close() {
      this.$parent.toggleCardVerifyModal();
    },
  },
};
</script>
