<template>
  <div class="painel painel-cards">
    <div class="row text-white" v-if="myCardsBuyAuto">
      <div class="col-12">
        <small>{{ $t("You have") }} {{ myCardsBuyAuto }} {{ $t("cards in automatic purchase") }}</small> <br>
        <small class="font-weight-bold"><a @click="cancelBuy" href="javascript:;" class="text-white">{{ $t("Cancel")
        }}</a></small>
      </div>
    </div>

    <div class="d-flex justify-content-between text-white mt-md-3 p-2">
      <b>{{ $t("Cards in play") }}</b>
      <span class="qtd-cartelas">{{ counterCardsThisRound }}</span>
    </div>

    <div class="cards" :class="{ 'cards-long': cardsNextRound === 0 }">
      <div v-for="(card, index) in myCards" :key="index" class="card mt-2" v-show="counterCardsThisRound > 0">
        <Card :numbers="card.bola_cartelas" :key="index" v-if="card.bola_cartelas" :highlight="arrayHighlight" />
      </div>
    </div>

    <div class="d-flex justify-content-between text-white mt-md-3 p-2" v-if="cardsNextRound > 0">
      <b>{{ $t("Cards next round") }}</b>
      <span class="qtd-cartelas">{{ cardsNextRound }}</span>
    </div>

    <div class="cards cardsNextRound"
         v-if="cardsNextRound > 0">
      <div v-for="(card, index) in myCardsNext"
           :key="index"
           class="card mt-2"
           v-show="cardsNextRound > 0">
        <div class="relative">
          <div class="opacity text-white"
               v-if="showCard != card.id || changinCard">
            <div>
              <span v-if="!changinCard || showCard != card.id">
                {{ $t("Purchase scheduled") }} <br> {{ $t("for round") }} {{ card.partida_id }}
              </span>
              <span v-if="changinCard && showCard == card.id">
                <i class="fas fa-sync fa-spin"></i> {{ $t("Changing numbers") }}
              </span>
              <a href="javascript:void(0)"
                 class="mt-2 d-block btn-show-card"
                 v-if="isChangingCardsAllowed && showCard != card.id"
                 @click="showCard = card.id">
                <i class="fas fa-search-plus"></i> {{ $t("Show card") }}
              </a>
            </div>
          </div>
          <Card :numbers="card.bola_cartelas"
                :key="index"
                v-if="card.bola_cartelas"
                :highlight="arrayHighlight"/>
        </div>
        <a href="javascript:void(0)"
           class="btn-change-cards"
           @click="changeCard(card.id)"
           v-if="isChangingCardsAllowed && showCard == card.id">
          <i class="fas fa-sync"></i>&nbsp;{{ $t("Change numbers") }}
        </a>
      </div>
    </div>

    <div class="card-purchase-dropdown dropdown-menu cancel-buy">
      <div>
        <p class="ml-0 mt-2 px-4 h5">
          <strong>{{ $t("Automatic purchase") }}</strong>
        </p>
        <p class="text-left ml-4">{{ $t("Do you want to cancel all automatic purchases for today?") }}</p>

        <button class="btn-close-modal" @click="cancelAction">
          &#x2715;
        </button>
      </div>

      <div class="row mt-2">
        <div class="col-12 px-5 mt-1 mb-3">
          <button class="btn btn-danger mr-3" @click="cancelAction">{{ $t("No") }}</button>
          <button class="btn btn-success" @click="confirmAction">{{ $t("Yes") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";
import {mapState} from "vuex";
import jQuery from "jquery";
import {EventBus} from "@/services/EventBus";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";
import Notification from "@/services/Notification";

export default {
  name: "Cards",
  props: ["round"],
  components: {
    Card,
  },
  computed: mapState(["user"]),
  data() {
    return {
      socket: null,
      myCards: [{}],
      myCardsNext: [{}],
      myCardsBuyAuto: false,
      counterCardsThisRound: 0,
      counterAllCards: 0,
      lastTransaction: 0,
      betterCard: null,
      roundActive: null,
      counter: 0,
      showCards: true,
      cardsNextRound: 0,
      statusAward: null,
      arrayHighlight: [],
      showBuyModal: false,
      showCard: 0,
      isChangingCardsAllowed: false,
      changinCard: false,
      totalCards: 0
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.roundActive = this.$helpers.getActiveRound();
  },
  mounted() {
    //Escuta o evento e executa o que tem que executar
    this.socket.on("bola-salva", (bola) => {
      this.$helpers.setRoundRunning(true);
      let isLive = localStorage.getItem('is-rng-running') == 0;
      setTimeout(() => {
        this.paintBall(bola);
        bola.lastBalls.forEach((ball) => {
          this.paintBall({
            bola: ball
          });
        });
      }, isLive ? 0 : 2200);
      
      this.highlightCard();
      this.sortCards();
    });

    // Remover destaque de cartelas quando tem ganhador
    this.socket.on("dispatch-winners", () => {
      setTimeout(() => {
        this.arrayHighlight = [];
      }, localStorage.getItem('is-rng-running') == 0 ? 0 : 3000);
    });

    //LISTA AS CARTELAS SE O USUARIO COMPRAR
    this.socket.on("list-cards", (obj) => {
      if (obj.userId == this.user.id) {
        this.listCards();
        EventBus.$emit("getBalance");
      }
    });

    //Zera as cartelas quando a partida for alterada
    this.socket.on("dispatch-round", () => {
      this.getRoundActive();
    });

    //Para limpar as cartelas quando avança a partida pelo dealer (Manual)
    this.socket.on("create-new-round", () => {
      this.totalCards = 0;
      this.$helpers.setRoundRunning(false);
      this.statusAward = "Bingo";
      this.adjustCards([
        {
          premiacao_tipo: {
            premiacao_tipo: "Bingo",
          },
        },
      ]);
    });

    //Para remover as cartelas se sair o bingo
    this.socket.on("status-plans", (obj) => {
      this.statusPlans(obj);
      setTimeout(() => {
        this.adjustCards(obj);
      }, 10000);
    });

    EventBus.$on('setIsChangingCardsAllowed', payload => {
      this.isChangingCardsAllowed = payload;
      
      if (!this.isChangingCardsAllowed) {
        this.showCard = 0;
      }
    });

    this.listCards();
    this.getAutomaticCards();
    this.getStatus();

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.counterAllCards = 0;
      this.statusAward     = null;
      this.getStatus();
      this.listCards();
      setTimeout(() => {
        this.statusAward = null;
        this.listCards();
      }, 5000);
      this.showCards   = true;
      this.roundActive = activeRound;
    });
  },
  methods: {
    cancelAction() {
      jQuery("#buy-alerts, .cancel-buy").fadeOut();
    },

    confirmAction() {
      jQuery("#buy-alerts, .cancel-buy").fadeOut();

      this.$emit('cancelBuyAutomatic', -1);
    },

    cancelBuy() {
      jQuery("#buy-alerts, .cancel-buy").fadeIn();
      jQuery('.cancel-buy').css({
        'position': 'fixed',
      });
    },

    //Método de remoção das cartelas caso saia o bingo
    adjustCards(obj) {
      let count = 0;
      if (obj.length > 0) {
        obj.forEach((item) => {
          this.statusAward = item.premiacao_tipo.premiacao_tipo;
          if (item.premiacao_tipo.premiacao_tipo == "Bingo") {
            this.counterCardsThisRound = 0;
            this.counterAllCards = 0;
            this.myCards.forEach((card) => {
              if (card.partida_id == this.$helpers.getActiveRound()) {
                count = count + 1;
              }
            });

            setTimeout(() => {
              this.myCards.forEach((card, index) => {
                if (card.partida_id == this.$helpers.getActiveRound()) {
                  this.myCards.splice(index, count);
                }
              }, 3000);
            });
          }
        });
      }
    },

    // PEGA A PARTIDA ATIVA
    async getRoundActive (attemptCount = 0) {
      return new Promise((resolve, reject) => {
        this.$http.get("partida-ativa")
          .then(response => {
            if (response.data.errors) {
              reject(response.data.errors);
            }
            this.$helpers.setActiveRound(response.data.id);
            this.roundActive = this.$helpers.getActiveRound();
            resolve(response);
          })
          .catch(error => {
            if (attemptCount < 5) {
              setTimeout(() => {
                this.getRoundActive(attemptCount + 1);
              }, 500);
            } else {
              console.error('Failed to get current round after 5 attempts');
              reject(error);
            }
          });
      });
    },

    async listCards(ballPassed = null, attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to list cards after 5 attempts');
        return;
      }

      this.$http
        .get("minhas-cartelas/" + this.$helpers.getActiveRound())
        .then((res) => {
          this.myCardsNext = [];
          let cards = res.data.data;
          if (cards.length > 0) {
            //Loop para percorrer array que vem do banco com as cartelas e bolas do jogador
            if (ballPassed) {
              cards.forEach((item, index) => {
                cards[index].bola_cartelas.forEach((ball, indexBall) => {
                  if (
                    ball.bola == ballPassed.bola &&
                    ball.partida_id == this.$helpers.getActiveRound()
                  ) {
                    cards[index].bola_cartelas[indexBall].status = 0;
                    cards[index].bolas_sorteadas =
                      res.data.data[index].bolas_sorteadas + 1;
                  }
                });
              });
            }

            //Se tiver saído o bingo, limpa as cartelas
            if (this.statusAward == "Bingo") {
              this.myCards = [];
              cards.forEach((item) => {
                if (item.partida_id != this.$helpers.getActiveRound()) {
                  this.myCardsNext.push(item);
                }
              });
            } else {
              //Senão inclui as cartelas no array
              this.myCards = [];
              this.myCardsNext = [];
              cards.forEach((item) => {
                if (item.partida_id == this.$helpers.getActiveRound()) {
                  this.myCards.push(item);
                } else {
                  this.myCardsNext.push(item);
                }
              });
            }
            // this.counter = cards.length + 1;
            this.lastTransaction = cards[cards.length - 1].id;

            if((this.myCards.length + this.myCardsNext.length) === this.totalCards) {
              this.updateCards();
            }
          } else {
            this.myCards = [{}];
          }
          
          this.listCardsNextRound(res.data.total);
          // this.getBalls();
        })
        .catch((error) => {
          console.error(error);
          
          setTimeout(() => {
            this.listCards(ballPassed, attemptCount + 1);
          }, 500);
        });
    },

    //Lista quantidade de cartelas vendidas na próxima rodada
    listCardsNextRound(counterAllCardsThisUser = 0, attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to list cards next round after 5 attempts');
        return;
      }
      
      this.$http
        .get("next-round-cards/" + (this.$helpers.getActiveRound() + 1))
        .then((res) => {
          this.cardsNextRound = parseInt(res.data);
          this.counterCardsThisRound = counterAllCardsThisUser - this.cardsNextRound;
          localStorage.setItem('cardsNextRound', res.data);
        })
        .catch((error) => {
          console.error(error);
          
          setTimeout(() => {
            this.listCardsNextRound(counterAllCardsThisUser, attemptCount + 1);
          }, 500);
        });
    },

    /**
     * Pinta a bola caso ela seja sorteada pelo dealer
     * */
    paintBall(bola) {
      try {
        let cards = this.myCards;
        cards.forEach((item, index) => {
          if (item.partida_id == this.$helpers.getActiveRound()) {
            item.bola_cartelas.forEach((item2, index2) => {
              if (bola.bola == item2.bola) {
                this.myCards[index].bola_cartelas[index2].status = 0;
              }
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    // Destacar cartela quando estiver faltando 1 bola para ganhar o premio
    highlightCard() {
      let cards = this.myCards;
      cards.forEach((item) => {
        if (item.partida_id == this.$helpers.getActiveRound()) {
          let line1 = 0;
          let line2 = 0;
          let line3 = 0;
          item.bola_cartelas.forEach((item2) => {
            if (
              item2.status == 0 &&
              item2.linha == 1 &&
              item.partida_id == this.$helpers.getActiveRound()
            )
              line1 += 1;

            if (
              item2.status == 0 &&
              item2.linha == 2 &&
              item.partida_id == this.$helpers.getActiveRound()
            )
              line2 += 1;

            if (
              item2.status == 0 &&
              item2.linha == 3 &&
              item.partida_id == this.$helpers.getActiveRound()
            )
              line3 += 1;
          });
          this.setCardHighlight(line1, line2, line3, item.id);
        }
      });
    },
    
    sortCards() {
      this.myCards = this.myCards.sort((a, b) => {
        let aDrawnBalls = a.bola_cartelas.filter(ball => ball.status === 0).length;
        let bDrawnBalls = b.bola_cartelas.filter(ball => ball.status === 0).length;
        
        return bDrawnBalls - aDrawnBalls;
      });
    },

    setCardHighlight(line1, line2, line3, id) {
      let lineByOne = 0;
      if (line1 >= 4) lineByOne += 1;
      if (line2 >= 4) lineByOne += 1;
      if (line3 >= 4) lineByOne += 1;

      let lineCompleted = 0;
      if (line1 == 5) lineCompleted += 1;
      if (line2 == 5) lineCompleted += 1;
      if (line3 == 5) lineCompleted += 1;

      if (lineByOne > 0) {
        if (this.statusAward == null && lineByOne >= 1 && lineCompleted == 0) {
          this.arrayHighlight.push(id);
        } else if (
          this.statusAward == "Linha" &&
          lineByOne >= 2 &&
          lineCompleted == 1
        ) {
          this.arrayHighlight.push(id);
        } else if (
          this.statusAward == "Linha Dupla" &&
          lineByOne == 3 &&
          lineCompleted == 2
        ) {
          this.arrayHighlight.push(id);
        }
      }
    },

    getAutomaticCards() {
      this.$http
        .post("compras-automatizada/automatic-cards")
        .then((res) => {
          this.myCardsBuyAuto = res.data.quantidade;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    buyCardsEvent() {
      this.$emit('buyCardsEvent');
    },

    updateCards() {
      this.totalCards = this.myCards.length + this.myCardsNext.length;
      setTimeout(() => {
        this.listCards();
      }, 1000);
    },

    getStatus() {
      this.$http.get("ganhadores").then(async (res) => {
        if (res.data.length > 0) {
          this.statusPlans(res.data);
        }
      });
    },

    statusPlans(status) {
      if (status.length > 0) {
        status.forEach((item) => {
          if (item.premiacao_tipo_id == 1) {
            this.statusAward = 'Linha';
          }
          if (item.premiacao_tipo_id == 2) {
            this.statusAward = 'Linha Dupla';
          }
          if (item.premiacao_tipo_id == 3) {
            this.statusAward = 'Bingo';
          }
          if (item.premiacao_tipo_id == 4) {
            this.statusAward = 'Acumulado';
          }
        });
      }
    },

    // getBalls() {
    //   const lastBalls = JSON.parse(localStorage.getItem('bola-sorteadas'));
    //   lastBalls.forEach((ball) => {
    //     this.paintBall({
    //       bola: ball
    //     });
    //   });
    // },

    showTextAutomatic(qtd) {
      this.myCardsBuyAuto = qtd;
    },

    changeCard(cardId) {
      this.changinCard = true;
      
      this.$http
          .post("change-card", {card_id: cardId})
          .then((res) => {
            if (res.data.errors) {
              this.showErrorModal(this.$t('Error while trying to change card'), this.$t('Try again later'));
                this.changinCard = false;
            } else {
              this.listCards();
              setTimeout(() => {
                this.changinCard = false;
              }, 1500);
            }
          })
          .catch(() => {
            this.showErrorModal(this.$t('Error while trying to change card'), this.$t('Try again later'));
            this.changinCard = false;
          });
    },
    
    showErrorModal(message, line2 = '') {
      Notification.error(
        message, line2
      );
    },
  },
};
</script>
