<template>
  <div v-if="user && roundActive">
    <notifications position="top center" width="100%" />

    <div class="container-game" @click="mobileFullscreen">
      <div id="mobile-fullscreen" v-show="isAndroid || isDesktop">
        <lottie-player autoplay
                       loop
                       mode="normal"
                       src="/lottie-animations/hand-click-animation.json"
                       class="mx-auto"
                       style="width: 150px"></lottie-player>
      </div>

      <div id="ios-device" v-show="isSafari">
        <div class="d-flex flex-column text-center justify-content-center align-itens-center p-2 position-relative">
          <strong>{{ $t("Before starting") }}</strong>

          <p class="my-2"><small>{{ $t("Try hiding toolbar") }}</small></p>

          <p class="close-symbol">&#x2715;</p>

          <div class="iphone">
            <div class="header">
              5:32
            </div>
            <div class="body">
              <p class="mt-4 px-2">{{ $t("Hide toolbar 1") }}</p>
              <p class="mt-4 px-2">{{ $t("Hide toolbar 2") }}</p>
              <div class="hide-bar mt-5">
                <p>{{ $t("Hide address") }}</p>
                <i class="pulse fa fa-expand" aria-hidden="true"></i>
              </div>
            </div>
            <div class="footer">
              <div class="address">
                <div class="pulse"><small>A</small>A</div>
              </div>
              <div class="icons d-flex justify-content-between mx-3 p-1 py-2">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                <i class="far fa-bookmark" aria-hidden="true"></i>
                <i class="far fa-clone"></i>
              </div>
              <div class="bar"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block container-logo text-center">
        <img src="~@/assets/images/logo-bingo.webp"
             class="img-responsive logo-bingo"
             alt="Bingo Everest">
      </div>

      <div class="container-rules" v-if="showRules">
        <Rules @toggleRules="toggleRules" />
      </div>

      <div class="container-header">
        <Header :round="roundActive"
                v-if="roundActive"
                :socket="socket"
                @toggleRules="toggleRules"
                @buyCardsEvent="buyCardsEvent"
                ref="header"/>
      </div>

      <div class="container-video">
        <Video :round="roundActive" :socket="socket" ref="video" />
      </div>

      <div class="container-info">
        <Info :round="roundActive" :socket="socket" />
      </div>

      <div class="container-acumulado">
        <Accumulated :round="roundActive" :socket="socket" />
      </div>

      <div class="container-proximo-premio">
        <NextRoundPrize :round="roundActive" :socket="socket" />
      </div>

      <div style="clear:both"></div>

      <div class="col-sm-12 container-lastballs">
        <LastBalls :round="roundActive" v-if="roundActive" :socket="socket" />
      </div>

      <div class="col-sm-12 container-cards">
        <Cards :round="roundActive" :socket="socket" ref="cards" @buyCardsEvent="buyCardsEvent"
          @cancelBuyAutomatic="cancelBuyAutomatic" />
      </div>

      <div class="px-3 container-buybuttons">
        <BuyButtons :round="roundActive" :socket="socket" ref="buyButtons" @buyCardsEvent="buyCardsEvent"
          @showTextAutomatic="showTextAutomatic" />
      </div>

      <div class="col-sm-12 container-chat pl-md-0">
        <Chat :socket="socket" />
      </div>

      <div class="container-lastwinners">
        <LastWinners :socket="socket" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import LastWinners from "../components/LastWinners.vue";
import Cards from "../components/Cards.vue";
import Video from "../components/Video.vue";
import LastBalls from "../components/LastBalls.vue";
import Info from "../components/Info.vue";
import Chat from "../components/Chat";
import BuyButtons from "../components/BuyButtons";
import Accumulated from "../components/Accumulated";
import NextRoundPrize from "../components/nextRoundPrize";
import {mapState} from "vuex";
import NoSleep from "nosleep.js";
import Rules from "../components/Rules.vue";
import jQuery from "jquery";
import "@lottiefiles/lottie-player";
import Socket from "@/services/Socket";
import SFX from "@/services/SFX";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";

export default {
  name: "Player",
  components: {
    Header,
    LastWinners,
    Cards,
    Video,
    LastBalls,
    Info,
    Chat,
    BuyButtons,
    Accumulated,
    NextRoundPrize,
    Rules
  },
  computed: {
    ...mapState(["user", "session"]),
  },
  data() {
    return {
      socket: null,
      roundActive: null,
      showRules: false,
      timeout: null,
      isAndroid: false,
      isSafari: false,
      isDesktop: false
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.roundActive = this.$helpers.getActiveRound();
    window.SFX = SFX;
    
    //SETA A PARTIDA QUANDO FOR ALTERADA NO DEALER
    this.socket.on("partida", (partida) => {
      //Compra cartelas toda vez que alterar a partida, isso se houverem compras no gatilho!
      if (partida.user.tipo == "administrador"
          && partida.user.name == "admin"
      ) {
        this.$helpers.setActiveRound(partida.partida);
        localStorage.setItem('bola-sorteadas', '[]');
      }
    });

    this.socket.on("create-new-round", () => {
      SFX.stopBackground();
      setTimeout(() => {
        SFX.rngNextVoice();
        SFX.playRng('rodada_iniciada');
       }, 1000);
       setTimeout(() => {
        SFX.playBingoMachine();
       }, 3000);
    });

    this.socket.on("set-partida-ativa", (message) => {
      this.$helpers.setActiveRound(message.partida);
    });

    this.socket.on("finished-round", () => {
      SFX.playRng('rodada_encerrada');
      SFX.stopBingoMachine();
    });

    // Verificar se existe outra sessão com o mesmo jogador
    this.socket.emit("verify-open-session", {
      room: "bingo",
      message: { user_id: this.user.id },
    });

    this.socket.on("verify-open-session", (message) => {
      if (message.user_id === this.user.id) {
        localStorage.removeItem("user");
        this.$router.push({name: "alert", query: {message: "O jogo está sendo utilizado em outra página."}});
      }
    });

    jQuery(window).on('resize', () => this.adjustInterface());
    setTimeout(() => {
      this.adjustInterface();
    }, 4500);

    // Adjust the interface when the timer is shown
    this.socket.on("show-timer", () => {
      let lastAdjustedRound = localStorage.getItem('lastAdjustedRound') || 0;
      
      if (this.roundActive > lastAdjustedRound) {
        localStorage.setItem('lastAdjustedRound', this.roundActive);
        this.adjustInterface();
      }
    });

    //Pega a partida ativa
    this.getRoundActive();

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.roundActive = activeRound;
    });
  },
  mounted() {
    /**
     * Este script serve para deixar a tela no mobile sempre ativa
    */
    var noSleep = new NoSleep();
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false
    );

    window.addEventListener('mousemove', this.resetTimeout, false);
    window.addEventListener('mousedown', this.resetTimeout, false);
    window.addEventListener('keypress', this.resetTimeout, false);
    window.addEventListener('touchmove', this.resetTimeout, false);
    window.addEventListener('scroll', this.resetTimeout, false);

    this.isAndroid = /android/i.test(this.getUserAgent()) && !localStorage.getItem('is_desktop');
    this.isSafari = (!!this.getUserAgent().match(/WebKit/))
            && !this.getUserAgent().match(/Chrome/)
            && !this.getUserAgent().match(/CriOS/);

    setTimeout(() => {
      this.addSoundToButtons();
    }, 100);

    localStorage.setItem('sound', false);
  },
  methods: {
    //PEGA A PARTIDA ATIVA
    getRoundActive() {
      this.$http.get("partida-ativa").then((res) => {
        this.$helpers.setActiveRound(res.data.id);
      });
    },

    buyCardsEvent() {
      this.$refs.cards.updateCards();
      this.$refs.video.listAllCards();
    },

    showTextAutomatic(qtd) {
      this.$refs.cards.showTextAutomatic(qtd);
    },

    cancelBuyAutomatic() {
      this.$refs.buyButtons.cancelBuyAutomatic();
    },

    mobileFullscreen() {
      if (document.getElementById("mobile-fullscreen")) {
        document.getElementById("mobile-fullscreen").remove();
        document.getElementById("ios-device").remove();
        localStorage.setItem('sound', false);

        try {
          // this.$refs.header.toggleVolume();

          if (window.embeddedPlayer) {
            window.embeddedPlayer.videojsPlayer.currentTime(0);
          }
        } catch (e) {
          console.log(e);
        }
        
        if (document.body.clientWidth < 768) {
          if (document.documentElement.requestFullscreen) document.documentElement.requestFullscreen();
          if (document.documentElement.mozRequestFullScreen) document.documentElement.mozRequestFullScreen();
          if (document.documentElement.webkitRequestFullscreen) document.documentElement.webkitRequestFullscreen();
          if (document.documentElement.msRequestFullscreen) document.documentElement.msRequestFullscreen();
          if (document.documentElement.webkitEnterFullscreen) document.documentElement.webkitEnterFullscreen();
        }
      }
    },

    toggleRules() {
      if (this.showRules) {
        jQuery("#winners").fadeOut();
      } else {
        jQuery("#winners").fadeIn();
      }
      this.showRules = !this.showRules;
    },

    resetTimeout() {
      if (this.session.casino_id > 1) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          window.location.href = "/idle?locale=" + this.session.locale;
        }, 900000);
      }
    },

    getUserAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },

    adjustInterface() {
      setTimeout(() => {
        if (document.body.clientWidth < 992) {
          this.adjustMobile();
        } else {
          this.adjustDesktop();
        }
      }, 250);
    },
    adjustMobile() {
      let containerInfo          = jQuery('.container-info');
      let containerAcumulado     = jQuery('.container-acumulado');
      let containerProximoPremio = jQuery('.container-proximo-premio');
      containerInfo.width('60%');
      containerAcumulado.width('calc(20%-5px)');
      containerProximoPremio.width('calc(20%-5px)');
      let totalWidth = containerInfo.outerWidth()
          + containerAcumulado.outerWidth()
          + containerProximoPremio.outerWidth()
          + 10;
      let windowWidth             = document.body.clientWidth;
      let marginLeftContainerInfo = (windowWidth - totalWidth) / 2;
      let topBottomVideoBar = jQuery('.container-header').outerHeight()
          + jQuery('.container-video').outerHeight()
          - 10;
      let style       = document.createElement('style');
      style.innerHTML = '@media (max-width: 992px) {' +
          '.container-info { margin-left: ' + marginLeftContainerInfo + 'px; }' +
          '.container-info, .container-acumulado, .container-proximo-premio { transition: all linear 0.3s; top: ' + topBottomVideoBar + 'px; }' +
        '}';
      document.head.appendChild(style);
    },
    
    adjustDesktop() {
      jQuery('.container-info,.container-acumulado,.container-proximo-premio').width('auto');
    },
    
    addSoundToButtons() {
      document.querySelectorAll('button').forEach(element => {
        element.addEventListener('click', (e) => {
          const classArray = e.target.classList.value.split(" ");
          if(!classArray.includes('button-send') && !classArray.includes('fa-paper-plane')) {
            SFX.playButton()
          }
        });
      });
    },
  },
};
</script>
