<template>
  <div class="ball-shadow">
    <div class="d-flex align-items-center justify-content-center bola-sorteada">
      {{ number }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Ball",
  props: ["number"]
};
</script>
