import { Howl } from 'howler';

class SFX {

    defaultVolume = 0.15;
    rngAudio = {};
    rngVolume = 0.5;
    rngVoices = [
        'marilia',
        'yasmine',
        'thais',
    ];
    playing = [];

    constructor() {
        setTimeout(() => {
            this.load();
            this.loadRNG();
        }, 3000);
    }

    load() {
        this.lines = this.init('/sfx/short-trumpet-fanfare.mp3');
        this.bingo = this.init('/sfx/winner-fanfare-with-cheers-1.mp3');
        this.success = this.init('/sfx/message-alert.mp3');
        this.error = this.init('/sfx/interface-error.mp3');
        this.button = this.init('/sfx/button-1.mp3');
        this.messageSent = this.init('/sfx/message-sent.mp3');
        this.notification = this.init('/sfx/notification.wav');
        this.countDown10s = this.init('/sfx/countdown10s.mp3');
        this.bingoMachine = this.init('/sfx/bingo-machine.opus');
        this.backgorund = this.init('/sfx/background.opus');
    }

    loadRNG() {
        this.rngVoices.forEach(voice => {
            this.rngAudio[voice] = {};

            for (let i = 1; i <= 90; i++) {
                this.rngAudio[voice][i] = this.init('/sfx/rng/' + voice + '/' + i + '_mono.mp3', this.rngVolume);
            }

            this.rngAudio[voice].curiosidade_1 = this.init('/sfx/rng/' + voice + '/curiosidade_1_mono.mp3', this.rngVolume);
            this.rngAudio[voice].curiosidade_2 = this.init('/sfx/rng/' + voice + '/curiosidade_2_mono.mp3', this.rngVolume);
            this.rngAudio[voice].curiosidade_3 = this.init('/sfx/rng/' + voice + '/curiosidade_3_mono.mp3', this.rngVolume);
            this.rngAudio[voice].curiosidade_4 = this.init('/sfx/rng/' + voice + '/curiosidade_4_mono.mp3', this.rngVolume);
            this.rngAudio[voice].curiosidade_5 = this.init('/sfx/rng/' + voice + '/curiosidade_5_mono.mp3', this.rngVolume);

            this.rngAudio[voice].comprem_suas_cartelas = this.init('/sfx/rng/' + voice + '/comprem_suas_cartelas_mono.mp3', this.rngVolume);
            this.rngAudio[voice].parabens_aos_ganhadores = this.init('/sfx/rng/' + voice + '/parabens_aos_ganhadores_mono.mp3', this.rngVolume);
            this.rngAudio[voice].rodada_encerrada = this.init('/sfx/rng/' + voice + '/rodada_encerrada_mono.mp3', this.rngVolume);
            this.rngAudio[voice].rodada_iniciada = this.init('/sfx/rng/' + voice + '/rodada_iniciada_mono.mp3', this.rngVolume);

            this.rngAudio[voice].linha = this.init('/sfx/rng/' + voice + '/linha_mono.mp3', this.rngVolume);
            this.rngAudio[voice].linha_dupla = this.init('/sfx/rng/' + voice + '/linha_dupla_mono.mp3', this.rngVolume);
            this.rngAudio[voice].bingo = this.init('/sfx/rng/' + voice + '/bingo_mono.mp3', this.rngVolume);
            this.rngAudio[voice].dez_segundos = this.init('/sfx/rng/' + voice + '/10_segundos_mono.mp3', this.rngVolume);
        });
    }

    isSoundEnabled() {
        return localStorage.getItem('sound') !== 'false';
    }

    init(src, volume = false) {
        return new Howl({
            src: [src],
            volume: volume || this.defaultVolume,
        });
    }

    play(sound, volume, loop) {
        try {
            let playVolume = this.isSoundEnabled() ? (volume || this.defaultVolume) : 0;

            sound.seek(0);
            sound.volume(playVolume);
            sound.loop(loop || false);
            sound.play();

            sound._bkp_volume = volume || this.defaultVolume;
            sound._bkp_loop = loop || false;

            this.playing.push(sound);

            sound.on('end', () => {
                if (!sound._bkp_loop) {
                    this.playing = this.playing.filter(s => s !== sound);
                }
            });
        } catch (e) {
            console.log('Error playing sound', e);
        }
    }

    playBingo() {
        //if (localStorage.getItem('is_live') == 0) return;
        this.play(this.bingo);
    }

    playWonLine() {
        //if (localStorage.getItem('is_live') == 0) return;
        this.play(this.lines);
    }

    playSuccessMessage() {
        this.play(this.success);
    }

    playErrorMessage() {
        this.play(this.error);
    }

    playButton() {
        this.play(this.button);
    }

    playMessageSent() {
        this.play(this.messageSent);
    }

    playNotification() {
        this.play(this.notification);
    }

    playCountDown10s() {
        this.play(this.countDown10s);
    }

    rngNextVoice() {
        let currentVoice = localStorage.getItem('voice') || this.rngVoices[0];
        let nextVoice = this.rngVoices[this.rngVoices.indexOf(currentVoice) + 1] || this.rngVoices[0];

        localStorage.setItem('voice', nextVoice);
    }

    playRng(sound) {
        let voice = localStorage.getItem('voice') || this.rngVoices[0];
        if (!this.rngAudio[voice][sound]
            || localStorage.getItem('is-rng-running') == 0
        ) {
            return;
        }

        this.play(this.rngAudio[voice][sound]);
    }

    playRngCuriosidade() {
        let options = [
            'curiosidade_1',
            'curiosidade_2',
            'curiosidade_3',
            'curiosidade_4',
            'curiosidade_5',
        ];

        this.playRng(
            options[Math.floor(Math.random() * options.length)]
        );
    }

    playBingoMachine() {
        if (localStorage.getItem('is-rng-running') == 0) return;

        this.play(this.bingoMachine, 0.02, true);
    }

    stopBingoMachine() {
        this.bingoMachine.fade(0.02, 0, 1000);
        setTimeout(() => {
            this.bingoMachine.stop();
        }, 1000);
    }

    playBackground() {
        this.play(this.backgorund, 0.03, true);
    }

    stopBackground() {
        this.backgorund.fade(0.03, 0, 1000);
        setTimeout(() => {
            this.backgorund.stop();
        }, 1000);
    }

    muteAll() {
        this.playing.forEach(sound => {
            sound._bkp_volume = sound.volume();
            sound.volume(0);
        });
    }

    unMuteAll() {
        this.playing.forEach(sound => {
            if (sound._bkp_volume) {
                sound.volume(sound._bkp_volume);
            }
        });
    }
}

export default new SFX;
