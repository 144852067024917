<template>
  <div class="game-screen text-white">
    <p class="d-inline float-left ml-2 font-weight-bold">
    </p>
    <span class="float-right mr-2 round-info"
          v-if="time != '00:00:00' && time != '00:03:00'">
      {{ $t("Waiting for the start of the round") }}: {{ roundActive + 1 }} - {{ $d(new Date(currentDate), 'long') }}
    </span>
    <span class="float-right mr-2 round-info"
          v-else>
      {{ $t("Round") }}: {{ roundActive }} - {{ counterAllCards }} {{ $t("Cards in play") }} - {{ $d(new
        Date(currentDate), 'long') }}
    </span>

    <div class="video-container">
      <div v-if="showVideo || isRngRunning"
           class="video-loading visible">
        <img id="live-image"
             style="display:none"
             src="/images/wait-720p.webp">
        <img id="wait-img"
             src="/images/wait-720p.webp">
        <div class="loading">
          <span class="loading-spinner"></span>
        </div>
      </div>

      <div v-if="!showVideo && !isRngRunning">
        <img src="~@/assets/images/stopped.png"
             class="img-fluid rounded-top">
      </div>
      <div v-else-if="!showVideo && isRngRunning"
           class="responsive-video-container"></div>
      <div v-else-if="showVideo && !isRngRunning"
           class="responsive-video-container">
        <!--<div id="nano-player" style="width:100%; padding-top:56.25%"></div>-->
        <video id="remoteVideo"
               playsinline
               disablePictureInPicture
               controlsList="nodownload"
               style="width:100%"></video>
  
        <div class="play-button"
             style="display: none">
          <img src="~@/assets/images/play-icon.svg"
               onclick="playWebRTCVideo()"/>
        </div>
      </div>
    </div>

    <RandomBallGenerator v-if="!isLive" />
    <IntervalGameTimeRNG v-if="!isLive" />
  </div>
</template>

<script>
import RandomBallGenerator from "@/components/RandomBallGenerator";
import {mapState} from "vuex";
import moment from "moment";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";
import {EmbeddedPlayer} from "@/assets/libraries/ant-media/embedded-player";
import { EventBus } from '@/services/EventBus';
import IntervalGameTimeRNG from "./IntervalGameTimeRNG.vue";

export default {
  name: "Video",
  props: ["round"],
  components: {
    RandomBallGenerator,
    IntervalGameTimeRNG
  },
  computed: mapState(["user"]),
  data() {
    return {
      socket: null,
      roundActive: true,
      currentRound: null,
      currentDate: null,
      showVideo: true,
      previousShowVideoState: false,
      isSunday: false,
      time: "00:00:00",
      statusBet: false,
      counterAllCards: 0,
      usersOnline: 0,
      isLive: false,
      isRngRunning: false
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.currentRound = this.round;
  },
  mounted() {
    //this.loadNanoPlayerScript();
    
    this.roundActive = this.round;

    //PARA MOSTRAR E ESCONDER O CONTADOR DE TEMPO
    this.socket.on("status-bet", (status) => {
      this.statusBet = status.status;
    });

    //EXIBE O TIMER SENDO CONTADO PARA O JOGADOR
    this.socket.on("show-timer", (message) => {
      this.time = message.time;
    });

    //Atualiza a contagem de cartelas totais da partida quando alguém comprar
    this.socket.on("update-total-cards", (message) => {
      if (message.round_id == this.currentRound) {
        this.counterAllCards = message.cards_count;
      }
    });

    // Remover as cartelas do painel do jogador
    this.socket.on("rollback", () => {
      this.counterAllCards = 0;
    });

    // Para limpar a quantidade de cartelas quando avança a partida pelo dealer (Manual)
    this.socket.on("create-new-round", () => {
      this.counterAllCards = 0;
    });
    
    this.socket.on("is-live", (message) => {
      this.isLive = message.is_live;
      this.setIsRNGRunning(message.is_live);
    });

    this.listAllCards();

    setInterval(() => {
      this.currentDate = this.showDate();
    }, 2000);

    setTimeout(() => {
      this.getOpeningHours();
    }, 1500);

    setTimeout(() => {
      this.getOpeningHours();
      
      let remoteVideo = document.querySelector('#remoteVideo');
      if (remoteVideo) {
        remoteVideo.addEventListener('click', () => {
          setTimeout(() => {
            remoteVideo.play();
          }, 100);
        });

        remoteVideo.addEventListener('pause', () => {
          setTimeout(() => {
            remoteVideo.play();
          }, 100);
        });
      }
    }, 3000);

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.counterAllCards = 0;
      this.roundActive     = activeRound;
      this.currentRound    = activeRound;
      this.listAllCards();
    });

    this.setIsRNGRunning();
  },
  methods: {
    loadNanoPlayerScript() {
      return new Promise((resolve, reject) => {
        if (document.querySelector('#nanoPlayerScript')) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = "https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js";
        script.id = 'nanoPlayerScript';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Falha ao carregar o script do NanoPlayer'));
        document.head.appendChild(script);
      });
    },
    
    initNanoPlayer() {
      if (window.nanoPlayer) {
        return;
      }
      
      setTimeout(() => {
        let nanoPlayerConfig = {
          "source"  : {
            "group"     : {
              "id"    : "43ce25c9-f181-4fbe-b563-cbee2e44c666",
              "apiurl": "https://bintu.nanocosmos.de"
            },
            "options"   : {
              "adaption": {
                "rule": "deviationOfMean2"
              }
            },
            "startIndex": -1
          },
          "playback": {
            "latencyControlMode": "balancedadaptive",
            "autoplay"          : true,
            "automute"          : true,
            "faststart"         : true
          },
          "style"   : {
            "width" : "auto",
            "height": "auto",
            "controls": false,
            "interactive": false,
            "backgroundColor": "rgba(0,0,0,0)"
          },
          "metrics": {
            "accountId": "Copacabanagames",
            "accountKey": "Cop1tUjWORzTDUCJ",
            "userId": 'bingo_' + this.user.id,
          },
        };

        const script = document.createElement('script');
        script.id = 'nanoPlayerInit';
        script.innerHTML = 'window.nanoPlayer=new NanoPlayer("nano-player");window.nanoPlayer.setup(' + JSON.stringify(nanoPlayerConfig)+ ');';
        document.head.appendChild(script);
      }, 1500);

      setTimeout(() => {
        window.nanoPlayer.unmute();
      }, 1000);
    },
    
    showDate() {
      return moment().utcOffset(-180).format();
    },

    showVideoVerification(isLive = false) {
      this.previousShowVideoState = this.showVideo;
      this.showVideo              = isLive;
      this.isLive                 = isLive;

      localStorage.setItem('is_live', String(isLive ? 1 : 0));

      EventBus.$emit('openingHoursUpdated', isLive)

      let liveImage           = document.querySelector('#live-image')
      liveImage.style.display = isLive ? 'block' : 'none';

      setTimeout(() => {
        if (isLive && window.embeddedPlayer) {
          window.embeddedPlayer.videojsPlayer.dispose();
          window.embeddedPlayer = null;

          setTimeout(() => {
            let btn = document.getElementById('btn-toggle-video-audio');
            if (!btn.classList.contains('is-audio-active')) {
              btn.click();
            } else {
              const video = document.getElementById('remoteVideo');
              video.muted = false;
            }
          }, 3000);
          
          //this.initNanoPlayer();
        //} else if (isLive && !window.embeddedPlayer) {
          //this.initNanoPlayer();
        } else if (!isLive
            && !window.embeddedPlayer
            && document.querySelector('.responsive-video-container')
        ) {
          this.$parent.isDesktop = !this.$parent.isAndroid && !this.$parent.isSafari;
          
          let video = 'https://cdn.copacabana.games/static/bingo/rng-video.mp4';

          window.embeddedPlayer = new EmbeddedPlayer(
              window,
              document.querySelector('.responsive-video-container'),
              document.querySelector('.video-loading'),
              video
          );
          
          if (this.previousShowVideoState) {
            window.embeddedPlayer.mute = false;
          }
          
          window.embeddedPlayer.play();
        }
      }, 200);
    },

    getOpeningHours() {
      this.$http.get("opening-hours")
      .then(async (res) => {
        this.showVideoVerification(res.data.isLive);
      });
    },

    listAllCards() {
      this.$http
        .get("list-all-cards/" + this.$helpers.getActiveRound())
        .then((res) => {
          this.counterAllCards = res.data.count;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setIsRNGRunning(is_live) {
      this.$http.get("is-rng-running").then((res) => {
        localStorage.setItem('is-rng-running', res.data || 0);
        this.isRngRunning = res.data || 0;
        this.showVideoVerification(is_live);
      });
    }
  },
};
</script>
