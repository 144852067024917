<template>
  <transition name="fade">
    <div class="modal-menu" v-if="show">
      <div class="modal__backdrop" @click="closeModal" />

      <div class="modal__dialog">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <slot name="header" />
          <button type="button" class="btn-close-modal" @click="closeModal" style="background:transparent">
            &#10006;
          </button>
        </div>

        <div class="modal__body">
          <slot name="body" />
        </div>

        <div class="modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    openModal() {
      this.show = true;
    },
    toggleModal() {
      this.show = !this.show;
    },
  },
};
</script>
