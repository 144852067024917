<template>
  <div class="rules">
    <div class="box">
      <div class="title-box">{{ $t("Game rules") }} <button class="text-white float-right" @click="closeRules">&#x2715;</button></div>
      <div class="row mx-0">
        <div class="col-sm-12 col-md-3 options d-none d-md-block">
          <ul>
            <li class="d-flex justify-content-between" :class="{'active': introduction}" @click="changeOption(1)">{{ $t("Introduction") }} <span>&#10095;</span></li>
            <!--<li class="d-flex justify-content-between" :class="{'active': time}" @click="changeOption(2)"> {{ $t("Opening hours") }} <span>&#10095;</span></li>-->
            <li class="d-flex justify-content-between" :class="{'active': how_play}" @click="changeOption(3)">{{ $t("How to play") }} <span>&#10095;</span></li>
            <!--<li class="d-flex justify-content-between" :class="{'active': payment}" @click="changeOption(4)">{{ $t("Payment") }} <span>&#10095;</span></li>-->
            <li class="d-flex justify-content-between" :class="{'active': prize_draw}" @click="changeOption(5)">{{ $t("Prize draw") }} <span>&#10095;</span></li>
            <!--<li class="d-flex justify-content-between" :class="{'active': special_rounds}" @click="changeOption(6)">{{ $t("Special rounds") }} <span>&#10095;</span></li>-->
            <li class="d-flex justify-content-between" :class="{'active': jackpot}" @click="changeOption(7)">{{ $t("Accumulated") }} <span>&#10095;</span></li>
            <li class="d-flex justify-content-between" :class="{'active': round_number}" @click="changeOption(8)">{{ $t("Round number") }} <span>&#10095;</span></li>
            <li class="d-flex justify-content-between" :class="{'active': control_panel}" @click="changeOption(9)">{{ $t("Control panel") }} <span>&#10095;</span></li>
            <li class="d-flex justify-content-between" :class="{'active': volume}" @click="changeOption(10)">{{ $t("Volume") }} <span>&#10095;</span></li>
            <li class="d-flex justify-content-between" :class="{'active': historic}" @click="changeOption(11)">{{ $t("Historic") }} <span>&#10095;</span></li>
            <!--<li class="d-flex justify-content-between" :class="{'active': chat}" @click="changeOption(12)">Chat <span>&#10095;</span></li>-->
            <li class="d-flex justify-content-between" :class="{'active': support}" @click="changeOption(13)">{{ $t("Support") }} <span>&#10095;</span></li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-9 body px-0">
          <div>
            <div class="options-title" :class="{'active': introduction}" @click="changeOption(1)">
              {{ $t("Introduction") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="introduction">
              {{ $t("Introduction text") }}
            </div>
          </div>

          <!--<div>
            <div class="options-title color-dark" :class="{'active': time}" @click="changeOption(2)">
              {{ $t("Opening hours") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="time">
              {{ $t("Opening hours text") }}
            </div>
          </div>-->

          <div>
            <div class="options-title" :class="{'active': how_play}" @click="changeOption(3)">
              {{ $t("How to play") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="how_play">
              {{ $t("How to play 1") }}
              <br> <img src="~@/assets/images/rules/how-to-play-1.webp" alt=""> <br>
              {{ $t("How to play 2") }}
              <br> <img src="~@/assets/images/rules/how-to-play-2.webp" alt="" style="width:30%;"> &emsp;
              <img src="~@/assets/images/rules/how-to-play-3.webp" alt="" style="width:30%;"> <br>
              {{ $t("How to play 3") }}
              <br> <img src="~@/assets/images/rules/how-to-play-4.webp" alt=""> <br>
              {{ $t("How to play 4") }}
              <br> <img src="~@/assets/images/rules/how-to-play-5.webp" alt=""> <br>
            </div>
          </div>

          <!--<div>
            <div class="options-title color-dark" :class="{'active': payment}" @click="changeOption(4)">
              {{ $t("Payment") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="payment">
              {{ $t("Payment text 1") }}
              
              <table class="table text-white text-uppercase">
                <tr>
                  <th>{{ $t("Type of gain") }}</th>
                  <th>{{ $t("Payment") }}</th>
                </tr>
                <tr>
                  <td>{{ $t("Line") }}</td>
                  <td>R$ 5,00</td>
                </tr>
                <tr>
                  <td>{{ $t("Double line") }}</td>
                  <td>R$ 10,00</td>
                </tr>
                <tr>
                  <td>BINGO</td>
                  <td>R$ 25,00</td>
                </tr>
              </table>

              {{ $t("Payment text 2") }}
            </div>
          </div>-->

          <div>
            <div class="options-title" :class="{'active': prize_draw}" @click="changeOption(5)">
              {{ $t("Prize draw") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="prize_draw">
              {{ $t("Prize draw text 1") }}
              <br> <img src="~@/assets/images/rules/prize-draw-1.webp" alt=""> <br>
              {{ $t("Prize draw text 2") }}
              <br> <img src="~@/assets/images/rules/prize-draw-2.webp" alt=""> <br>
              {{ $t("Prize draw text 3") }}
              <br> <img src="~@/assets/images/rules/prize-draw-3.webp" alt=""> <br>
              {{ $t("Prize draw text 4") }}
              <br> <img src="~@/assets/images/rules/prize-draw-4.webp" alt=""> <br>
              {{ $t("Prize draw text 5") }}
            </div>
          </div>

          <!--<div>
            <div class="options-title color-dark" :class="{'active': special_rounds}" @click="changeOption(6)">
              {{ $t("Special rounds") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="special_rounds">
              {{ $t("Special rounds text 1") }}
              <table class="table text-white text-uppercase">
                <tr>
                  <th>{{ $t("Type of gain") }}</th>
                  <th>{{ $t("Payment") }}</th>
                </tr>
                <tr>
                  <td>{{ $t("Line") }}</td>
                  <td>R$ 15,00</td>
                </tr>
                <tr>
                  <td>{{ $t("Double line") }}</td>
                  <td>R$ 30,00</td>
                </tr>
                <tr>
                  <td>BINGO</td>
                  <td>R$ 55,00</td>
                </tr>
              </table>

              {{ $t("Special rounds text 2") }}
              <table class="table text-white text-uppercase">
                <tr>
                  <th>{{ $t("Type of gain") }}</th>
                  <th>{{ $t("Payment") }}</th>
                </tr>
                <tr>
                  <td>{{ $t("Line") }}</td>
                  <td>R$ 35,00</td>
                </tr>
                <tr>
                  <td>{{ $t("Double line") }}</td>
                  <td>R$ 65,00</td>
                </tr>
                <tr>
                  <td>BINGO</td>
                  <td>R$ 100,00</td>
                </tr>
              </table>

              {{ $t("Special rounds text 3") }}
              <br> <img src="~@/assets/images/rules/special-round-1.webp" alt=""> <br>
              <img src="~@/assets/images/rules/special-round-2.webp" alt=""> <br>

              {{ $t("Special rounds text 4") }}
              <br><img  src="require('@/assets/images/rules/special-round-3.webp" alt=""> <br><br>
            </div>
          </div>-->

          <div>
            <div class="options-title" :class="{'active': jackpot}" @click="changeOption(7)">
              {{ $t("Accumulated") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="jackpot">
              {{ $t("Accumulated text 1") }}
              <br><img src="~@/assets/images/rules/jackpot.webp" alt=""> <br>

              {{ $t("Accumulated text 2") }}
            </div>
          </div>

          <div>
            <div class="options-title color-dark" :class="{'active': round_number}" @click="changeOption(8)">
              {{ $t("Round number") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="round_number">
              {{ $t("Round number text") }}
              <br><img src="~@/assets/images/rules/round-number.webp" alt=""> <br><br>
            </div>
          </div>

          <div>
            <div class="options-title" :class="{'active': control_panel}" @click="changeOption(9)">
              {{ $t("Control panel") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="control_panel">
              {{ $t("Control panel text") }}
              <br><img src="~@/assets/images/rules/control-panel.webp" alt=""> <br><br>
            </div>
          </div>

          <div>
            <div class="options-title color-dark" :class="{'active': volume}" @click="changeOption(10)">
              {{ $t("Volume") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="volume">
              {{ $t("Volume text") }}
              <br><img src="~@/assets/images/rules/volume.webp" alt=""> <br><br>
            </div>
          </div>

          <div>
            <div class="options-title" :class="{'active': historic}" @click="changeOption(11)">
              {{ $t("Historic") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="historic">
              {{ $t("Historic text") }}
              <br><img src="~@/assets/images/rules/historic.webp" alt=""> <br><br>
            </div>
          </div>

          <!--<div>
            <div class="options-title color-dark" :class="{'active': chat}" @click="changeOption(12)">
              Chat <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="chat">
              {{ $t("Chat text 1") }}
              <br><br><img src="~@/assets/images/rules/chat.webp" alt=""> <br><br>
              {{ $t("Chat text 2") }}
            </div>
          </div>-->

          <div>
            <div class="options-title" :class="{'active': support}" @click="changeOption(13)">
              {{ $t("Support") }} <span class="options-title-icon float-right">&#10095;</span>
            </div>
            <div class="text-center text-md-left" v-show="support">
              {{ $t("Support text 1") }}
              <br><br><img src="~@/assets/images/rules/support.webp" alt=""> <br><br>
              {{ $t("Support text 2") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";

export default {
  name: "Rules",
  data() {
    return {
      introduction: true,
      time: false,
      how_play: false,
      payment: false,
      prize_draw: false,
      special_rounds: false,
      jackpot: false,
      round_number: false,
      control_panel: false,
      volume: false,
      historic: false,
      chat: false,
      support: false,
    };
  },
  mounted() {
    jQuery("#winners").fadeIn();
  },

  methods: {
    changeOption(opt) {
      this.introduction = false;
      this.time = false;
      this.how_play = false;
      this.payment = false;
      this.prize_draw = false;
      this.special_rounds = false;
      this.jackpot = false;
      this.round_number = false;
      this.control_panel = false;
      this.volume = false;
      this.historic = false;
      this.chat = false;
      this.support = false;

      if(opt == 1) this.introduction = true;
      if(opt == 2) this.time = true;
      if(opt == 3) this.how_play = true;
      if(opt == 4) this.payment = true;
      if(opt == 5) this.prize_draw = true;
      if(opt == 6) this.special_rounds = true;
      if(opt == 7) this.jackpot = true;
      if(opt == 8) this.round_number = true;
      if(opt == 9) this.control_panel = true;
      if(opt == 10) this.volume = true;
      if(opt == 11) this.historic = true;
      if(opt == 12) this.chat = true;
      if(opt == 13) this.support = true;
    },

    closeRules() {
      this.$emit('toggleRules');
    }
  }
};
</script>
