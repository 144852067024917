/**
* This page accepts following arguments. 
* 1. "id": the stream id to play.It is mandatory
* 2. "token": the token to play stream. It's mandatory if token security is enabled on server side.
* 3. "autoplay": To start playing automatically if streams is available. Optional. Default value is true
* 4. "mute": To start playing with mute if streams is available. Optional. Default value is true
* 5. "playOrder": the order which technologies is used in playing. Optional. Default value is "webrtc,hls".
*     possible values are "hls,webrtc","webrtc","hls","vod","dash"
* 6. "playType": the order which play type is used in playing. Optional. Default value is "mp4,webm".
*     possible values are "webm,mp4"","mp4","webm","mov"
* 7. "targetLatency": To define target latency for the DASH player(). Optional. Default value is 3.
* 8. "is360": To play the stream in 360. Default value is false. 
*/
var dashjs;
var Hls;
var timeToStartVideo;

import 'video.js/dist/video-js.css'
import videojs from 'video.js/dist/video.js';
import { WebRTCAdaptor } from "./webrtc_adaptor"
import { getUrlParameter, isMobile, tryToPlay, tryToVODPlay } from "./fetch.stream";

var playOrder = getUrlParameter("playOrder");
if (playOrder == null) {
  playOrder = ["webrtc", "hls"];
}
else {
  playOrder = playOrder.split(',');
}
//make play order global to let the other module access it
window.playOrder = playOrder;

var head = document.getElementsByTagName('head')[0];

if (playOrder.includes("dash")) 
{
  let js = document.createElement("script");
  js.type = "text/javascript";
  js.src = "js/external/dash.all.min.js";
  head.appendChild(js);
}

var is360 = getUrlParameter("is360");
if (is360 == "true") {
  var hlsJS = document.createElement("script");
  hlsJS.type = "text/javascript";
  hlsJS.src = "js/external/hls.js";
  head.appendChild(hlsJS);
  
  var aframeJS = document.createElement("script");
  aframeJS.type = "text/javascript";
  aframeJS.src = "js/external/aframe.min.js";

  aframeJS.onload = function() {
    var aScene = document.createElement("a-scene");
    aScene.innerHTML = "<a-videosphere src=\"#remoteVideo\" rotation=\"0 180 0\" style=\"background-color: antiquewhite\"></a-videosphere>";
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(aScene);
  }
  head.appendChild(aframeJS);
  

  is360 = true;
}
else {
  is360 = false;
}

window.is360 = is360;

var handleConnectivityCallDateTimeMs = 0;


var streamId = getUrlParameter("id");
if (streamId == null) {
  //check name variable for compatibility with older versions
  streamId = getUrlParameter("name");
}

var playType = getUrlParameter("playType");
if (playType == null || playType == "mp4,webm") {
  playType = ["mp4", "webm"];
}
else if (playType == "webm,mp4") {
  playType = ["webm", "mp4"];
}
else if (playType == "mp4") {
  playType = ["mp4"];
}
else if (playType == "webm") {
  playType = ["webm"];
}
else if (playType == "mov") {
  playType = ["mov"];
}

var token = getUrlParameter("token");
var pAutoplay = getUrlParameter("autoplay");
var autoPlay = true;
if (pAutoplay == "true" || isMobile()) {
  autoPlay = true;
}
var mute = getUrlParameter("mute");
if (mute == "true" || mute == null) {
  mute = true;
}
else {
  mute = false;
}

var targetLatency = getUrlParameter("targetLatency");
if (targetLatency == "null") {
  targetLatency = 3;
}

var hlsExtension = "m3u8";
var dashExtension = "mpd";
var subscriberId = getUrlParameter("subscriberId");
var subscriberCode = getUrlParameter("subscriberCode");

var unMuteButton = () => document.getElementById("unmuteButton");
var placeHolder  = () => document.getElementById("video_info");
var player       = () => document.getElementById("remoteVideo");

var iceConnected = false;

function showVideoOverlay() {
  document.querySelector('.video-loading').classList.add('visible');
}

function hideVideoOverlay() {
  document.querySelector('.video-loading').classList.remove('visible');
  window.dispatchEvent(new Event('resize'));

  setInterval(() => {
    updateLoadingImageFrame();
  }, 10000);
}

function showPlayButton() {
  document.querySelectorAll('.play-button')
          .forEach(function (item) {
            item.style.display = "flex";
          });
}

function hidePlayButton() {
  document.querySelectorAll('.play-button')
          .forEach(function (item) {
            item.style.display = "none";
          });
}

// This function mutes or unmutes the 360 video stream.
// This is necessary because the browser doesn't allow videos to be played automatically and unmuted by default.
// Exceptions are possible: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
function changeVideoMuteStatus() {
  // Checks the current "mute" status of the video
  if (player().muted) {
    // Unmute the video and change the button's text
    player().muted = false;
    document.getElementById("unmuteButton").innerText = "Mute";
  }
  else {
    player().muted = true;
    document.getElementById("unmuteButton").innerText = "Unmute";
  }
}

var webRTCAdaptor = null;
var streamsFolder = "streams";

function genericCallback(currentTech) {

  placeHolder().innerHTML = "Stream will start playing automatically<br/>when it is live";
  setTimeout(function () {
    var index = playOrder.indexOf(currentTech);

    if (currentTech == "webrtc") {
      if (!iceConnected) {
        if (index == -1 || index == (playOrder.length - 1)) {
          index = 0;
        }
        else {
          index++;
        }
      }
    }
    else {
      if (index == -1 || index == (playOrder.length - 1)) {
        index = 0;
      }
      else {
        index++;
      }
    }

    var tech = playOrder[index];

    if (tech == "webrtc") {
      // It means there is no HLS stream, so try to play WebRTC stream
      if (webRTCAdaptor == null) {
        initializeWebRTCPlayer(streamId, token, webrtcNoStreamCallback);
      }
      else {
        webRTCAdaptor.getStreamInfo(streamId);
      }
    }
    else if (tech == "hls") {
      tryToPlay(streamId, token, hlsExtension, subscriberId, subscriberCode, hlsNoStreamCallback);
    }
    else if (tech == "dash") {
      var dashFile = streamId + "/" + streamId;
      tryToPlay(dashFile, token, dashExtension, subscriberId, subscriberCode, dashNoStreamCallback);
    }
  }, 3000);
}

function webrtcNoStreamCallback() {
  /**
 * If HLS is in the play order then try to play HLS, if not wait for WebRTC stream
 * In some cases user may want to remove HLS from the order and force to play WebRTC only
 * in these cases player only waits for WebRTC streams
 */
  genericCallback("webrtc");
}

function hlsNoStreamCallback() {
  genericCallback("hls");
}

function vodNoStreamCallback() {
  placeHolder().innerHTML = "Stream will start playing automatically<br/>when it is live";
  setTimeout(function () {
    if (playOrder.includes("vod")) {
      tryToVODPlay(streamId, token, subscriberId, subscriberCode, vodNoStreamCallback, playType);
    }
  }, 3000);

}

function dashNoStreamCallback() {
  genericCallback("dash");
}

function setHLSElementsVisibility(show) {
  let ele = document.getElementById("video_container");
  if (ele) {
    ele.style.display = show == true ? "block" : "none";
  }
}

function hideWebRTCElements() {
  setWebRTCElementsVisibility(false);
  hidePlayButton();
}

function setWebRTCElementsVisibility(show) {
  document.getElementById("remoteVideo").style.display = show == true ? "block" : "none";
}

function setPlaceHolderVisibility() {
  // placeHolder().style.display = show == true ? "block" : "none";
}

function show360ElementsVisibility() {
  unMuteButton().style.display = "block";
}

function playWebRTCVideo() {
  setWebRTCElementsVisibility(true);

  if (is360) {
    unMuteButton().style.display = "block";
  }

  if (mute) {
    document.getElementById("remoteVideo").muted = true;
    // unMuteButton().innerText = "Unmute";
  } else {
    document.getElementById("remoteVideo").muted = false;
    unMuteButton().innerText = "Mute";
  }
  
  if (autoPlay) {
    document.getElementById("remoteVideo")
            .play()
            .then(function () {
              hidePlayButton();
            })
            .catch(function () {
              showPlayButton();
            });
  }
}


function initializePlayer(streamId, extension, token, subscriberId, subscriberCode) {
  hideWebRTCElements();
  startPlayer(streamId, extension, token, subscriberId, subscriberCode)
}

window.initializePlayer = initializePlayer;
window.playWebRTCVideo = playWebRTCVideo;
window.changeVideoMuteStatus = changeVideoMuteStatus;

function startPlayer(streamId, extension, token, subscriberId, subscriberCode) {

  var type;
  var liveStream = false;
  if (extension == "mp4") {
    type = "video/mp4";
    liveStream = false;
  }
  else if (extension == "webm") {
    type = "video/webm";
    liveStream = false;
  }
  else if (extension == "mov") {
    type = "video/mp4";
    alert("Browsers do not support to play mov format");
    liveStream = false;
  }
  else if (extension == "avi") {
    type = "video/mp4";
    alert("Browsers do not support to play avi format");
    liveStream = false;
  }
  else if (extension == "m3u8") {
    type = "application/x-mpegURL";
    liveStream = true;
  }
  else if (extension == "mpd") {
    type = "application/dash+xml";
    liveStream = true;
  }
  else {
    console.log("Unknown extension: " + extension + liveStream);
    return;
  }

  var preview = streamId;
  if (streamId.endsWith("_adaptive")) {
    preview = streamId.substring(0, streamId.indexOf("_adaptive"));
  }

  var player = document.getElementById('remoteVideo');

  if (!is360) {
    // If it's not dash, play with videojs
    if (extension != dashExtension) {

      player = videojs('video-player', { //
        poster: "previews/" + preview + ".png",
        liveui: true,
        liveTracker: {
          trackingThreshold: 0
        },
      });

      videojs.Hls.xhr.beforeRequest = function (options) {
        options.uri = options.uri + "?token=" + token + "&subscriberId=" + subscriberId + "&subscriberCode=" + subscriberCode;
        return options;
      };

      player().src({
        src: "streams/" + streamId + "." + extension,
        type: type,
        withCredentials: true,
      });
  

      player().poster("previews/" + preview + ".png");

      if (mute) {
        player().muted(true);
      }
      else {
        player().muted(false);
      }

      if (autoPlay) {
        player().ready(function () {
          player().play();
        });
      }
    }
    else {
      player = dashjs.MediaPlayer().create();

      player().updateSettings({ 'streaming': { 'lowLatencyEnabled': true } });

      player().updateSettings({
        'streaming': {
          'liveDelay': targetLatency,
          'liveCatchUpMinDrift': 0.05,
          'liveCatchUpPlaybackRate': 0.5,
          "liveCatchupLatencyThreshold": 30,
        }
      });

      player().initialize(document.querySelector("#video-player"), "streams/" + streamId + "." + extension + "?token=" + token, false);

      if (mute) {
        player().setMute(true);
      }
      else {
        player().setMute(false);
      }

      if (autoPlay && player().isReady()) {
        player().play();
      }

      setInterval(function () {
        // console.log("live latency: " + player().getCurrentLiveLatency());
      }, 2000);
    }
  }
  else {
    show360ElementsVisibility();
    if (extension == hlsExtension) {

      // We need to call again due to creating 360 video elements
      player = document.getElementById('remoteVideo');

      if (mute) {
        player().muted = true;
      }
      else {
        player().muted = false;
      }

      var hls = new Hls({
        debug: false
      });

      hls.loadSource("streams/" + streamId + "." + extension + "?token=" + token + "&subscriberId=" + subscriberId + "&subscriberCode=" + subscriberCode);
      hls.attachMedia(player);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        player().play();
      });

      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          // console.log("There is an error with chunks");
          tryToPlay(streamId, token, extension, subscriberId, subscriberCode, hlsNoStreamCallback);
        }
      });
    }
    else {
      // We need to call again due to creating 360 video elements
      player = document.getElementById('remoteVideo');

      player().src = "streams/" + streamId + "." + extension + "?token=" + token + "&subscriberId=" + subscriberId + "&subscriberCode=" + subscriberCode;

      if (mute) {
        player().muted = true;
      }
      else {
        player().muted = false;
      }

      if (autoPlay) {
        player().play();
      }
    }

    if (autoPlay) {
      document.getElementById("remoteVideo").play().then(function () {
        //autoplay started
        hidePlayButton();
        unMuteButton().innerText = "Unmute";
      }).catch(function () {
        unMuteButton().innerText = "Mute";
        showPlayButton();
        // console.log("User interaction needed to start playing");
      });
    }
  }


  if (!is360) {
    setHLSElementsVisibility(true);
    setWebRTCElementsVisibility(false);
    if (typeof player().ready != "undefined") {
      player().ready(function () {
        var player = this;
        player().on('ended', function () {
          // console.log("is360: " + is360 + " Playing has been finished");
          hideWebRTCElements();
          setHLSElementsVisibility(false);
          setPlaceHolderVisibility(true);
          tryToPlay(streamId, token, extension, subscriberId, subscriberCode, hlsNoStreamCallback);
        });
      });
    }

  }
  else if (is360) {
    setHLSElementsVisibility(false);
    player().addEventListener("ended", () => {
      // console.log("is360: " + is360 + " Playing has been finished");
      hideWebRTCElements();
      setHLSElementsVisibility(false);
      setPlaceHolderVisibility(true);
      tryToPlay(streamId, token, extension, subscriberId, subscriberCode, hlsNoStreamCallback);
    });
    setWebRTCElementsVisibility(true);
  }
  else {
    // console.log("player ready is not available. List playing may not be continous");
  }
  setPlaceHolderVisibility(false);
}

function handleWebRTCConnectivity(noStreamCallback) 
{
  var currentTimeMs = (new Date()).getTime();
  //call if it's more than 3 seconds older
  if (currentTimeMs >  (handleConnectivityCallDateTimeMs + 3000)) 
  {
    handleConnectivityCallDateTimeMs = currentTimeMs;
    if (iceConnected) 
    {
      //webrtc connection was successful and try to play again with webrtc
      setTimeout(function () {
        webRTCAdaptor.getStreamInfo(streamId);
      }, 3000);
      // console.log("Trying to play with webrtc again");
    }
    else 
    {
      //webrtc connection was not succesfull, switch the next play type(playOrder) if available 
      if (typeof noStreamCallback != "undefined") {
        noStreamCallback();
        // console.log("Trying to play with other tech than webrtc if available");
      }
    }
    //make the flag false to try other technologies
    iceConnected = false;		
  }
}


function initializeWebRTCPlayer(streamId, token, subscriberId, subscriberCode, noStreamCallback) {

  if (is360) {
    show360ElementsVisibility();
  }
  setHLSElementsVisibility(false);

  var pc_config = {
    'iceServers': [{
      'urls': 'stun:stun1.l.google.com:19302'
    }]
  };


  var sdpConstraints = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true

  };
  var mediaConstraints = {
    video: false,
    audio: false
  };

  var appName = location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1);
  var path = location.hostname + ":" + location.port + appName + "websocket";
  path = "live.copacabana.games/WebRTCAppEE/websocket";
  var websocketURL = "ws://" + path;

  if (location.protocol.startsWith("https")) {
    websocketURL = "wss://" + path;
  }

  iceConnected = false;

  //webRTCAdaptor is a global variable
  webRTCAdaptor = new WebRTCAdaptor({
    websocket_url: websocketURL,
    mediaConstraints: mediaConstraints,
    peerconnection_config: pc_config,
    sdp_constraints: sdpConstraints,
    remoteVideoId: "remoteVideo",
    isPlayMode: true,
    debug: true,
    callback: function (info, description) {
      if (info == "initialized") {
        // console.log("initialized");
        iceConnected = false;
        webRTCAdaptor.getStreamInfo(streamId);
      }
      else if (info == "streamInformation") {
        console.log("stream information");
        webRTCAdaptor.play(streamId, token, "",[] ,subscriberId, subscriberCode);
      }
      else if (info == "play_started") {
        //joined the stream
        // console.log("play started");
        
        hideVideoOverlay();
        setPlaceHolderVisibility(false);
        setHLSElementsVisibility(false);
        playWebRTCVideo();
      } else if (info == "play_finished") {
        //leaved the stream
        // console.log("play finished");
        setHLSElementsVisibility(false);
        hideWebRTCElements();
        setPlaceHolderVisibility(true);
        //if play_finished event is received, it has two meanings
        //1. stream is really finished 
        //2. ice connection cannot be established and server reports play_finished event
        //check that publish may start again
        //below method handle the cases above
        handleWebRTCConnectivity(noStreamCallback);
      }
      else if (info == "closed") 
      {
        setHLSElementsVisibility(false);
        hideWebRTCElements();
        setPlaceHolderVisibility(true);
        showVideoOverlay();
        
        console.log("Websocket connecton closed: " + (typeof description != "undefined" ? 
              JSON.stringify(description) : ""));
        
        handleWebRTCConnectivity(noStreamCallback);
      }
      else if (info == "bitrateMeasurement") {
        if (!document.getElementById("remoteVideo").paused) {
          hidePlayButton();
        }
      }
      else if (info == "ice_connection_state_changed") {
        // console.debug("ice connection state changed to " + description.state);
        if (description.state == "connected" || description.state == "completed") {
          //it means the ice connection has been established
          iceConnected = true;
        }
      }
      else if (info == "resolutionChangeInfo") {
        showVideoOverlay();
        
        let getVideo = document.getElementById("remoteVideo");
        getVideo.pause();
        
        setTimeout(function () {
          getVideo.play();
          hideVideoOverlay();
        }, 2000);
      }
      else if (info == "server_will_close") {
        // console.log("Server will close soon");
      }
    },
    callbackError: function (error) {
      showVideoOverlay();
      //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

      console.log("error callback: " + JSON.stringify(error));

      if (error == "no_stream_exist" || error == "WebSocketNotConnected" 
          || error == "not_initialized_yet" || error == "data_store_not_available") 
      {
        handleWebRTCConnectivity(noStreamCallback);
      }
      if (error == "notSetRemoteDescription") {
        /*
        * If getting codec incompatible or remote description error, it will redirect HLS player().
        */
        tryToPlay(streamId, token, hlsExtension, subscriberId, subscriberCode, hlsNoStreamCallback);
      }
    }
  });
}

function updateLoadingImageFrame() {
  // let fraction5s = Math.floor(Date.now() / 5000);
  //
  // let ele = document.querySelector('.video-loading img#live-image');
  //
  // ele.onload = function () {
  //   ele.style.display = 'block';
  // };
  // ele.onerror = function () {
  //   ele.style.display = 'none';
  // };
  // 
  //ele.src = 'https://live.copacabana.games/WebRTCAppEE/previews/u837NYURc4eX1669302443614.png?_=' + fraction5s;
}

function main() {
  clearInterval(timeToStartVideo);
  var streamId = "u837NYURc4eX1669302443614"; // ID DO VIDEO
  if (typeof streamId != "undefined") {

    if (streamId.startsWith(streamsFolder)) {
      /*
      * If streamId starts with streams, it's hls or mp4 file to be played
      */
      var lastIndexOfDot = streamId.lastIndexOf(".")
      var streamPath = streamId.substring(streamsFolder.length + 1, lastIndexOfDot);
      var extension = streamId.substring(lastIndexOfDot + 1);
      initializePlayer(streamPath, extension, token);
    }
    else {
      /*
        * Check that which one is in the first order
      */
      if (playOrder[0] == "webrtc") {
        initializeWebRTCPlayer(streamId, token, subscriberId, subscriberCode, webrtcNoStreamCallback);
      }
      else if (playOrder[0] == "hls") {
        tryToPlay(streamId, token, hlsExtension, subscriberId, subscriberCode, hlsNoStreamCallback);
      }
      else if (playOrder[0] == "vod") {
        tryToVODPlay(streamId, token, subscriberId, subscriberCode, vodNoStreamCallback, playType);
      }
      else if (playOrder[0] == "dash") {
        tryToPlay(streamId, token, dashExtension, subscriberId, subscriberCode, dashNoStreamCallback);
      }
      else {
        alert("Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls");
      }
    }
  }
  else {
    alert("No stream specified. Please add ?id={STREAM_ID}  to the url");
  }
}

window.addEventListener('load', function() {
  timeToStartVideo = setInterval(() => {
    var video = document.querySelector('#remoteVideo');
    if (video) {
      updateLoadingImageFrame();
      main();
    }
  }, 3000);
});
