<template>
  <div :class="highlight.includes(numbers[0].cartela_id) ? 'card card-glow' : 'card'" v-if="numbers !== undefined">
    <!-- CARD -->
    <p class="card-id">ID: {{ numbers[0].cartela_id }}</p>
    <div class="card-numbers">
      <div class="card-line line-1">
        <div></div>
        <div :class="{ 'number-black': numbers[0].status, 'number-red': !numbers[0].status }">{{ numbers[0].bola }}</div>
        <div :class="{ 'number-black': numbers[1].status, 'number-red': !numbers[1].status }">{{ numbers[1].bola }}</div>
        <div></div>
        <div :class="{ 'number-black': numbers[2].status, 'number-red': !numbers[2].status }">{{ numbers[2].bola }}</div>
        <div></div>
        <div :class="{ 'number-black': numbers[3].status, 'number-red': !numbers[3].status }">{{ numbers[3].bola }}</div>
        <div :class="{ 'number-black': numbers[4].status, 'number-red': !numbers[4].status }">{{ numbers[4].bola }}</div>
        <div></div>
      </div>
      <div class="card-line line-2">
        <div :class="{ 'number-black': numbers[5].status, 'number-red': !numbers[5].status }">{{ numbers[5].bola }}</div>
        <div></div>
        <div :class="{ 'number-black': numbers[6].status, 'number-red': !numbers[6].status }">{{ numbers[6].bola }}</div>
        <div :class="{ 'number-black': numbers[7].status, 'number-red': !numbers[7].status }">{{ numbers[7].bola }}</div>
        <div></div>
        <div :class="{ 'number-black': numbers[8].status, 'number-red': !numbers[8].status }">{{ numbers[8].bola }}</div>
        <div></div>
        <div></div>
        <div :class="{ 'number-black': numbers[9].status, 'number-red': !numbers[9].status }">{{ numbers[9].bola }}</div>
      </div>
      <div class="card-line line-3">
        <div></div>
        <div :class="{ 'number-black': numbers[10].status, 'number-red': !numbers[10].status }">{{ numbers[10].bola }}
        </div>
        <div></div>
        <div :class="{ 'number-black': numbers[11].status, 'number-red': !numbers[11].status }">{{ numbers[11].bola }}
        </div>
        <div :class="{ 'number-black': numbers[12].status, 'number-red': !numbers[12].status }">{{ numbers[12].bola }}
        </div>
        <div></div>
        <div :class="{ 'number-black': numbers[13].status, 'number-red': !numbers[13].status }">{{ numbers[13].bola }}
        </div>
        <div :class="{ 'number-black': numbers[14].status, 'number-red': !numbers[14].status }">{{ numbers[14].bola }}
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ['numbers', 'highlight'],
  mounted() {
  }
};
</script>
