<template>
  <div>
    <div class="row px-0 px-md-1"
      v-show="!showBuyAutomaticContainer && (!showScheduleContainer || !showDesktopScheduleContainer)">
      <div class="col-lg-12 padding-sm-0">
        <div class="row buy-container" :class="{ 'special-card': nextRoundSpecial > 0 }">
          <div class="col-7 col-md-8 p-0">
            <div class="compra-rapida d-flex justify-content-between align-items-center">
              <span class="text-center text-white">
                {{ $t("Fast buy_1") }}<br>{{ $t("Fast buy_2") }}
              </span>
              <div class="d-none d-md-block"><button @click="setQtdCards(5)">5</button></div>
              <div class="buy-10-cards"><button @click="setQtdCards(10)">10</button></div>
              <div class="buy-25-cards"><button @click="setQtdCards(25)">25</button></div>
              <div class="buy-50-cards"><button @click="setQtdCards(50)">50</button></div>
              <div class="buy-100-cards"><button @click="setQtdCards(100)">100</button></div>
              <div class="buy-150-cards"><button @click="setQtdCards(150)">150</button></div>
              <div class="buy-200-cards"><button @click="setQtdCards(200)">200</button></div>
              <div class="buy-200-cards d-none d-md-block"><button @click="setQtdCards(300)">300</button></div>
            </div>
            <div class="buy d-flex justify-content-between text-white font-weight-bold">
              <span class="text-center mr-1 min-max align-self-center d-none d-md-block">
                Min: 01 <br>
                Max: 500
              </span>
              <div class="d-flex">
                <button class="less" @click="decrementCards">-</button>
                <input type="text" class="text-center card-purchase-input" v-model="cards.quantidade" readonly>
                <button class="more" @click="incrementCards">+</button>
              </div>
              <span class="total-price">{{ $currency }} {{ $helpers.convertAndFormatMoney(price * cards.quantidade) }} </span>
            </div>
          </div>
          <div class="col-5 col-md-4 buy-buttons mt-md-0 px-1 pt-md-1">
            <div class="d-flex flex-row flex-lg-column buy-buttons">
              <button class="agendar" @click="toggleScheduleModal">{{ $t("Scheduled purchase") }}</button>
              <button class="ml-1 ml-md-0 automatica" @click="toggleBuyAutomaticModal">{{ $t("Automatic purchase")
              }}</button>
            </div>
            <button class="comprar" :class="{
                'not-purchase-button': $helpers.convertMoney(price) * cards.quantidade > user.balance || cards.quantidade == 0,
                disabled: inactiveButton,
              }" @click="purchase">{{ $t("Buy") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="showBuyAutomaticContainer">
      <div class="col-lg-12 mx-3 mx-md-0 padding-sm-0">
        <div class="row automatic-buy-container">
          <div class="col-lg-12 w-100">
            <div class="text-center">
              <h3 class="font-weight-bold text-white px-5 d-none text-uppercase d-md-block">{{ $t("Automatic purchase") }}
              </h3>
            </div>
            <button class="float-right text-white h3 d-none d-md-block" @click="toggleBuyAutomaticModal"
              style="margin-top: -45px;">
              <img src="~@/assets/images/voltar.png"
                   alt="historico"
                   width="30px"/>
            </button>
            <button class="float-right text-white close-sm-automatic-buy-container d-block d-md-none"
              @click="toggleBuyAutomaticModal" style="">&#x2715;</button>
          </div>
          <div class="col-lg-12">
            <div
              class="buy d-flex justify-content-between align-items-md-center text-white font-weight-bold flex-column flex-md-row">
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-center mr-2 min-max align-self-center">
                  Min: 01 <br>
                  Max: 500
                </span>
                <div class="d-flex">
                  <button class="less" @click="decrementCards">-</button>
                  <input type="text" class="text-center card-purchase-input" v-model="cards.quantidade" readonly>
                  <button class="more" @click="incrementCards">+</button>
                </div>
                <span class="total-price">{{ $currency }} {{ $helpers.formatMoney($helpers.convertMoney(price) * cards.quantidade) }}</span>
              </div>
              <div class="automatic-buy-btn">
                <button class="comprar" :class="{
                  'not-purchase-button': price * cards.quantidade > user.balance || cards.quantidade == 0,
                  disabled: inactiveButton,
                }" @click="automaticPurchase">
                  <span class="d-none d-md-block">{{ $t("Buy") }}</span>
                  <span class="d-block d-md-none">{{ $t("Enable automatic purchase") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="(showScheduleContainer && showDesktopScheduleContainer)">
      <div class="schedule-buy-container w-100" v-if="datesPremiacoes.length > 0">
        <div class="col-lg-12 padding-sm-0">
          <div class="d-flex flex-row align-items-center justify-content-around">
            <div class="d-flex align-items-center justify-content-around">
              <label>{{ $t("Date") }}</label>
              <select class="form-control" name="" id="" @change="getPremiacoesPorData($event)" v-model="scheduleDate">
                <option value="0" selected disabled>{{ $t("Select") }}</option>
                <option :value="date.date" v-for="(date, index) in datesPremiacoes" :key="index">
                  {{ date.date | data_partida_especial }}
                </option>
              </select>
            </div>

            <div class="d-flex align-items-center justify-content-around mr-4 m-md-0">
              <label for="" class="ml-md-3">{{ $t("Guaranteed") }}</label>
              <select class="form-control"
                      name="schedule"
                      id="schedule"
                      @change="selectedSchedule()"
                      v-model="scheduleCards">
                <option value="0" selected disabled>{{ $t("Select") }}</option>
                <option v-bind:value="{ id: premio.id, valor_cartela: premio.valor_cartela }"
                  v-for="(premio, index) in premiacoes" :key="index">
                  {{ premio.data_hora | hora_partida_especial }} -
                  {{ premio.total && Number(premio.total) > Number(premio.valor_minimo) ? $currency +
                    $helpers.convertAndFormatMoney(premio.total) : $currency + $helpers.convertAndFormatMoney(premio.premio_garantido) }}
                  ({{ $t("Card") }} {{ $currency }}{{ $helpers.convertAndFormatMoney(premio.valor_cartela) }})
                </option>
              </select>
            </div>

            <button class="float-right text-white h3 ml-2 btn-close-historico d-none d-md-block"
              @click="toggleScheduleModal">
              <img src="~@/assets/images/voltar.png"
                   alt="historico"
                   width="30px"/>
            </button>
            <button class="float-right text-white btn-close-historico d-block d-md-none"
              @click="toggleScheduleModal">&#x2715;</button>
          </div>
        </div>
        <div class="col-lg-12 mt-2 padding-sm-0">
          <div class="d-flex flex-row align-items-center justify-content-around">
            <div class="d-flex align-items-center justify-content-around container-qtd">
              <label for="">{{ $t("Number of cards") }}</label>
              <div>
                <button @click="decrementScheduleCards">-</button>
              </div>
              <input class="form-control" type="text" v-model="cards.quantidade" readonly>
              <div>
                <button @click="incrementScheduleCards">+</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-around ">
              <label for="">Total</label>
              <span class="schedule-total-value d-flex align-items-center justify-content-center">{{ $currency }} {{
                $helpers.convertAndFormatMoney(cards.total || 0) }}</span>
            </div>
            <button class="btn-schedule-buy" @click="schedulePurchase">{{ $t("Buy") }}</button>
          </div>
        </div>
      </div>
      <div class="schedule-buy-container w-100" v-else>
        <div class="col-lg-12 py-3">
          <button class="float-right text-white h3 ml-2 btn-close-historico d-none d-md-block"
            @click="toggleScheduleModal">
            <img src="~@/assets/images/voltar.png"
                 alt="historico"
                 width="30px"/>
          </button>
          <button class="float-right text-white btn-close-historico d-block d-md-none"
            @click="toggleScheduleModal">&#x2715;</button>
          <div class="d-flex justify-content-center align-items-center">
            <h3 class="d-none d-md-block">{{ $t("No rounds currently scheduled") }}</h3>
            <p class="d-block d-md-none h6">{{ $t("No rounds currently scheduled") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card-schedule-dropdown" v-show="showScheduleContainer">
      <div v-if="datesPremiacoes.length > 0">
        <div class="d-flex justify-content-between align-content-center p-2">
          <div class="text-left schedule-header">
            <label>{{ $t("Date") }}</label>
            <select class="form-control" name="" @change="getPremiacoesPorData($event)" v-model="scheduleDate">
              <option value="0" selected disabled>{{ $t("Select") }}</option>
              <option :value="date.date" v-for="(date, index) in datesPremiacoes" :key="index">
                {{ date.date | data_partida_especial }}
              </option>
            </select>
          </div>
          <button class="h3 text-white" @click="toggleScheduleModal"
            style="position: absolute;top: 0px;right: 0px;">&#x2715;</button>
        </div>

        <div class="container row m-0 p-1">

          <div class="col-sm-12 col-md-12 mx-1 mt-2 schedule-card" v-for="(premio, index) in premiacoes" :key="index"
            @click="toogleBuyModal(premio)" :class="{ 'schedule-card-primary': index === 0 }">
            <div class="time">{{ $t('at') }} {{ premio.data_hora | hora_partida_especial }}</div>
            <div class="price">{{ premio.total && Number(premio.total) > Number(premio.valor_minimo) ? $currency + ' ' +
              $helpers.convertAndFormatMoney(premio.total) : $currency + ' ' + $helpers.convertAndFormatMoney(premio.premio_garantido) }}
            </div>
            <div class="d-flex justify-content-between align-content-center schedule-info">
              <div><b>{{ $t("Card") }}:</b> <span class="font-weight-light">{{ $currency }} {{
                $helpers.convertAndFormatMoney(premio.valor_cartela) }}</span></div>
            </div>
          </div>

        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between align-content-center p-2">
          <div class="text-left schedule-header align-self-center">
            <h5 class="m-0 p-0">{{ $t("No rounds currently scheduled") }}</h5>
          </div>
          <button class="h1 text-white m-0 p-0" @click="toggleScheduleModal">&#x2715;</button>
        </div>
      </div>
    </div>


    <div class="card-purchase-dropdown dropdown-menu show buyModal" v-show="showBuyModal">
      <div>
        <p class="ml-0 mt-2 px-4 h5">
          <strong>{{ $t("Buy cards for the round") }}</strong>
        </p>
        <p class="text-center font-weight-bold">
          <span>{{ $t("Scheduled round") }}</span>
        </p>
        <button class="close-dropdown text-white float-right h3" @click="buyModalOff()">
          &#x2715;
        </button>
      </div>
      <div class="row">
        <div class="col-12 px-5">
          <small>{{ $t("Card limit") }}</small>
          <div class="card-purchase-input">
            {{ cards.quantidade }}/{{ limitQuantity }}
          </div>
        </div>
        <div class="col-12">
          <small>{{ $t("Number of cards") }}</small>
          <div class="d-flex align-items-center px-5">
            <button class="card-schedule-purchase-operator mr-1" @click="decrementCards">
              -
            </button>
            <input min="1" :max="limitQuantity" type="text" class="text-center card-purchase-input"
              v-model="cards.quantidade" />
            <button class="card-schedule-purchase-operator ml-1" @click="incrementCards"
              :disabled="cards.quantidade >= limitQuantity">
              +
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-5">
          <small>{{ $t("Card price") }}</small>
          <div class="card-purchase-input">{{ $currency }} {{ $helpers.convertAndFormatMoney(price) }}</div>
        </div>
        <div class="col-12 px-5 align-self-end">
          <small>{{ $t("Purchase total") }}</small>
          <div class="card-purchase-input card-purchase-input-total">
            {{ $currency }} {{ $helpers.formatMoney($helpers.convertMoney(price) * cards.quantidade) }}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 px-5 mt-1 mb-3">
          <p class="text-center text-purchase" v-if="textPurchase">
            {{ $t("Wait") }} ...
          </p>
          <button v-if="(!textPurchase) || premiacoes_especial_id" :disabled="(price * cards.quantidade > user.balance) ||
            cards.quantidade == 0 ||
            inactiveButton
            " class="btn-purchase" :class="{
    'purchase-button': price * cards.quantidade <= user.balance,
    'not-purchase-button': price * cards.quantidade > user.balance || cards.quantidade == 0,
    disabled: inactiveButton,
  }" @click="schedulePurchase">
            {{ $t("Buy") }}
          </button>
        </div>
      </div>
    </div>

    <div class="card-purchase-dropdown dropdown-menu show" id="confirmAutomatic" v-show="showBuyAutomaticModal">
      <div>
        <p class="ml-0 mt-2 px-4 h5">
          <strong>{{ $t("Automatic purchase selected") }}</strong>
        </p>
        <p class="text-left ml-4">
          {{ $t("Confirming the option, the system will make automatic purchases until the lastround of the day.") }}
        </p>
      </div>

      <div class="row mt-2">
        <div class="col-12 px-5 mt-1 mb-3">
          <p class="text-center text-purchase" v-if="textPurchase">
            {{ $t("Wait") }} ...
          </p>

          <button class="btn btn-danger mr-3" @click="cancelBuy">{{ $t("No") }}</button>

          <button v-if="!textPurchase" class="btn btn-success" @click="confirmBuy">{{ $t("Yes") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import jQuery from "jquery";
import moment from "moment";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";
import {EventBus} from "@/services/EventBus";
import Notification from "@/services/Notification";

export default {
  name: "BuyButtons",
  computed: {
    ...mapState(["user", "session"]),
  },
  props: ["transactionId", "round"],
  data() {
    return {
      socket: null,
      cards: {
        user_id: null,
        partida_id: null,
        quantidade: 1,
        currency: null
      },
      specialCards: {
        user_id: null,
        premiacoes_especial_id: null,
        total: 0,
        quantidade: 0,
      },
      price: 0,
      statusBet: false,
      inactiveButton: false,
      datesPremiacoes: [],
      premiacoes: [],
      limitPurchase: 1000,
      limitQuantity: 500,
      buyAutomatic: false,
      confirmBuyAutomatic: false,
      showBuyAutomaticModal: false,
      textPurchase: false,
      awardNextRound: 40,
      premiacoes_especial_id: null,
      nextRoundSpecial: 0,
      showBuyAutomaticContainer: false,
      showScheduleContainer: false,
      scheduleCards: 0,
      scheduleDate: 0,
      qtdBallsSelected: 0,
      showDesktopScheduleContainer: false,
      showBuyModal: false,
      currency: null
    };
  },
  filters: {
    data_partida_especial: function (date) {
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      
      let language = 'pt-BR';
      if (localStorage.getItem('locale') === 'US') language = 'en-US';

      moment.locale(language);
      
      return capitalizeFirstLetter(moment(date).format("dddd - LL"));
    },

    hora_partida_especial: function (date) {
      let language = 'pt-BR';
      if (localStorage.getItem('locale') === 'US') language = 'en-US';
      
      moment.locale(language);
      
      return moment(date).format('LT');
    },
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.getRoundActive();
    this.getNextSpecialRound();
    this.listenToUserChange();
    
    setTimeout(() => {
      this.setPriceCards();
    }, 2000);
  },
  mounted() {
    this.currency = this.session?.currency;
    this.cards.currency = this.currency;
    
    //Desabilita a compra de cartelas para a rodada corrente
    this.socket.on("bola-salva", () => {
      this.statusBet = false;
      if (this.qtdBallsSelected === 0) {
        this.getNextSpecialRound();
      }
      this.qtdBallsSelected++;
    });

    this.socket.on("status-bet", (message) => {
      if (this.user.id == message.user.id) {
        this.statusBet = message.status;

        if (this.statusBet == false) {
          this.cards.partida_id = this.round + 1;
          this.statusBet = message.status;
        }
      }
    });

    document.addEventListener('mousedown', function (event) {
      if (event.detail > 1) {
        event.preventDefault();
      }
    }, false);

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.inactiveButton = false;
      this.statusBet = true;
      this.cards.partida_id = activeRound;
      this.getNextSpecialRound();
      this.qtdBallsSelected = 0;
    });

    this.socket.on("is-live", () => {
      setTimeout(() => {
        this.setPriceCards();
      }, 5000);
    });

    setTimeout(() => {
      this.setPriceCards();
    }, 5000);
  },
  methods: {
    confirmBuy() {
      this.buyAutomatic = true;
      this.confirmBuyAutomatic = true;
      this.showBuyAutomaticModal = false;

      // Enviar para cadastrar em compras_automatizadas
      this.cards.total = this.$helpers.fixMoney(this.$helpers.convertMoney(this.price) * this.cards.quantidade);
      
      if (!this.isQuantityValid(this.cards.quantidade)) {
        this.buyAutomatic = false;
        this.confirmBuyAutomatic = false;
        return;
      }

      this.$http.post("compras-automatizada/store", this.cards)
          .then((res) => {
            if (res.data.errors) {
              Notification.error(
                  res.data.error_title || 'Por favor, faça o login novamente para comprar a cartela',
                  res.data.error_message
              );
            } else {
              this.$emit('showTextAutomatic', this.cards.quantidade);
              jQuery("#confirmAutomatic").hide();
              jQuery("#buy-alerts, .box-automatic").fadeIn();
              setTimeout(() => {
                jQuery('#close-dropdown').trigger('click');
                jQuery("#buy-alerts, .box-automatic").fadeOut();
              }, 3000);
            }
          });

      this.showBuyAutomaticContainer = false;
    },

    cancelBuy() {
      this.$http.post("compras-automatizada/delete", this.cards).then(() => { });

      this.$emit('showTextAutomatic', 0);

      if (this.confirmBuyAutomatic) {
        jQuery("#buy-alerts, .box-cancel-confirm").fadeIn();

        setTimeout(() => {
          jQuery("#buy-alerts, .box-cancel-confirm").fadeOut();
        }, 3000);
      } else {
        jQuery("#buy-alerts").fadeOut();
      }

      this.buyAutomatic = false;
      this.confirmBuyAutomatic = false;
      this.showBuyAutomaticModal = false;
    },

    purchase: function () {
      if (this.inactiveButton
          || this.showScheduleContainer
          || !this.isQuantityValid(this.cards.quantidade)
      ) {
        return;
      }
      
      if (this.cards.partida_id && this.cards.quantidade > 0) {
        this.textPurchase = true;
        this.inactiveButton = true;
        //Efetua a compra para a rodada corrente
        this.cards.total = this.$helpers.fixMoney(this.$helpers.convertMoney(this.price) * this.cards.quantidade);
        
        /**
         * Rack temporário. Apagar depois!
         * */
        if (this.cards.total > this.limitPurchase) {
          Notification.error(
            this.$t('Maximum purchase allowed') + " " + this.limitQuantity + " " + this.$t('Cards')
          );

          this.inactiveButton = false;
          this.textPurchase = false;
          return;
        }

        if (parseFloat(this.cards.total) > parseFloat(this.user.balance)) {
          jQuery(".close-dropdown").trigger("click");          
          Notification.error(
            this.$t('Insufficient funds')
          );

          this.inactiveButton = false;
          this.textPurchase = false;
          return;
        }

        //Compra as cartelas
        // Faz a requisição de compra para o operador
        this.$http
            .post("place-bet", {
              roundId   : this.cards.partida_id,
              quantidade: this.cards.quantidade,
              makeCards : 'now'
            })
          .then((res) => {
            // Criar as cartelas
            if (!res.data.errors) {
              // Criar as cartelas
              this.user.balance = res.data.new_user_balance;
              this.$emit('buyCardsEvent');

              //Zera a quantidade de cartelas se não estiver no automático
              if (!this.buyAutomatic) {
                this.cards.quantidade = 0;
              }

              Notification.success(this.$t('successfully purchased'));
            } else if (res.data.errors.error_type == 'quantity_limit_per_round') {
              Notification.error(
                this.$t('Round card limit reached') + res.data.errors.limit
              );
            } else if (res.data.errors.error_type == 'quantity_limit_per_user') {
              Notification.error(
                this.$t('Player card limit exceeded') + res.data.errors.limit
              );
            } else {
              Notification.error(
                this.$t('Please, log in again to buy the card')
              );
            }
            
            this.inactiveButton = false;
            this.textPurchase = false;
          });
      }
    },

    automaticPurchase() {
      if (this.isQuantityValid(this.cards.quantidade)) {
        this.showBuyAutomaticModal = true;
      }
    },

    //PEGA A PARTIDA ATIVA
    getRoundActive: function () {
      this.$http.get("partida-ativa").then((res) => {
        if (res.data) {
          this.cards.partida_id = res.data.id;
        }
      });
    },

    getNextSpecialRound: function () {
      this.$http.get("proxima-partida-especial").then((res) => {
        if (res.data) {
          // Verificar se proxima partida é especial
          if (res.data?.proxima_partida_especial) {
            this.nextRoundSpecial = res.data.proxima_partida_especial;
          } else {
            this.nextRoundSpecial = 0;
            this.setPriceCards();
          }

          // se a rodada seguinte é especial, retornar o valor da cartela
          if (this.nextRoundSpecial > 0) {
            this.$http
              .get("get-premiacao-id/" + this.nextRoundSpecial)
              .then((res) => {
                this.price = res.data.valor_cartela;
                localStorage.setItem("card-value", res.data.valor_cartela);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      });
    },

    setPriceCards: function () {
      this.price = localStorage.getItem('card-value');
    },

    decrementCards() {
      this.setPriceCards();
      if (this.cards.quantidade > 1) {
        this.cards.quantidade--;
      }
    },

    incrementCards() {
      this.setPriceCards();
      if (this.isQuantityValid(this.cards.quantidade + 1)) {
        this.cards.quantidade++;
      }
    },
    
    isQuantityValid(quantity, cardPrice = null) {
      cardPrice = this.$helpers.convertMoney(cardPrice ?? this.price);
      // TODO: Find bet limit and calc the limitQuantity
      if (quantity > this.limitQuantity) {
        Notification.error(
            this.$t('Maximum purchase allowed') + " " + this.limitQuantity + " " + this.$t('Cards')
        );
      } else if (this.user.balance < cardPrice * quantity) {
        Notification.error(
            'Low balance',
            'Your balance is low, please make a deposit to continue playing'
        );
      } else if (quantity == 0) {
        Notification.error(
            'Invalid quantity',
            'Please select a valid quantity'
        );
      } else {
        return true;
      }
      
      return false;
    },

    getDatasPremiacoes() {
      this.$http
        .get("get-datas-premiacoes")
        .then((res) => {
          this.datesPremiacoes = res.data;
          this.getPremiacoesPorData(null, this.scheduleDate ?? res?.data[0]?.date);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getPremiacoesPorData(event, data = null) {
      if (event) {
        data = event.target.value
      }
      this.$http
        .get("get-premiacao-data/" + data)
        .then((res) => {
          this.premiacoes = res.data;
          this.scheduleCards = 0;
          this.cards = {
            partida_id: this.cards.partida_id,
            quantidade: 0,
            currency: this.currency
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setQtdCards(quantity) {
      if (this.isQuantityValid(quantity)) {
        this.cards.quantidade = quantity;
      }
    },

    toggleBuyAutomaticModal() {
      this.showBuyAutomaticContainer = !this.showBuyAutomaticContainer;
    },

    toggleScheduleModal(e, value) {
      if (document.body.offsetWidth >= 992) {
        this.showDesktopScheduleContainer = true;
      } else {
        this.showDesktopScheduleContainer = false;
      }

      this.showScheduleContainer = !this.showScheduleContainer;

      if (this.showScheduleContainer && !this.showDesktopScheduleContainer) {
        jQuery("#buy-alerts").fadeIn();
      } else if (value === undefined) {
        jQuery("#buy-alerts").fadeOut();
      }

      this.cards = {
        partida_id: this.cards.partida_id,
        quantidade: 1,
        currency: this.currency
      };
      
      if (this.showScheduleContainer) {
        this.getDatasPremiacoes();
      } else if (this.nextRoundSpecial == 0) {
        this.setPriceCards();
      } else {
        this.getNextSpecialRound();
      }
    },

    decrementScheduleCards() {
      if (this.cards.quantidade > 1 && this.scheduleCards != 0) {
        this.cards.quantidade--;
        this.cards.total = (this.scheduleCards.valor_cartela * this.cards.quantidade).toFixed(2);
      }
    },

    incrementScheduleCards() {
      if (this.isQuantityValid(this.cards.quantidade + 1, this.scheduleCards.valor_cartela)) {
        this.cards.quantidade++;
        this.cards.total = (this.scheduleCards.valor_cartela * this.cards.quantidade).toFixed(2);
      }
    },

    selectedSchedule() {
      this.premiacoes_especial_id = this.scheduleCards.id;
      this.price = this.scheduleCards.valor_cartela;
      if (this.cards.quantidade > 0) {
        this.cards.total = (this.scheduleCards.valor_cartela * this.cards.quantidade).toFixed(2);
      }
    },

    schedulePurchase: function () {
      if (!this.isQuantityValid(this.cards.quantidade, this.scheduleCards.valor_cartela)
        || this.scheduleCards === 0
      ) {
        return;
      }

      if (this.premiacoes_especial_id) {
        this.textPurchase = true;
        this.inactiveButton = true;

        this.specialCards = {
          premiacoes_especial_id: this.premiacoes_especial_id,
          quantidade: this.cards.quantidade
        };

        if (this.specialCards.total > this.limitPurchase) {
          Notification.error(
            this.$t('Maximum purchase allowed') + " " + this.limitQuantity + " " + this.$t('Cards')
          );

          this.inactiveButton = false;
          this.textPurchase = false;
          this.showBuyModal = false;
          return;
        }

        if (parseFloat(this.specialCards.total) > parseFloat(this.user.balance)) {
          jQuery(".close-dropdown").trigger("click");
          Notification.error(
            this.$t('Insufficient funds')
          );
          this.inactiveButton = false;
          this.textPurchase = false;
          this.showBuyModal = false;
          return;
        }

        //Compra as cartelas
        this.$http.post("cartelas-especial", this.specialCards).then((res) => {
          if (!res.data.errors) {
            this.user.balance = res.data.user.balance;
            EventBus.$emit("getBalance");

            Notification.success(this.$t('successfully purchased'));
          } else if (res.data.errors.error_type == 'quantity_limit_per_round') {
            Notification.error(
              this.$t('Round card limit reached') + res.data.errors.limit
            );
          } else if (res.data.errors.error_type == 'quantity_limit_per_user') {
            Notification.error(
              this.$t('Player card limit exceeded') + res.data.errors.limit
            );
          } else {
            Notification.error(
              this.$t('Please, log in again to buy the card')
            );
          }

          this.inactiveButton = false;
          this.textPurchase = false;
          this.showBuyModal = false;
          this.toggleScheduleModal(null, 'buy');
        });
      }
    },

    cancelBuyAutomatic() {
      this.cards.quantidade = 1;
      this.cancelBuy();
    },

    toogleBuyModal(premio = null) {
      this.showBuyModal = true;
      // Coloca o valor da cartela da partida especial selecionada no modal de compra agendada
      if (premio.id) {
        this.premiacoes_especial_id = premio.id;
        this.scheduleCards = { id: premio.id, valor_cartela: premio.valor_cartela }
        this.price = premio.valor_cartela;
        jQuery("#buy-alerts").fadeIn();
      }
    },

    buyModalOff() {
      this.showBuyModal = false;
    },

    listenToUserChange() {
      EventBus.$on("userFetched", () => {
        if (this.nextRoundSpecial == 0) {
          this.setPriceCards();
        }
      });
    },
  },
};
</script>
