<template>
  <div class="col-12 col-lg-3 box px-0 pl-lg-1">
    <div class="head-box bg-yellow text-center pt-3 pb-3">
      <span>GANHADORES</span>
    </div>

    <div class="container-box bg-black">
      <div class="container-winners">
        <div :class="{ 'bg-1': index % 2 == 0, 'bg-2': index % 2 != 0 }"
             class="list px-3 py-1 text-white"
             v-for="(winner, index) in winners"
             :key="index">
          <p v-if="winner.valor > 0"
             :class="'pb-1 premiacao-tipo-' + winner.premiacao_tipo.id">
            <span class="winner-ball mr-2">
              <img src="~@/assets/images/white-ball.svg"
                   class="img-fluid">
              <span>{{ winner.bola }}</span>
            </span>
            
            {{ winner.user.name }} (
            {{ winner.premiacao_tipo.premiacao_tipo }} ) {{ $currency }} {{ winner.valor }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import jQuery from "jquery";
import Socket from "@/services/Socket";

export default {
  name: "Winners",
  props: ["round"],
  data() {
    return {
      socket: null,
      winners: [],
      alertWinners: [],
      roundActive: null,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.$root.$refs.Winners = this;
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.roundActive = this.round;
    this.getWinners(true);
  },

  mounted() {
    this.socket.emit("list-winners", { room: "bingo" });

    //Limpa a lista de ganhadores
    this.socket.on("partida", (message) => {
      if (message.user.id == this.user.id) {
        this.winners = [];
        this.alertWinners = [];
        this.getWinners();
      }
    });

    jQuery(document).on('click', function () {
      if (jQuery('#award').hasClass('shown')) {
        setTimeout(() => {
          jQuery('#award, #award img').fadeOut()
                                      .removeClass('shown');
        }, 500);
      }
    });

    this.startProcessing(500);
  },
  methods: {
    async getWinners(reloadPage = false, attemptCount = 0) {
      if (attemptCount >= 10) {
        console.error('Failed to get winners after 10 attempts');
        return;
      }
      
      this.roundActive = this.round;
      await this.$http.get('ganhador-user')
        .then(async (res) => {
          if (res.data.length > 0) {
            this.winners = [];
            res.data.forEach((element) => {
              if (parseFloat(element.valor) > 0) {
                this.winners.push(element);
              }

              if (
                element.premiacao_tipo.premiacao_tipo.toLowerCase() == 'bingo'
              ) {
                this.socket.emit('status-bet', {
                  room   : 'bingo',
                  message: {
                    user  : {
                      id  : this.user.id,
                      tipo: this.user.tipo,
                    },
                    status: false
                  },
                });
              }
            });

            if (this.winners.length > this.alertWinners.length) {
              let string =
                res.data[
                  res.data.length - 1
                ].premiacao_tipo.premiacao_tipo.toLowerCase();

              let lastCartelaId = 0;
              let lastPremiacaoTipoId = 0;

              for (const data of res.data) {
                if (!data.pago && (lastCartelaId !== data.cartela_id || lastPremiacaoTipoId !== data.premiacao_tipo_id)) {
                  // 1° Enviar saldo para ganhador; Verificar de data.pago é falso
                  // 2° na resposta de atualização do ganhador, enviar pago para a premiação;
                  // 3° Enviar socket para atualizar o saldo do jogador;
                  // Enviar para API

                  lastCartelaId = data.cartela_id;
                  lastPremiacaoTipoId = data.premiacao_tipo_id;

                  const dataUser = {
                    premiacao_jogador_id: data.id,
                    user_id: data.user_id,
                    cartela_id: data.cartela_id,
                    premiacao_tipo_id: data.premiacao_tipo_id
                  };

                  //Se não for demo, envia premio para a API
                  if (Number(data.valor) > 0) {
                    this.addToQueue({
                      userId       : data.user.site_user_id,
                      currency     : data.user.currency,
                      transactionId: String(data.id),
                      amount       : data.moeda_valor_total,
                      roundId      : String(this.round),
                      token        : data.user.token,
                      gameId       : 4,
                      siteId       : data.user.site.casino_id,
                    });
                  }

                  try {
                    const resp = await this.$http.patch("user/update-balance", dataUser);
                    //Envia atualização de saldo para os jogadores (front)
                    this.socket.emit("update-balance", {
                      room: "bingo",
                      message: { user: resp.data },
                    });
                    // Envia a lista de ganhaores (front)
                    this.socket.emit("dispatch-winners", {
                      room: "bingo",
                      message: this.winners,
                    });

                    this.user.balance = resp.data.balance;
                  } catch (err) {
                    console.error(err);
                  }
                }
              }

              /**
               * Exibir a imagem do tipo de premiação
               * */
               if (!reloadPage && this.alertWinners.indexOf(string) === -1) {
                 if (string == 'linha') {
                   jQuery('#award, #img-line').fadeIn();
                 } else if (string == 'linha dupla') {
                   jQuery('#award, #img-line-two').fadeIn();
                 } else if (string == 'bingo' || string == 'acumulado') {
                   jQuery('#award, #img-bingo').fadeIn();
   
                   if (Number(localStorage.getItem('close-bets-round')) !== this.roundActive) {
                     localStorage.setItem('close-bets-round', this.roundActive);
                     setTimeout(() => {
                       this.closeBets();
                     }, 5000);
                   }
                 }

                 jQuery('#award').addClass('shown');

                 if (window.hideAwardAlertTimeout) {
                   clearTimeout(window.hideAwardAlertTimeout);
                 }

                 window.hideAwardAlertTimeout = setTimeout(() => {
                   jQuery('#award, #award img').fadeOut();
                 }, 4000);
               }

              this.alertWinners.push(string);

              this.socket.emit('status-plans', {
                room: 'bingo',
                message: res.data,
              });

              if (
                string == 'bingo' ||
                string == 'bingo 2' ||
                string == 'bingo 3' ||
                string == 'bingo 4' ||
                string == 'bingo 5'
              ) {
                this.roundActive = null;
                this.socket.emit('show-cards', {
                  room: 'bingo',
                  message: { status: false },
                });

                /**
                 * Emite uma mensagem ao socket dizendo que as apostas para a partida corrente estão encerradas
                 * */
                this.$notify({
                  title: '<b>Alerta!</b>',
                  text: 'Fim desta rodada.',
                  type: 'warn',
                  duration: 5000,
                });
                this.socket.emit('status-bet', {
                  room   : 'bingo',
                  message: {
                    user  : {
                      id  : this.user.id,
                      tipo: this.user.tipo,
                    },
                    status: false
                  },
                });
              }
            }

            this.winners = res.data;
          }
          
          // Liberar próxima bola
          this.$emit('liberateBall');
        });
    },

    async awardWinnings(data) {
      return await this.$http.post("award-winnings", data);
    },

    listWinners() {
      this.getWinners();
    },

    loadQueue() {
      const loadedQueue = localStorage.getItem('requestQueueAwardWinnings');
      return loadedQueue ? JSON.parse(loadedQueue) : [];
    },

    saveQueue() {
      localStorage.setItem('requestQueueAwardWinnings', JSON.stringify(this.queue));
    },

    addToQueue(request) {
      this.queue.push(request);
      this.saveQueue();
    },

    async processQueue() {
      if (this.isProcessing || this.queue.length === 0) {
        return;
      }

      this.isProcessing = true;
      const request = this.queue[0];

      try {
        // Replace this with your actual request sending logic
        await this.awardWinnings(request);

        // Remove processed request from queue and save
        this.queue.shift();
        this.saveQueue();
        this.isProcessing = false;
      } catch (err) {
        console.error('Error processing request:', err);
        if (request.attempts >= 5) {
          // if request has already been attempted 5 times, remove it from queue and save
          this.queue.shift();
          console.error('Request failed after 5 attempts', request);
        } else {
          // otherwise, increment attempts and save
          request.attempts = (request.attempts || 1) + 1;
        }
        
        this.saveQueue();
        
        setTimeout(() => {
          this.isProcessing = false;
        }, 1000);
      }
    },

    startProcessing(interval = 100) {
      if (this.processInterval) {
        return;
      }

      this.queue = this.loadQueue();
      this.processInterval = setInterval(() => this.processQueue(), interval);
    },

    stopProcessing() {
      if (this.processInterval) {
        clearInterval(this.processInterval);
        this.processInterval = null;
      }
    },

    closeBets(attempts = 0) {
      this.$http.post('close-bets')
          .then((response) => {
            if (response.data.errors) {
              if (attempts < 5) {
                setTimeout(() => {
                  this.closeBets(attempts + 1);
                }, 1000);
              }
            }
          })
          .catch(() => {
            if (attempts < 5) {
              setTimeout(() => {
                this.closeBets(attempts + 1);
              }, 1000);
            }
          });
    }
  },
};
</script>
