import Vue from 'vue';
import VueRouter from 'vue-router';
import Player from '../views/Player';
import Dealer from '../views/Dealer';
import Login from '../views/Login';
import Home from '../views/Home';
import Idle from '../views/Idle';
import Alert from '../views/Alert';

const routes = [
    {path: '/', redirect: '/home', meta: {auth: false}},
    {name: 'game', path: '/game', component: Player, meta: {auth: false}},
    {name: 'launch', path: '/launch/:hash', component: Player, meta: {auth: false}},
    {name: 'test22', path: '/test22', component: Player, meta: {auth: false}},
    {name: 'dealer', path: '/dealer', component: Dealer, meta: {auth: true}},
    {name: 'login', path: '/login', component: Login, meta: {auth: false}},
    {name: 'home', path: '/home', component: Home, meta: {auth: false}},
    {name: 'idle', path: '/idle', component: Idle, meta: {auth: false}},
    {name: 'alert', path: '/alert', component: Alert, meta: {auth: false}},
];

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        const logged = window.localStorage.getItem('user');
        if (!logged) {
            next('/');
            return;
        }
    }
    next();
});

export default router