<template>
  <div v-if="(time === '00:00:00' || time === '00:03:00') || !isLiveTime" class="h-100 w-90">
    <div class="d-flex justify-content-center align-items-center text-center proximo-premio">
      <div class="d-flex flex-column">
        <span class="title d-none d-md-block">{{ $t("Prize next round") }}</span>
        <span class="title d-block d-md-none">{{ $t("Next round") }}</span>
        <span class="valor">{{ $currency }} {{ $helpers.convertAndFormatMoney(awardNextRound) }}</span>
      </div>
    </div>
  </div>
  <div v-else class="container-countdown w-90">
    <div class="d-flex justify-content-center align-items-center text-center proximo-premio mb-1">
      <div class="d-flex flex-column">
        <span class="title d-none d-md-block">{{ $t("Prize next round") }}</span>
        <span class="title d-block d-md-none">{{ $t("Next round") }}</span>
        <span class="valor">{{ $currency }} {{ $helpers.convertAndFormatMoney(awardNextRound) }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center text-center proximo-premio countdown"
         v-if="time && time !== '00:00:00'">
      <div class="d-flex flex-column">
        <div id="countdown-bar" :style="'width: ' + percentTime + '%;'">
        </div>
        <span v-if="isLive">{{ $t("Next round in") }} {{ time }}</span>
        <span v-else>{{ $t("Next match live on") }} {{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { EventBus } from '@/services/EventBus';
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";

export default {
  name: "NextRoundPrize",
  props: ["round"],
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      isLive: true,
      intervalId: null, // Armazena a referência do intervalo
      socket: null,
      awardNextRound: 40,
      time: "00:00:00",
      startTime: "00:00:00",
      percentTime: "30",
      partida_id: null,
      countBalls: 0,
      nextCardsAcumulado: 0,
      isLiveTime: false
    };
  },
  mounted() {
    this.socket = Socket.initialize();
    this.partida_id = this.$helpers.getActiveRound();
    this.getPrices();
    //Atrela os valores de premiações quando efetuam compra de cartelas
    this.socket.on("list-plans", () => {
      this.getPrices();
    });

    this.socket.on("update-total-cards", (message) => {
      //this.getPrices();
      this.updatePrices(message.prizes);
    });

    this.socket.on("show-timer", (message) => {
      this.handleOpeningHoursUpdate(true);
      if (this.startTime === "00:00:00" || this.time > this.startTime) this.startTime = message.time;
      this.time = message.time;
      this.changingCardsRule();
      this.percentCountDown();
      this.fixCountdownPositionMobile();
    });

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.countBalls = 0;
      this.startTime = "00:00:00";
      this.partida_id = activeRound;
      this.isLiveTime = localStorage.getItem('is-rng-running') == 0 ? true : false;
      setTimeout(() => {
        this.getPrices();
      }, 5000);
    });

    this.socket.on("is-live", () => {
      setTimeout(() => {
        this.isLiveTime = localStorage.getItem('is-rng-running') == 0 ? true : false;
        this.getPrices();
      }, 5000);
    });

    this.isLiveTime = localStorage.getItem('is-rng-running') == 0 ? true : false;

    // Registra o ouvinte para o evento 'openingHoursUpdated'
    EventBus.$on('openingHoursUpdated', this.handleOpeningHoursUpdate);
  },
  beforeDestroy() {
    // Remove o ouvinte do EventBus
    EventBus.$off('openingHoursUpdated');

    // Limpa o intervalo de tempo se ele foi configurado
    if (this.intervalId) {
        clearInterval(this.intervalId);
    }
  },
  methods: {
    // Manipula a atualização das horas de funcionamento para apresentar o Timer em contagem regressiva até horário da próxima Live
    handleOpeningHoursUpdate(isLive) {
        this.isLive = isLive; 
        this.showChronometer = this.shouldShowChronometer(); // Determina se o cronômetro deve ser mostrado
        this.time = this.isLive ? this.time : this.stopwatch; // Atualiza o tempo baseado no estado de 'isLive'

        this.setupChronometerInterval(); // Configura o intervalo para atualizar o cronômetro
    },

    // Determina se o Timer em até horário da próxima Live deve ser mostrado
    shouldShowChronometer() {
        const currentHour = new Date().getHours();
        return localStorage.getItem('is-rng-running') == 0 && !(currentHour >= 15 && currentHour < 23);
    },

    // Calcula o tempo restante e o progresso do Timer até às 15h
    calculateChronometer() {
      const now = new Date();
      const currentHour = now.getHours();

      // Se a hora atual for entre 15h e 23h, o Timer mostrará 00:00:00 e progresso 0%
      if (currentHour >= 15 && currentHour < 23) {
          return { formattedTime: '00:00:00', progress: 0 };
      }

      // Define o horário alvo para as 15:00 do mesmo dia ou do próximo dia
      const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0);
      if (now >= targetTime) {
          targetTime.setDate(targetTime.getDate() + 1);
      }

      // Calcula a diferença de tempo em milissegundos
      const differenceInMilliseconds = targetTime - now;

      // Calcula o tempo total desde a meia-noite em milissegundos
      const totalMilliseconds = 24 * 60 * 60 * 1000;

      // Calcula o progresso do Timer até o horário da próxima Live
      const progress = ((totalMilliseconds - differenceInMilliseconds) / totalMilliseconds) * 100;

      // Formata o tempo restante em horas:minutos:segundos
      const formattedTime = this.formatTime(differenceInMilliseconds);

      return { formattedTime, progress };
    },

    // Formata o tempo em horas, minutos e segundos
    formatTime(milliseconds) {
        const hours = Math.floor(milliseconds / 3600000).toString().padStart(2, '0');
        const minutes = Math.floor((milliseconds % 3600000) / 60000).toString().padStart(2, '0');
        const seconds = Math.floor((milliseconds % 60000) / 1000).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    },

    // Configura um intervalo para atualizar o cronômetro a cada segundo
    setupChronometerInterval() {
      // Certifica de limpar qualquer intervalo existente antes de configurar um novo
      if (this.intervalId) {
          clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
          // Verifica se o Timer deve ser exibido e se o estado 'isLive' é falso
          if (!this.isLive && this.showChronometer) {
              // Calcula o tempo e o progresso para o Timer
              const { formattedTime, progress } = this.calculateChronometer();

              // Atualiza o estado do componente com o tempo formatado e o progresso
              this.time = formattedTime;
              this.percentTime = progress;
          }
      }, 1000);
    },

    getPrices() {
      this.$http
        .get("lista-valores-premiacao/" + this.user.site.id, this.cards)
        .then((res) => {
          this.updatePrices(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updatePrices(data) {
      this.awardNextRound = data.proxima_partida_premiacao;
    },

    percentCountDown() {
      const startHours = Number(this.startTime.split(":")[0]) * 60 * 60;
      const startMinutes = Number(this.startTime.split(":")[1]) * 60;
      const startSeconds = Number(this.startTime.split(":")[2]);

      const currentHours = Number(this.time.split(":")[0]) * 60 * 60;
      const currentMinutes = Number(this.time.split(":")[1]) * 60;
      const currentSeconds = Number(this.time.split(":")[2]);

      const start = startHours + startMinutes + startSeconds;
      const current = currentHours + currentMinutes + currentSeconds;

      this.percentTime = Math.abs((((current * 100) / start) - 100).toFixed(0));
    },

    changingCardsRule() {
      let time = parseInt(this.time.split(":")[0] * 60 * 60)
          + parseInt(this.time.split(":")[1] * 60)
          + parseInt(this.time.split(":")[2]);

      if (time < 10 || this.time == '00:03:00') { // initial time
        EventBus.$emit("setIsChangingCardsAllowed", false);
      } else {
        EventBus.$emit("setIsChangingCardsAllowed", true);
      }
    },
    
    fixCountdownPositionMobile() {
      if (window.innerWidth > 992) return;
      
      let ele = document.querySelector('.proximo-premio.countdown');
      let topCountdown = 15;

      topCountdown += document.querySelector('.container-header').offsetHeight;
      topCountdown += document.querySelector('.container-video').offsetHeight;

      ele.style.top = `${topCountdown}px`;
    }
  },
};
</script>
