<template>
  <div class="d-flex justify-content-between">
    <div class="w-100 line-info position-relative">
      <div class="d-flex align-items-center prize-container">
        <img v-lazy-src="require('@/assets/images/player/square-line.png')" alt="">
        <div class="d-flex flex-column">
          <span v-if="!lineOne.awarded" class="andamento">{{ $t("In progress") }}</span>
          <span v-else class="premiado d-none d-md-block">{{ $t("Awarded") }}</span>
          <span class="title">{{ $t("Line") }}</span>
          <b>{{ $currency }} {{ $helpers.convertAndFormatMoney(lineOne.value) }}</b>
        </div>
      </div>
    </div>

    <div class="w-100 px-2 double-line-info position-relative">
      <div class="d-flex align-items-center prize-container">
        <img v-lazy-src="require('@/assets/images/player/square-double-line.png')" alt="">
        <div class="d-flex flex-column">
          <span v-if="!lineTwo.awarded && lineOne.awarded" class="andamento">{{ $t("In progress") }}</span>
          <span v-if="lineTwo.awarded" class="premiado d-none d-md-block">{{ $t("Awarded") }}</span>
          <span v-if="!lineOne.awarded" class="aguardando d-none d-md-block">{{ $t("Waiting") }}</span>
          <span class="title">{{ $t("Double line") }}</span>
          <b>{{ $currency }} {{ $helpers.convertAndFormatMoney(lineTwo.value) }}</b>
        </div>
      </div>
    </div>

    <div class="w-100 bingo-info position-relative">
      <div class="d-flex align-items-center prize-container">
        <img v-lazy-src="require('@/assets/images/player/square-bingo.png')" alt="">
        <div class="d-flex flex-column">
          <span v-if="!bingo.awarded && lineTwo.awarded" class="andamento">{{ $t("In progress") }}</span>
          <span v-if="bingo.awarded" class="premiado d-none d-md-block">{{ $t("Awarded") }}</span>
          <span v-if="(!lineOne.awarded || !lineTwo.awarded)" class="aguardando d-none d-md-block">{{ $t("Waiting")
          }}</span>
          <span class="title">Bingo</span>
          <b>{{ $currency }} {{ $helpers.convertAndFormatMoney(bingo.value) }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.next-money span {
  font-size: 13px;
}
</style>

<script>
import { mapState } from "vuex";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";

export default {
  name: "Info",
  props: ["round"],
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      socket: null,
      accumulated: { value: 0, awarded: null },
      lineOne: { value: 0, awarded: null },
      lineTwo: { value: 0, awarded: null },
      bingo: { value: 0, awarded: null },
      bingoOne: { value: 0, awarded: null },
      bingoTwo: { value: 0, awarded: null },
      bingoThree: { value: 0, awarded: null },
      bingoFour: { value: 0, awarded: null },
      bingoFive: { value: 0, awarded: null },
      minBuy: 100,
      awardNextRound: 40,
      time: "00:00:00",
      partida_id: null,
      countBalls: 0,
      nextCardsAcumulado: 0,
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.getPrices();
    this.partida_id = this.$helpers.getActiveRound();

    this.socket.on("show-timer", (message) => {
      this.time = message.time;
    });
  },
  mounted() {
    //Atrela os valores de premiações quando efetuam compra de cartelas
    this.socket.on("list-plans", () => {
      this.getPrices();
    });

    //Altera o status da premiação
    this.socket.on("status-plans", (message) => {
      setTimeout(() => {
        this.statusPlans(message);
      }, localStorage.getItem('is-rng-running') == 0 ? 0 : 3000);
    });

    this.getStatus();

    this.socket.on("update-total-cards", (message) => {
      //this.getPrices();
      this.updatePrices(message.prizes);
    });

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.countBalls = 0;
      this.partida_id = activeRound;
      this.getStatus();
      setTimeout(() => {
        this.getPrices();
      }, 5000);
    });

    this.socket.on("is-live", () => {
        this.getPrices();
    });
  },
  methods: {
    getPrices() {
      this.$http
        .get("lista-valores-premiacao/" + this.user.site.id, this.cards)
        .then((res) => {
          this.updatePrices(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePrices(data) {
      this.lineOne.value = data?.linha;
      this.lineTwo.value = data?.linha_dupla;
      this.bingo.value = data?.bingo;
      data?.valor_cartela && localStorage.setItem("card-value", data?.valor_cartela);
    },
    statusPlans(status) {
      const awardsMap = {
        1: 'lineOne',
        2: 'lineTwo',
        3: 'bingo',
        4: 'accumulated',
        5: 'bingoTwo',
        6: 'bingoThree',
        7: 'bingoFour',
        8: 'bingoFive',
      };

      // Reset all awards to false
      Object.values(awardsMap).forEach(awardKey => {
        this[awardKey].awarded = false;
      });

      // Set the corresponding awards to true
      status.forEach((item) => {
        const awardKey = awardsMap[item.premiacao_tipo_id];
        if (awardKey) {
          this[awardKey].awarded = true;
        }
      });
    },

    getStatus(attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to get winners after 5 attempts');
        return;
      }
      
      this.$http.get("ganhadores").then(async (res) => {
        this.statusPlans(res.data);
      })
          .catch((error) => {
            console.error(error);

            setTimeout(() => {
              this.getStatus(attemptCount + 1);
            }, 500);
          });
    }
  },
};
</script>
