<template>
  <div id="bg-login">
    <div class="d-flex justify-content-center align-items-center">
      <div class="col-12 col-sm-8 col-md-7 col-lg-4 text-center mt-5 pt-5">
        <div class="row">
          <div class="col">
            <img src="~@/assets/images/logo-everest.png" class="logo-copacabana mb-5" />
          </div>
        </div>

        <div class="row">
          <div class="col my-4">
            <span class="text-white login-span">Sistema Dealer Bingo</span>
          </div>
        </div>

        <form @submit.prevent="submit" @submit="login">
          <div class="row">
            <div class="col text-center">
              <input type="email" id="email" class="form-control" placeholder="email" />
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <input type="password" id="password" class="form-control" placeholder="senha" />
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <button class="btn btn-outline-secondary btn-lg btn-block" @click="login">Login</button>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col text-center mt-3">
            <span class="text-danger"></span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'

export default {
  name: "Login",
  components: {},
  computed: mapState(['user']),
  data() {
    return {}
  },
  beforeMount() {
    this.verifyUser();
  },
  methods: {
    verifyUser() {
      let userLocal = localStorage.getItem('user');
      if (userLocal) {
        localStorage.removeItem('user');
        this.$store.commit('setUser', 'login');
      }
    },
    login() {
      let email = document.getElementById('email');
      let password = document.getElementById('password');
      let text = document.getElementsByClassName('text-danger');

      if (email.value == '' || password.value == '') {

        text[0].textContent = 'Email ou Senha não podem ser vazios';

        this.clearMessage();
      } else {
        axios.post('login', { email: email.value, password: password.value }).then((res) => {
          if (res.data.id) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
            localStorage.setItem('user', JSON.stringify(res.data));
            this.$store.commit('setUser', res.data);

            if (res.data.name === 'admin') {
              this.$router.replace('dealer');
            } else {
              window.location.replace('/game?site_id=1&user_id=' + res.data.site_user_id + '&token=1&currency=BRL&user_name=' + res.data.name + '&locale=BR');
              
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          } else {
            text[0].textContent = 'Credenciais Inválidas';
            this.clearMessage();
          }
        }).catch((error) => {
          text[0].textContent = 'Ocorreu um erro inesperado';
          console.log(error)
          this.clearMessage();
        })
      }
    },
    clearMessage() {
      let text = document.getElementsByClassName('text-danger');

      setTimeout(function () {
        text[0].textContent = '';
      }, 3000)
    }
  }
};
</script>
