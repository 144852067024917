<template>
  <div id="bg-login">
    <div class="d-flex justify-content-center align-items-center">
      <div class="col-12 col-sm-8 col-md-7 col-lg-4 text-center mt-5 pt-5">
        <div class="row">
          <div class="col">
            <img src="~@/assets/images/logo-everest.png" class="logo-copacabana mb-5" />
          </div>
        </div>

        <div class="row">
          <div class="col my-4">
            <span class="text-white register-span">{{ this.$route.query.message }}</span>
            <h1></h1>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  components: {},
};
</script>
