<template>
  <div id="bg-dealer">
    <div :class="{ 'd-none': !showAutomaticBallsModal }">
      <AutomaticBalls :round="roundActive" />
    </div>
    <ScheduleRound v-if="showScheduleModal" />
    <CardVerify v-if="showCardVerify" />
    <notifications position="top center" width="100%" />

    <Head :round="roundActive"
          v-if="roundActive"></Head>
    
    <div class="container mt-0">
      <div class="row">
        <Chat v-if="roundActive"></Chat>
        <TimerNumber :round="roundActive"
                     v-if="roundActive"
                     @confirmNumberSelection="confirmNumberSelection"
                     ref="timer_number"></TimerNumber>
        
        <Winners :round="roundActive"
                 v-if="roundActive"
                 @liberateBall="liberateBall"
                 ref="winners"></Winners>
        
        <Numbers :round="roundActive"
                 v-if="roundActive"
                 @changeNumberSelected="changeNumberSelected"
                 @listWinners="listWinners"
                 ref="numbers"></Numbers>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "../components/dealer/Head";
import Chat from "../components/dealer/Chat";
import CardVerify from "../components/dealer/CardVerify";
import Winners from "../components/dealer/Winners";
import TimerNumber from "../components/dealer/TimerNumber";
import Numbers from "../components/dealer/Numbers";
import AutomaticBalls from "../components/dealer/AutomaticBalls";
import {mapState} from "vuex";
import ScheduleRound from '../components/dealer/ScheduleRound.vue';
import Socket from "@/services/Socket";

export default {
  name: "Dealer",
  components: {
    Head,
    Chat,
    Winners,
    TimerNumber,
    Numbers,
    AutomaticBalls,
    ScheduleRound,
    CardVerify
  },
  computed: mapState(["user"]),
  data() {
    return {
      socket: null,
      roundActive: null,
      showScheduleModal: false,
      showAutomaticBallsModal: false,
      showCardVerify: false,
      alreadyCreateNewRound: false,
      automatedPurchaseQueue: [],
      isProcessing: false,
      lastRoundAutomated: 0
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    
    // Cria uma partida
    this.socket.on("create-new-round", () => {
      this.createNewRound();
    });
    
    //Pega a partida ativa
    this.getRoundActive();
    this.setMyUser();

    // Verificar se existe outra sessão com o mesmo jogador
    this.socket.on("verify-open-session", (message) => {
      if (message.user_id == 1) {
        localStorage.removeItem("user");
        this.$router.push("login");
      }
    });

    this.socket.emit("verify-open-session", {
      room: "bingo",
      message: { user_id: 1 },
    });

    this.socket.on("dispatch-winners", (message) => {
      const string =
        message[
          message.length - 1
        ].premiacao_tipo.premiacao_tipo.toLowerCase();

        if((string === 'bingo' || string === 'acumulado') && this.roundActive > this.lastRoundAutomated) {
          this.lastRoundAutomated = this.roundActive;
          this.getAutomatedPurchases();
        }
    });

    setInterval(() => {
      this.$http.get("partida-ativa").then((res) => {
        if (res.data.id) {
          this.roundActive = res.data.id;
        }
      })
    }, 10000);

    setInterval(() => {
      if (this.roundActive) {
        this.socket.emit("set-partida-ativa", {
          room   : "bingo",
          message: {
            partida: this.roundActive,
          }
        });
      }
    }, 2000);
  },
  methods: {
    dispatchRoundActive() {
      this.socket.emit("partida", {
        room   : "bingo",
        message: {
          partida: this.roundActive,
          user   : {
            id  : this.user.id,
            tipo: this.user.tipo,
            name: this.user.name,
          }
        },
      });
    },
    //PEGA A PARTIDA ATIVA
    getRoundActive(attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to get active round after 5 attempts');
        return;
      }

      this.$http.get("partida-ativa").then((res) => {
        //Se já existir partida ativa, somente envia para os jogadores
        if (res.data.id) {
          this.roundActive = res.data.id;
          this.dispatchRoundActive();
        }
      })
          .catch((error) => {
            console.error(error);

            setTimeout(() => {
              this.getRoundActive(attemptCount + 1);
            }, 500);
          });
    },
    /**
     * Gera uma nova partida
     * */
    createNewRound(attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to create new round after 5 attempts');
        return;
      }

      if (!this.alreadyCreateNewRound) { // Verificar se uma rodada vou criada a pouco segundos antes
        this.alreadyCreateNewRound = true;
        this.$http.post("partida", {attempt_count: attemptCount})
            .then((res) => {
              if (!res.data || res.data.errors) {
                this.alreadyCreateNewRound = false;
                console.error(res.data.errors);

                setTimeout(() => {
                  this.createNewRound(attemptCount + 1);
                }, 1000);
                return;
              }
              
              if (res.data) {
                //Seta o id da nova pertida
                this.roundActive = res.data.id;
                //Chama o método para disparar a nova partida
                //this.getRoundActive();

                this.$notify({
                  title   : "<b>Mensagem de aviso!</b>",
                  text    : "Iniciando uma nova partida.",
                  type    : "success",
                  duration: 5000,
                });

                this.$helpers.setWinners([]);

                window.location.reload();
              }
            })
            .catch((error) => {
              this.alreadyCreateNewRound = false;
              console.error(error);

              setTimeout(() => {
                this.createNewRound(attemptCount + 1);
              }, 1000);
            });
      }

      setTimeout(() => {
        this.alreadyCreateNewRound = false; // Depois de 10 segundos pode criar outra rodada
      }, 10000);
    },
    setMyUser() {
      let user = localStorage.getItem("user");
      this.$store.commit("setUser", user);
    },

    toggleAutomaticBallsModal() {
      this.showAutomaticBallsModal = !this.showAutomaticBallsModal;
    },

    toggleScheduleRound() {
      this.showScheduleModal = !this.showScheduleModal;
    },

    toggleCardVerifyModal() {
      this.showCardVerify = !this.showCardVerify;
    },

    changeNumberSelected(number) {
      this.$refs.timer_number.changeNumberSelected(number);
    },

    confirmNumberSelection(number) {
      this.$refs.numbers.confirmNumberSelection(number);
    },

    listWinners() {
      this.$refs.winners.listWinners();
    },

    liberateBall() {
      this.$refs.numbers.liberateBall();
    },

    getAutomatedPurchases() {
      this.$http.get("compras-automatizadas").then((res) => {
        res.data.forEach(dt => {
          this.addToQueue({
            id                : dt.id,
            user_id           : dt.user_id,
            data              : dt.data,
            quantidade        : dt.quantidade,
            total             : dt.total,
            moeda             : dt.moeda,
            moeda_valor_total : dt.moeda_valor_total,
            request_fails     : dt.request_fails
          });
        });

        this.stopProcessing();
        this.startProcessing(500);

      }).catch((error) => {
        console.error(error);
      })
    },

    addToQueue(request) {
      this.automatedPurchaseQueue.push(request);
      this.saveQueue();
    },

    saveQueue() {
      localStorage.setItem('requestQueueAutomatedPurchase', JSON.stringify(this.automatedPurchaseQueue));
    },

    loadQueue() {
      const loadedQueue = localStorage.getItem('requestQueueAutomatedPurchase');
      return loadedQueue ? JSON.parse(loadedQueue) : [];
    },

    async processQueue() {
      if (this.isProcessing || this.automatedPurchaseQueue.length === 0) {
        return;
      }

      this.isProcessing = true;
      const request     = this.automatedPurchaseQueue[0];

      try {
        await this.automatedPurchase(request);
      } catch (err) {
        console.error('Error processing request:', err);
      }

      this.automatedPurchaseQueue.shift();
      this.saveQueue();
      this.isProcessing = false;
    },

    startProcessing(interval = 500) {
      if (this.processInterval) {
        return;
      }

      this.automatedPurchaseQueue = this.loadQueue();
      this.processInterval = setInterval(() => this.processQueue(), interval);
    },

    async automatedPurchase(data) {
      return await this.$http.post("compras-automatizadas/buy-cards", data);
    },

    stopProcessing() {
      if (this.processInterval) {
        clearInterval(this.processInterval);
        this.processInterval = null;
      }
    },
  },
};
</script>
