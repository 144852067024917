import Vue from 'vue';
import axios from 'axios';

// let urlString = window.location.href;
// let urlArray = urlString.split('://');

// if(urlArray[0] == 'https'){
//     axios.defaults.baseURL = 'https://api.bingo.copacabanagames.com/api/'
// } else {
//     axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
// }

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})