import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import BigNumber from "bignumber.js";

export default {
    convertMoney(value) {
        value        = Number(value);
        let currency = localStorage.getItem('currency');

        if (currency === 'BRL') {
            return value.toFixed(2);
        }

        let rate = Number(localStorage.getItem('brl_rate'));

        if (!rate) {
            return 0;
        }

        let bigValue          = new BigNumber(value);
        let bigConversionRate = new BigNumber(rate);

        return bigValue.dividedBy(bigConversionRate)
                       .multipliedBy(localStorage.getItem('currency_presentation_multiplier'))
                       .toFixed(parseInt(localStorage.getItem('currency_format_precision')), BigNumber.ROUND_HALF_DOWN);
    },
    convertAndFormatMoney(value) {
        value = this.convertMoney(value);
        
        let currency = localStorage.getItem('currency');

        if (currency === 'BRL') {
            let val = new BigNumber(value).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        let rate = Number(localStorage.getItem('brl_rate'));

        if (!rate) {
            return 0;
        }

        let bigValue = new BigNumber(value);

        return bigValue.toFixed(parseInt(localStorage.getItem('currency_format_precision')), BigNumber.ROUND_HALF_DOWN);
    },
    formatMoney(value) {
        value        = Number(value);
        let currency = localStorage.getItem('currency');

        if (currency === 'BRL') {
            let val = value.toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        let bigValue = new BigNumber(value).multipliedBy(localStorage.getItem('currency_presentation_multiplier'));

        return bigValue.toFixed(parseInt(localStorage.getItem('currency_format_precision')), BigNumber.ROUND_HALF_DOWN);
    },
    fixMoney(value) {
        value        = Number(value);
        let currency = localStorage.getItem('currency');

        if (currency === 'BRL') {
            return value.toFixed(2);
        }

        let bigValue = new BigNumber(value);

        return bigValue.toFixed(parseInt(localStorage.getItem('currency_format_precision')), BigNumber.ROUND_HALF_DOWN);
    },
    setActiveRound(value) {
        activeRoundUpdaterInstance.setActiveRound(value);
    },
    getActiveRound() {
        return activeRoundUpdaterInstance.getActiveRound();
    },
    setRoundRunning(value) {
        localStorage.setItem('roundRunning', value ? '1' : '0');
    },
    isRoundRunning() {
        return localStorage.getItem('roundRunning') === '1';
    },
    getLocaleParam() {
        return new URLSearchParams(window.location.search).get('locale') ?? 'BR';
    },
    setWinners(winners) {
        localStorage.setItem('winners', JSON.stringify(winners));
    },
    getWinners() {
        return JSON.parse(localStorage.getItem('winners'));
    },
}
