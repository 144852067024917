<template>
  <div class="ramdon-ball-generator text-center">
  <div v-if="currentBall !== 0 || randomBall !== 0"
    class="rounded-circle rng-area" >
    <img src="@/assets/images/white-ball.svg" class="w-100 h-100">
    <p>
      {{ currentBall !== 0 ? currentBall : randomBall }}
    </p>
  </div>
</div>

</template>

<script>
import { gsap } from "gsap";
import Socket from "@/services/Socket";
import SFX from "@/services/SFX";

export default {
  name: "RandomBallGenerator",
  data() {
    return {
      currentBall: 0,
      randomBall: 0,
      socket: null
    }
  },
  beforeMount() {
    this.socket = Socket.initialize();

    this.socket.on("give-back-ball", (data) => {
      let isLive = localStorage.getItem('is-rng-running') == 0 ? true : false;
      if (!isLive) {
        this.simulateAndShowBall(data.bola);
      }
    });

    this.socket.on("show-timer", () => {
      this.currentBall = 0;
      this.randomBall  = 0;
    });
  },
  mounted() {
    if(document.querySelector('.ramdon-ball-generator')) {
        gsap.from('.ramdon-ball-generator .rounded-circle', {
          scale: 0,
          duration: 1,
          ease: "back.out(1.7)",
        });
      }
  },
  methods: {
    simulateAndShowBall(bola) {
      // this.currentBall = bola;
      const maxUpdates = 15;
      const updateRate = 40;

      gsap.set('.ramdon-ball-generator .rounded-circle', { scale: 0 });
  
      gsap.to('.ramdon-ball-generator .rounded-circle', {
        scale: 1,
        duration: 0.5,
        ease: "back.out(1.7)",
        onStart: () => {
          this.currentBall = 0;
        },
        onComplete: () => {
          setTimeout(() => {
            this.currentBall = bola;
          }, 100);
        }
      });

      setTimeout(() => {
        SFX.playRng(bola);
        this.currentBall = bola;
      }, maxUpdates * updateRate);
      
      setTimeout(() => {
        let tempCount = 0;
        const intervalId = setInterval(() => {
          if (tempCount < maxUpdates) {
            this.randomBall = Math.floor(Math.random() * 60) + 1;
            tempCount++;
          } else {
            clearInterval(intervalId);
          }
        }, updateRate);
      }, 5);
    }
  }
}
</script>

<style scoped>
.ramdon-ball-generator {
  position: absolute; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 10;
}

.rounded-circle {
  min-width: 100px;
  font-size: 75px; 
  font-weight: bold; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
  position: relative;
}
.rounded-circle p {
  position: absolute;
  z-index: 10;
  width: 100%;
  color: black;
  text-align: center;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 360px) {
  .ramdon-ball-generator {
    height: 110px;
  }
}

@media (min-width: 1280px) {
  .rounded-circle {
    font-size: 120px;
  }
}
</style>