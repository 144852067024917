import Vue from "vue";
import App from "./App.vue";
import { store } from "./config/vuex";
import router from "./config/router";
import Notifications from "vue-notification";
import VueI18n from "vue-i18n";

import VueGtag from "vue-gtag";

Vue.use(Notifications);
Vue.use(VueI18n);

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./assets/stylesheet/global.css";
import "./assets/stylesheet/dealer/style.css";
import "./assets/stylesheet/player/desktop.css";
import "./assets/stylesheet/player/tablet.css";
import "./assets/stylesheet/player/mobile.css";
import "./assets/libraries/fontawesome/css/all.min.css";
import "./plugins/axios";

import "./assets/libraries/ant-media/ant-media.css";
import "./assets/libraries/ant-media/ant-media.js";

import messages from "./assets/translate/messages.json";
import datetimeFormats from "./assets/translate/datetimeFormats.json";

import { config } from 'dotenv'
config();

const i18n = new VueI18n({
  locale: 'pt-BR',
  messages,
  datetimeFormats,
});
Vue.prototype.$currency = '';

if (
    window.location.pathname === "/game"
    || window.location.host.includes("dev-")
    || window.location.host.includes("stag-")
    || window.location.host.includes("local")
) {
  Vue.use(VueGtag, {
    config: {
      id    : process.env.VUE_APP_G_TAG,
      params: {
        send_page_view: true,
        cookie_flags  : 'secure;sameSite=none'
      },
    },
  });
}

import helpers from "./assets/helpers/helper";
const plugins = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
Vue.use(plugins);

Vue.config.productionTip = false;

//region lazy load
const imageQueue = [];
let isLoading = false;

function loadNextImage() {
  if (isLoading || imageQueue.length === 0) return;

  isLoading = true;
  const el = imageQueue.shift(); // get the next element in the queue

  el.src = el.dataset.src;
  el.onload = () => {
    isLoading = false;
    loadNextImage(); // after the current image has loaded, start loading the next image
  };
}

Vue.directive('lazy-src', {
  bind(el, binding) {
    el.dataset.src = binding.value;
    el.src = ''; // or a placeholder

    imageQueue.push(el);

    Vue.nextTick(() => {
      if (!isLoading) {
        loadNextImage(); // start loading the images if none are currently being loaded
      }
    });
  }
});
//endregion

new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
}).$mount("#app");
