<template>
  <div class="container-flex">
    <h1 class="l-is-there-anyone-out-there">Is there anyone out there?</h1>
    <a @click="$router.go(-1)" class="l-keep-playing">Keep playing</a>
  </div>
</template>

<script>
export default {
  name: "Idle",
  components: {},
  mounted() {
    const language = {
      'BR': {
        'is-there-anyone': 'Tem alguém por aí?',
        'keep-playing': 'Continuar jogando'
      },
      'US': {
        'is-there-anyone': 'Is there anyone out there?',
        'keep-playing': 'Keep playing'
      },
      'ES': {
        'is-there-anyone': '¿Hay alguien ahí afuera?',
        'keep-playing': 'sigue jugando'
      }
    };
    const locale = new URLSearchParams(document.location.search).get('locale');
    if(locale === 'BR') {
      document.querySelector('.l-is-there-anyone-out-there').innerHTML = language[locale]['is-there-anyone'];
      document.querySelector('.l-keep-playing').innerHTML = language[locale]['keep-playing'];
    }
    if(locale === 'US') {
      document.querySelector('.l-is-there-anyone-out-there').innerHTML = language[locale]['is-there-anyone'];
      document.querySelector('.l-keep-playing').innerHTML = language[locale]['keep-playing'];
    }
    if(locale === 'ES') {
      document.querySelector('.l-is-there-anyone-out-there').innerHTML = language[locale]['is-there-anyone'];
      document.querySelector('.l-keep-playing').innerHTML = language[locale]['keep-playing'];
    } 
  }
};

</script>

<style scoped>
.container-flex {
  background: url('~@/assets/images/player/bg-splash-screen.webp') no-repeat center center #333;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  gap: 20px;
}

h1 {
  font-size: 4em;
  font-family: "Helvetica", Roboto, sans-serif;
  font-weight: bold;
}

a {
  font-size: 1.5em;
  font-family: "Helvetica", Roboto, sans-serif;
  font-weight: normal;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 25px;
  width: 300px;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  cursor: pointer;
}

@media screen and (max-width: 764px) {
  h1 {
    font-size: 2em;
  }
}
</style>