<template>
  <div class="row balls mt-2 mt-md-0">
    <div class="opacity-balls" v-if="time != '00:00:00' && time != '00:03:00'">
      <div class="ball-opacity"></div>
      {{ $t("Next round will start soon") }}
      <div></div>
    </div>
    <div class="col-sm-12 col-lg-3 d-lg-flex align-items-center justify-content-center mr-0 pr-0 d-none">
      <Ball v-for="(ball, index) in lastBallsMin" :key="index" :number="ball.bola" v-show="index == 0" />
    </div>
    <div class="col-sm-12 col-lg-9 p-1 p-md-2 d-none d-md-block">
      <b class="text-white h6">{{ $t("Drawn balls") }} <small class="qtd-drawn-balls">{{ balls.length + '/90'
      }}</small></b>
      <div class="little-balls mt-2">
        <div v-for="(number, index) in 90" :key="index" :class="{ 'selected': balls.includes(number) }"
          :id="'lastball-' + number">
          {{ number }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-9 p-1 p-md-2 d-block d-md-none">
      <b class="text-white h6">{{ $t("Drawn balls") }} <small class="qtd-drawn-balls">{{ balls.length + '/90'
      }}</small></b>

      <div class="resume-balls mt-2"
           @click="showLittleBalls = !showLittleBalls">
        <div v-for="(ball, index) in lastBallsMin"
             :key="index"
             :class="{ 'selected': index === 0 }">
          {{ ball.bola }}
        </div>
      </div>

      <div v-if="showLittleBalls"
           class="little-balls mt-2"
           @click="showLittleBalls = !showLittleBalls">
        <div v-for="(number, index) in 90"
             :key="index"
             :class="{ 'selected': balls.includes(number) }"
             :id="'lastball-' + number">
          {{ number }}
        </div>
      </div>

      <div class="text-center"
           @click="showLittleBalls = !showLittleBalls">
        <i class="fa fa-angle-down text-white"
           :class="{'fa-angle-up': showLittleBalls}"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Ball from "./Ball.vue";
import { mapState } from "vuex";
import jQuery from "jquery"
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";
import {EventBus} from "@/services/EventBus";

export default {
  name: "LastBalls",
  props: ["round"],
  components: {
    Ball,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      socket: null,
      lastBalls: [],
      lastBallsMin: [],
      roundActive: null,
      card: {
        user_id: null,
        partida_id: null,
        quantidade: 1,
      },
      time: "00:00:00",
      statusBet: true,
      balls: [],
      showLittleBalls: false,
    };
  },
  mounted() {
    this.socket = Socket.initialize();
    this.card.partida_id = this.$helpers.getActiveRound();
    this.getBalls(true);

    this.socket.on("status-bet", (message) => {
      if (message.user.tipo == "administrador") {
        this.statusBet = message.status;
        if (message.status == false) {
          this.lastBalls = [];
          this.lastBallsMin = [];
          this.balls = [];
        }
      }
    });

    this.socket.on("show-timer", (message) => {
      this.time = message.time;

      localStorage.setItem('bola-sorteadas', '[]');
    });

    this.socket.on("give-back-ball", (message) => {
      let isLive = localStorage.getItem('is-rng-running') == 0;
      setTimeout(() => {
        this.lastBalls.push(message);

        this.balls = message.lastBalls;
        this.balls.push(message.bola);

        this.lastBalls.sort((a, b) => {
          if (a.bola > b.bola) return 1;
          if (a.bola < b.bola) return -1;
          return 0;
        });

        this.lastBallsMin.unshift({
          bola: message.bola,
          status: 0,
        });

        if (this.lastBallsMin.length >= 7) {
          this.lastBallsMin.length = 7;
        }

        this.balls        = this.uniqueArray(this.balls);
        this.lastBallsMin = this.uniqueArray(this.lastBallsMin);

        const storageBolas = JSON.parse(localStorage.getItem('bola-sorteadas'));
        storageBolas.push(message.bola);
        localStorage.setItem('bola-sorteadas', JSON.stringify(storageBolas));

        if (this.balls.length == 1) {
          EventBus.$emit("roundStarted");
        }
        
        setTimeout(() => {
          jQuery('#lastball-' + message.bola).addClass('blinker');
        }, 300);
        
        setTimeout(() => {
          jQuery('#lastball-' + message.bola).removeClass('blinker');
        }, 2000);
      }, isLive ? 0 : 2200);
    });

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.card.partida_id = activeRound;
      this.roundActive = activeRound;
      this.lastBalls = [];
      this.lastBallsMin = [];
      this.balls = [];
      this.getBalls();
    });
  },
  methods: {
    //PEGA AS ULTIMAS BOLAS SORTEADAS
    getBalls: function () {
      if (this.card.partida_id && this.statusBet) {
        this.$http.get("bola-sorteada/" + this.card.partida_id).then((res) => {
          this.lastBalls = res.data.lastBallsSorted;

          const storageBolas = res.data.lastBallsSorted.map(item => item.bola);
          localStorage.setItem('bola-sorteadas', JSON.stringify(storageBolas));

          this.lastBallsMin = [];
          res.data.lastBalls.forEach((item, index) => {
            if (index < 7) {
              this.lastBallsMin.push(item);
            }

            this.balls.push(item.bola);
          });

          this.balls        = this.uniqueArray(this.balls);
          this.lastBallsMin = this.uniqueArray(this.lastBallsMin);
        });
      }
    },
    
    uniqueArray: function (arrArg) {
      return arrArg.filter(function (elem, pos, arr) {
        return arr.indexOf(elem) == pos;
      });
    },
  },
};
</script>
