<template>
  <div>
    <div class="last-winners text-white">
      <p class="p-2">
        <strong class="text-uppercase">{{ $t("Last winners") }}:</strong>
        <span v-for="(win, index) in winners" :key="index">
          <span class="font-italic" v-if="win.valor > 0">
            {{ win.user.name }} ({{ $t(win.premiacao_tipo.premiacao_tipo+' -') }} {{ $currency }} {{
              $helpers.convertAndFormatMoney(win.valor) }} )
          </span>
          <b class="font-bold"
             v-if="win.valor > 0">
            <span class="winner-ball">
              <img src="~@/assets/images/white-ball.svg"
                   class="img-fluid">
              <span>{{ win.bola }}</span>
            </span>
            {{ index + 1 != winners.length ? " / " : "" }}
          </b>
        </span>
      </p>
    </div>

    <div id="winners">
      <div class="box">
        <button class="btn-close-modal" @click="closeWinningsModal">&#x2715;</button>

        <div v-if="locale === 'pt-BR'">
          <img src="~@/assets/images/Linha-header-sm.webp"
               class="img-fluid mb-2"
               alt="linha"
               v-show="typePrize === 'linha'">
          <img src="~@/assets/images/Linha_dupla-header-sm.webp"
               class="img-fluid mb-2"
               alt="linha_dupla"
               v-show="typePrize === 'linha dupla'">
        </div>

        <div v-if="locale === 'en-US'">
          <img src="~@/assets/images/Line-header-sm.webp"
               class="img-fluid mb-2"
               alt="linha"
               v-show="typePrize === 'linha'">
          <img src="~@/assets/images/Double_line-header-sm.webp"
               class="img-fluid mb-2"
               alt="linha_dupla"
               v-show="typePrize === 'linha dupla'">
        </div>

        <img src="~@/assets/images/Bingo-header-sm.webp"
             class="img-fluid mb-2"
             alt="bingo"
             v-show="typePrize === 'bingo' || typePrize === 'acumulado'">

        <div class="d-flex justify-content-between text-white mx-2 h5 font-weight-bold">
          <span>{{ $t("Winners") }}</span>
          <div style="color:#FFBF40">
            <span id="winner-valor">{{ $currency }} {{ $helpers.convertAndFormatMoney(valorWinner) }}</span>
          </div>
        </div>
        <span v-if="valorAcumulado > 0" id="acumulado-valor">{{ $t("Accumulated") }}: {{ $currency }} {{
          $helpers.convertAndFormatMoney(valorAcumulado) }}</span>

        <div v-for="(card, index) in cards" :key="index" class="my-2 winner-box">
          <div class="text-white text-left mx-2">
            <b>{{ $t("User") }}: </b><span>{{ card.user.name }}</span>
            <div class="winner-ball ml-2">
              <img src="~@/assets/images/white-ball.svg"
                   class="img-fluid">
              <span>{{ card.premiacao_jogador[0].bola }}</span>
            </div>
          </div>
          <div class="cards mt-2">
            <Card :key="index"
                  :numbers="card.bola_cartelas"
                  v-if="card.bola_cartelas"
                  :highlight="arrayHighlight"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import jQuery from "jquery";
import Card from "./Card.vue";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";
import {EventBus} from "@/services/EventBus";
import SFX from "@/services/SFX";

export default {
  name: "LastWinners",
  components: {
    Card
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      socket: null,
      winners: [],
      alertWinners: [],
      cards: [],
      valorWinner: 0,
      valorAcumulado: 0,
      arrayHighlight: [],
      typePrize: null,
      locale: 'pt-BR',
      partida_id: null
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    window.SFX  = SFX;
  },
  mounted() {
    this.getLastWinners(false);
    
    this.socket.on("dispatch-winners", (message) => {
      setTimeout(() => {
        this.setLocale();
        this.winners = message;

        if (this.winners.length > this.alertWinners.length) {
          let string = message[message.length - 1].premiacao_tipo.premiacao_tipo.toLowerCase();
          // Exibir a imagem do tipo de premiação
          this.showModal(string, message);
        }
        this.alertWinners = this.winners;
      }, localStorage.getItem('is-rng-running') == 0 ? 0 : 3000);
    });

    this.socket.on("update-balance", (message) => {
      if (message.user.id === this.user.id) {
        this.user.balance = message.user.balance;
      }
    });

    this.socket.on("list-winners", () => {
      this.getLastWinners(false);
    });

    this.setLocale();

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.partida_id = activeRound;
      this.winners = [];
      this.alertWinners = [];
      this.typePrize = null;
    });
  },
  methods: {
    getLastWinners: function (haveModal = false) {
      this.$http.get("ganhadores").then((res) => {
        this.winners = res.data;

        if (this.winners.length > this.alertWinners.length) {
          let string =
            res.data[
              res.data.length - 1
            ].premiacao_tipo.premiacao_tipo.toLowerCase();
          /**
           * Exibir a imagem do tipo de premiação
           * */
          if (haveModal) {
            this.showModal(string, res.data);
          }

          if (string == "bingo") {
            this.socket.emit("show-cards", {
              room: "bingo",
              message: { status: false },
            });

            this.socket.emit("status-bet", {
              room   : "bingo",
              message: {
                user    : {
                  id  : this.user.id,
                  tipo: this.user.tipo,
                },
                status: false
              },
            });
          }
        }

        this.alertWinners = this.winners;
      });
    },

    showModal(string, message) {
      this.showModalWinners(string, message);
    },

    showModalWinners(string, message) {
      let newArray = [];
      this.valorAcumulado = 0;
      this.typePrize = string;
      if (string == "linha") {
        newArray = message.filter(function (el) {
          return el.premiacao_tipo_id == 1;
        });

        this.valorWinner = newArray[0].valor;
        SFX.playWonLine();
        setTimeout(() => {
          SFX.playRng('linha');
        }, 1500);
      }

      if (string == "linha dupla") {
        newArray = message.filter(function (el) {
          return el.premiacao_tipo_id == 2;
        });

        this.valorWinner = newArray[0].valor;
        SFX.playWonLine();
        setTimeout(() => {
          SFX.playRng('linha_dupla');
        }, 1500);
      }

      if (string == "bingo") {
        newArray = message.filter(function (el) {
          return el.premiacao_tipo_id == 3;
        });

        this.valorWinner = newArray[0].valor;
        SFX.playBingo();
        setTimeout(() => {
          SFX.playRng('bingo');
        }, 3500);
      }

      if (string == "acumulado") {
        newArray = message.filter(function (el) {
          return el.premiacao_tipo_id == 3;
        });

        this.valorWinner = newArray[0].valor;

        // Premio acumulado
        let newArrayAcumulado = [];
        newArrayAcumulado = message.filter(function (el) {
          return el.premiacao_tipo_id == 4;
        });

        if (newArrayAcumulado.length > 0) {
          this.valorAcumulado = newArrayAcumulado[0].valor;
        }
      }
      
      if (['bingo', 'acumulado'].indexOf(string) > -1) {
        EventBus.$emit("roundEnded");
      }

      this.cards = newArray.map(item => item.card);

      // This is needed because the delay of the Vue binding render
      setTimeout(function () {
        jQuery("#winners .winner-box").fadeIn();
      }, 100);

      jQuery("#winners, #winners .box").fadeIn();

      setTimeout(() => {
        jQuery("#winners, #winners .box, #winners .winner-box").fadeOut();
      }, 9000);
    },

    closeWinningsModal() {
      jQuery("#winners, #winners .box, #winners .winner-box").fadeOut();
    },

    setLocale() {
      this.locale = this.$i18n.locale;
    },
  },
};
</script>
