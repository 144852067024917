export class Subject {
    constructor() {
        this.observers = [];
    }

    registerObserver(callback) {
        if (typeof callback === 'function') {
            this.observers.push(callback);
        } else {
            throw new Error("Registered observer must be a function!");
        }
    }

    unregisterObserver(callback) {
        const callbackIndex = this.observers.indexOf(callback);
        if (callbackIndex > -1) {
            this.observers.splice(callbackIndex, 1);
        }
    }

    notifyObservers(data) {
        for (let observer of this.observers) {
            observer(data);
        }
    }
}