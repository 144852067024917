<template>
  <div class="col-12 col-lg-3 pr-lg-1 box px-0">
    <div class="head-box bg-grey text-center pt-3 pb-3">
      <span>CHAT</span>
    </div>

    <div class="container-box bg-black">
      <div class="container-messages">
        <div v-for="(message, index) in messages" :key="index" class="message text-right cursor-pointer"
          :class="{ blinker: blinker && index === 0 }"
          @click="usersChat(message.user_from.id, message.user_from.name, message.user_from.phone)">
          <p class="text-white" :class="{ 'big-emoji': isSingleEmoji(message.message) }">{{ message.message }}</p>
          <p class="text-white">
            <span class="text-green mr-2">{{ message.user_from.name }}</span>
            <span>{{ message.created_at | moment }}</span>
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3 px-2">
      </div>
    </div>
    <div class="modal-schedule-round" v-show="showModalChat">
      <div class="d-flex justify-content-between">
        <h4>Jogadores no Chat</h4>
        <button type="button" @click="close">
          <h3>&#x2715;</h3>
        </button>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <p>
          Deseja <span v-if="!userBlocked">bloquear</span> <span v-else>desbloquear</span> o {{ this.name }}?
        </p>
        <div v-if="!userBlocked">
          <button class="btn btn-sm btn-danger" @click="blockUser">Bloquear</button>
        </div>
        <div v-else>
          <button class="btn btn-sm btn-success" @click="unlockUser(id)">Desbloquear</button>
        </div>
      </div>

      <div class="mt-4">
        <h5>
          Jogadores bloqueados
        </h5>
        <table class="table text-center">
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Operador</th>
            <th>Bloqueado em</th>
            <th>Ação</th>
          </tr>
          <tr v-for="(user, index) in blockedUsers" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.site_name }}</td>
            <td>{{ user.phone }}</td>
            <td>
              <button class="btn btn-sm btn-success" @click="unlockUser(user.id)">Desbloquear</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Socket from "@/services/Socket";

export default {
  name: 'Chat',
  data() {
    return {
      socket: null,
      messages: [],
      blinker: false,
      showModalChat: false,
      name: '',
      id: '',
      userBlocked: false,
      blockedUsers: []
    };
  },
  computed: {
    ...mapState(['user']),
  },
  filters: {
    moment: function (date) {
      moment.locale('pt-br');
      return moment(date).format('LT');
    },
  },
  mounted() {
    this.socket = Socket.initialize();
    
    this.getMessages();

    this.socket.on('message', (event) => {
      if (event.message) { // old version
        this.getMessages();
      } else {
        this.messages = event.messages;
        this.addMessageBlinker();
      }
    });
  },
  methods: {
    moment: function () {
      return moment();
    },

    isSingleEmoji(text) {
      const emojiRegex = /^[\p{Emoji}\u200d]{1,2}$/u;
      return emojiRegex.test(String(text).trim());
    },

    getMessages() {
      this.$http
        .get('get-messages')
        .then((res) => {
          this.messages = res.data;
          this.addMessageBlinker();
        })
        .catch((error) => {
          console.error(error);
          
          setTimeout(() => {
            this.getMessages();
          }, 250);
        });
    },

    addMessageBlinker() {
      this.blinker = true;
      setTimeout(() => {
        this.blinker = false;
      }, 4000);
    },

    usersChat(id, name, blocked) {
      this.getBlockedUsers();
      this.name = name;
      this.id = id;
      this.userBlocked = !!blocked;
      this.showModalChat = !this.showModalChat;
    },

    getBlockedUsers() {
      this.$http
        .get('blocked-users')
        .then((res) => {
          this.blockedUsers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    blockUser() {
      const data = {
        user_id: this.id,
      };

      this.$http
        .post('block-user', data)
        .then(() => {
          this.getBlockedUsers();
          this.getMessages();
          this.userBlocked = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    unlockUser(id) {
      const data = {
        user_id: id,
      };

      this.$http
        .post('unlock-user', data)
        .then(() => {
          this.getBlockedUsers();
          this.getMessages();
          if (this.id == id) {
            this.userBlocked = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    close() {
      this.showModalChat = !this.showModalChat;
    }
  },
};
</script>
