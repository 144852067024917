<template>
  <div class="col-12 col-lg-6 box px-0 ml-lg-0">
    <div class="head-box bg-green text-center pt-3 pb-3">
      <span>
        <span v-if="!statusBet">Venda de cartelas disponíveis</span>
        <span v-if="statusBet">Bola Selecionada</span>
      </span>
    </div>

    <div class="container-box bg-green2">
      <div id="sale-cards" v-if="statusBet != true">
        <div class="d-flex justify-content-center next-round text-white pt-3 pb-2">
          Próxima Rodada
        </div>
        <div class="d-flex justify-content-center timer-next-round mt-2 timer-editor">
          <div class="d-flex justify-content-center align-items-center flex-column pr-3">
            <input type="text"
                   :readonly="!pauseCounter"
                   v-mask="'##:##:##'"
                   id="time"
                   class="text-white mr-3"
                   v-model="time"/>

            <div class="d-flex justify-content-center text-white pb-2 pt-2">
              <a href="javascript:;"
                 @click="setTimerTo('00:05:00')"
                 id="set-timer-to-5-min"
                 class="btn btn-sm btn-warning btn-lg mx-1">
                5min
              </a>
              <a href="javascript:;"
                 @click="setTimerTo('00:02:00')"
                 id="set-timer-to-2-min"
                 class="btn btn-sm btn-warning btn-lg mx-1">
                2min
              </a>
              <a href="javascript:;"
                 @click="setTimerTo('00:01:00')"
                 id="set-timer-to-1-min"
                 class="btn btn-sm btn-warning btn-lg mx-1">
                1min
              </a>
              <a href="javascript:;"
                 @click="setTimerTo('00:00:30')"
                 id="set-timer-to-30-s"
                 class="btn btn-sm btn-warning btn-lg mx-1">
                30s
              </a>
              <a href="javascript:;"
                 @click="setTimerTo('00:00:15')"
                 id="set-timer-to-15-s"
                 class="btn btn-sm btn-warning btn-lg mx-1">
                15s
              </a>
            </div>
          </div>
          <div class="pb-4">
            <a href="javascript:;"
               @click="pauseOrPlay">
              <i :class="{ 'text-danger': pauseCounter }"
                 class="fas fa-power-off power-button button-on"></i>
            </a>
          </div>
        </div>
      </div>

      <div id="selected-ball" v-if="statusBet">
        <div class="
            d-flex
            justify-content-center
            align-items-center
            next-round
            text-white
            pt-4
            pb-2
          ">
          <div id="roundBall" class="d-flex align-items-center pt-5">
            <span class="mr-3 sended" :class="{ 'ball-sended': sended > 0 }" v-if="numberSelected" id="number-selected">{{
              numberSelected }}</span>
            <button class="btn-confirm sended" :class="{ 'ball-sended': sended > 0 }" @click="saveBall" id="saveBall"
              :disabled="!numberSelected">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Avançar Rodada</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja avançar?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              Não
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="goRound()" id="go-round">
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Socket from "@/services/Socket";
import moment from "moment";
import { mask } from "vue-the-mask";
import jQuery from "jquery";
import {totalCardsNextRoundUpdaterInstance} from "@/observer/TotalCardsNextRoundUpdater";

export default {
  name: "TimeNumber",
  props: ["round"],
  directives: { mask },
  data() {
    return {
      socket: null,
      time: "00:03:00",
      lastBalls: [],
      roundActive: null,
      numberSelected: null,
      sended: 0,
      pauseCounter: true,
      timeout: null,
      statusBet: true,
      loading: true,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.socket.on("partida", (partida) => {
      this.roundActive = partida.partida;
      if (partida.user.tipo == "administrador" && partida.user.name == "admin") {
        this.numberSelected = null;
      }
    });
  },
  mounted() {
    // Execute a function when the user releases a key on the keyboard
    window.addEventListener("keydown", (event) => {
      // Number 13 is the "Enter" key on the keyboard
      if (event.key === "Enter" || event.key == null) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        if (document.querySelector('.modal-rollback') === null) {
          document.getElementById("saveBall").click();
          this.saveBall();
        }
      }
    });

    //Exibe e esconde o timer
    this.socket.on("status-bet", (status) => {
      if (status.user.id == this.user.id
          && status.status == false
      ) {
        // Caso selecione Avançar a partida pelo menu, aparecer o modal imediatamente
        // Caso for Bingo, aparecer depois de 5 segundos
        if (status?.menu_selection) {
          jQuery(".modal").modal("show");
        } else {
          setTimeout(() => {
            jQuery(".modal").modal("show");
          }, 5000);
        }
      }
    });

    this.socket.emit("status-bet", {
      room   : "bingo",
      message: {
        user  : {
          id  : this.user.id,
          tipo: this.user.tipo,
        },
        status: this.statusBet
      },
    });

    totalCardsNextRoundUpdaterInstance.registerObserver(() => {
      this.updateRoomInfo();
    });

    this.roundActive = this.round;
    this.getBalls();
  },
  methods: {
    goRound() {
      this.$notify({
        title: "<b>Alerta!</b>",
        text: "Fim desta rodada.",
        type: "warn",
        duration: 5000,
      });

      this.statusBet = false;
      //Faz com que o contador se inicie automaticamente
      this.pauseCounter = false;
      this.initCount();
    },
    initCount() {
      let initial = this.time;
      initial = this.convertStringToMs(initial);
      
      this.updateRoomInfo(this.time);

      this.timeout = setInterval(() => {
        initial = initial - 1000;

        let duration = moment.duration(initial, 'milliseconds');
        let time = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();
        this.time = time.length < 8 ? moment(time, "HH:m:s").format("HH:mm:ss") : time;

        let miliseconds = this.convertStringToMs(this.time);

        //Fim da contagem, zera tudo e muda a partida
        if (miliseconds <= 0) {
          this.updateRoomInfo("00:08:00");
          //Cria uma nova partida
          this.socket.emit("create-new-round", { room: "bingo" });
          //Libera  apostas
          this.statusBet = true;
          //Limpa o último número selecionado
          this.numberSelected = null;
          window.clearTimeout(this.timeout);
          //Reseta o time
          this.time = "00:03:00";
        }

        //Envia mensagem com o timer para os jogadores
        this.socket.emit("show-timer", {
          room: "bingo",
          message: { time: this.time },
        });

        //Envia status de possibilidade de aposta
        this.socket.emit("status-bet", {
          room   : "bingo",
          message: {
            user  : {
              id  : this.user.id,
              tipo: this.user.tipo,
            },
            status: false
          },
        });
      }, 1000);
    },
    convertStringToMs(time) {
      let string = time;
      let arrayString = string.split(":");

      let hours = parseInt(arrayString[0]);
      let minutes = parseInt(arrayString[1]);
      let seconds = parseInt(arrayString[2]);

      seconds = seconds * 1000;
      minutes = minutes * 60 * 1000;
      hours = hours * 60 * 60 * 1000;

      return hours + minutes + seconds;
    },
    msToTime(s) {
      function pad(n, z) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      var ms = s % 1000;
      s = (s - ms) / 1000;
      var secs = s % 60;
      s = (s - secs) / 60;
      var mins = s % 60;

      return pad(mins) + ":" + pad(secs);
    },
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);

      return (
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    pauseOrPlay() {
      if (this.pauseCounter) {
        this.initCount();
        this.pauseCounter = null;
      } else {
        window.clearTimeout(this.timeout);
        this.pauseCounter = true;
      }
    },
    convertTime() {
      let date = moment().format("Y-M-D");
      let time = moment(date + " 00:00:00")
        .add(moment.duration(this.time, "seconds"))
        .format("HH:mm:ss");

      this.time = time;

      return time;
    },
    saveBall() {
      if (this.sended == 0 || this.sended === undefined) {
        let ball = {
          bola: this.numberSelected,
          partida_id: this.roundActive,
          status: 0,
        };

        this.sended = 1;
        this.$emit("confirmNumberSelection", ball);
      }
    },
    //PEGA AS ULTIMAS BOLAS SORTEADAS
    getBalls: function () {
      if (this.roundActive) {
        this.$http.get("bola-sorteada/" + this.roundActive).then((res) => {
          this.lastBalls = res.data;
        });
      }
    },
    changeNumberSelected(number) {
      this.numberSelected = number;
      this.sended = 0;
    },
    setTimerTo(time) {
      window.clearTimeout(this.timeout);
      this.pauseCounter = true;
      this.time = time;
      this.initCount();
      this.pauseCounter = null;
    },
    updateRoomInfo(time = null, attemptCount = 0) {
      if (attemptCount >= 5) {
        console.error('Failed to get update room info after 10 attempts');
        return;
      }

      this.$http.post("rooms", {
        id           : 1,
        room_uuid    : '7283dcaa-5182-11ee-be56-0242ac120002',
        room_name    : 'Bingo Everest',
        start_timeout: time || this.time,
      })
          .catch(() => {
            setTimeout(() => {
              this.updateRoomInfo(time, attemptCount + 1);
            }, 1000);
          });
    }
  },
};
</script>
