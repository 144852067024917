<template>
  <div class="d-flex">
    <img v-lazy-src="require('@/assets/images/logo-bingo.webp')"
         class="img-fluid logo-bingo mx-1 d-md-none"
         alt="Bingo Everest">
    <div class="header w-100">
      <div class="d-flex flex-column username cursor-pointer justify-content-center"
        @click="$refs.modalSettings.toggleModal()">
        <span>{{ $t("User") }}: </span>
        <span>
          <i class="far fa-edit float-left d-block d-md-none">&ensp;</i>
          {{ user.name }}
      </span>
      </div>
      <div class="d-flex flex-column balance">
        <span>{{ $t("Balance") }}: </span>
        <span class="text-nowrap">{{ $currency }} {{ $helpers.formatMoney(user.balance) }}</span>
      </div>
      <div class="text-center menu">
        <button type="button"
                id="toggle-video-volume"
                class="
            mr-1
            d-flex
            justify-content-center
            align-items-center
            rounded-circle
          " @click="toggleVolume">
          <span v-if="volume">
            <i class="fas fa-volume-up md:text-2xl"></i>
          </span>
          <span v-else>
            <i class="fas fa-volume-mute md:text-2xl"></i>
          </span>
        </button>
        <button @click="showMenu = !showMenu" class="modalHistoric"><i class="fa fa-bars"></i></button>
        <div v-show="showMenu" class="menu-options mt-md-3">
          <p @click="$refs.modalTournament.toggleModal(), showMenu = false"
             v-if="isTournamentAvailable"
             class="cursor-pointer">{{ $t("Tournament") }}</p>
          <hr style="margin: 10px 0px; background: #fff;"
              v-if="isTournamentAvailable">
          <!--<p @click="$refs.modalSchedule.toggleModal(), showMenu = false"
             class="cursor-pointer">{{ $t("Schedule") }}</p>
          <hr style="margin: 10px 0px; background: #fff;">-->
          <p @click="$refs.modalHistoric.toggleModal(), showMenu = false" class="cursor-pointer">{{ $t("Historic") }}</p>
          <hr style="margin: 10px 0px; background: #fff;">
          <p>{{ $t("Language") }}</p>
          <select name="game_locale"
                  @change="setLocale($event)"
                  class="cursor-pointer">
            <option value="pt-BR">PT</option>
            <option value="en-US">EN</option>
          </select>
          <div style="display:none">
            <a href="javascript:void(0)"
               @click="changeLocaleTo('pt-BR')"
               id="change-language-to-pt-br"></a>
            <a href="javascript:void(0)"
               @click="changeLocaleTo('en-US')"
               id="change-language-to-en-us"></a>
          </div>
          <hr style="margin: 10px 0px; background: #fff;">
          <p @click="toggleRules" class="cursor-pointer">{{ $t("Rules") }}</p>
          <!--<hr style="margin: 10px 0px; background: #fff;">
          <div class="custom-control custom-switch" @change="toggleSoundEffects($event.target.checked)">
            <input type="checkbox" class="custom-control-input" id="sound-effects" @input="event => text = event.target.value" v-model="soundEffect">
            <label class="custom-control-label" for="sound-effects">{{ $t("Sound effects") }}</label>
          </div>-->
        </div>
      </div>
    </div>

    <Modal ref="modalSettings" class="modal-settings">
      <template v-slot:header>
        <h3>{{ $t("User name") }}</h3>
      </template>

      <template v-slot:body>
        <div class="d-flex flex-column flex-md-row align-items-center">

          <input type="text"
                 class="form-control input-lg"
                 name="username"
                 minlength="3"
                 maxlength="20"
                 v-model="username"/>
          <button @click="updateUsername" class="btn btn-lg btn-username-confirm">
            {{ $t("Confirm") }}
          </button>
        </div>
      </template>

      <template v-slot:footer>
        <div>

        </div>
      </template>
    </Modal>

    <Modal ref="modalTournament"
           class="modal-default modal-default">
      <template v-slot:body>
        <img v-lazy-src="require('@/assets/images/torneio-bingo-ao-vivo-2024-03.png')"
             class="img-fluid"
             alt="Torneio Bingo Ao Vivo">
      </template>

      <template v-slot:footer></template>
    </Modal>

    <!--<Modal ref="modalSchedule"
           class="modal-default modal-schedule">
      <template v-slot:body>
        <img v-lazy-src="require('@/assets/images/bingo-schedule-2024-07.webp')"
             v-if="new Date() < new Date(2024, 2, 31)"
             class="img-fluid"
             alt="Agenda Bingo Copacabana">
      </template>

      <template v-slot:footer></template>
    </Modal>-->

    <Modal ref="modalHistoric" class="modal-historic">
      <template v-slot:header>
        <h4>{{ $t("Historic") }}</h4>
      </template>

      <template v-slot:body>
        <div>
          <button class="btn mr-2" :class="{ 'active': showHistoricGeral }" @click="changeShowHistoric(1)">{{ $t("Rounds")
          }}</button>
          <button class="btn mr-2" :class="{ 'active': showHistoricCompras }" @click="changeShowHistoric(2)">{{
            $t("Purchasing") }}</button>
          <button class="btn" :class="{ 'active': showHistoricGanhos }" @click="changeShowHistoric(3)">{{ $t("Earnings")
          }}</button>
        </div>
        <div>

          <div v-if="showHistoricGeral">
            <h5 class="mt-4">{{ $t("Last 10 rounds") }}</h5>
            <div v-for="(round, index) in lastestRounds" :key="index">
              <div class="mb-3">
                <p class="font-weight-bold h6">{{ $t("Round") }}: {{ round.id }}</p>
                <table class="table text-white text-center">
                  <tr>
                    <th>{{ $t("Award") }}</th>
                    <th>{{ $t("User") }}</th>
                    <th>{{ $t("Card") }}</th>
                    <th>{{ $t("Value") }}</th>
                  </tr>
                  <tr v-for="(award, index) in round.premiacao_jogadores" :key="index"
                    v-show="award.moeda_valor_total > 0">
                    <td>{{ award.premiacao_tipo.premiacao_tipo }}</td>
                    <td>{{ award.user.name }}</td>
                    <td>{{ award.cartela_id }}</td>
                    <td>{{ award.moeda }} {{ award.moeda_valor_total }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div v-if="showHistoricCompras">
            <div v-show="userScheduledPurchases && userScheduledPurchases.length > 0">
              <h5 class="mt-4">{{ $t("Your purchases for scheduled round") }}</h5>
              <table class="table text-white text-center">
                <tr>
                  <th>{{ $t("Date and time") }}</th>
                  <th>{{ $t("Purchased cards") }}</th>
                  <th>{{ $t("Card value") }}</th>
                  <th>{{ $t("Amount debited") }}</th>
                  <th>{{ $t("Date") }}</th>
                </tr>
                <tr v-for="(scheduled, index) in userScheduledPurchases" :key="index">
                  <td>{{ $d(new Date(scheduled.data_hora), 'long') }}</td>
                  <td>{{ scheduled.quantidade }}</td>
                  <td>{{ $currency }} {{ $helpers.formatMoney(scheduled.moeda_valor_total / scheduled.quantidade) ||
                    (scheduled.total / scheduled.quantidade) }}</td>
                  <td>{{ $currency }} {{ $helpers.formatMoney(scheduled.moeda_valor_total) || scheduled.total }}</td>
                  <td>{{ $d(new Date(scheduled.created_at), 'long') }}</td>
                </tr>
              </table>
            </div>

            <div class="d-flex justify-content-between my-2">
              <h5>{{ $t("Your purchases") }}</h5> <button v-show="quantityCardsNextRound > 0" class="btn btn-danger" @click="$refs.modalCancelPurchase.toggleModal(), $refs.modalHistoric.closeModal();">{{ $t("Cancel last purchase") }}</button>
            </div>
            <table class="table text-white text-center">
              <tr>
                <th>{{ $t("Round") }}</th>
                <th>{{ $t("Purchased cards") }}</th>
                <th>{{ $t("Card value") }}</th>
                <th>{{ $t("Amount debited") }}</th>
                <th>{{ $t("Date") }}</th>
              </tr>
              <tr v-for="(purchase, index) in userPurchases" :key="index">
                <td>{{ purchase.partida_id }}</td>
                <td>{{ purchase.quantidade }}</td>
                <td>
                  {{ $currency }} 
                  {{ $helpers.formatMoney(purchase.moeda_valor_total / purchase.quantidade) || purchase.valor_cartela }}
                </td>
                <td>
                  {{ $currency }} 
                  {{ $helpers.formatMoney(purchase.moeda_valor_total) || (purchase.valor_cartela * purchase.quantidade) }}
                </td>
                <td>{{ $d(new Date(purchase.created_at), 'long') }}</td>
              </tr>
            </table>
          </div>

          <div v-if="showHistoricGanhos">
            <h5 class="mt-4">{{ $t("Your earnings") }}</h5>
            <table class="table text-white text-center">
              <tr>
                <th>{{ $t("Round") }}</th>
                <th>{{ $t("Award") }}</th>
                <th>{{ $t("Card") }}</th>
                <th>{{ $t("Earned value") }}</th>
                <th>{{ $t("Date") }}</th>
              </tr>
              <tr v-for="(earnings, index) in userEarnings" :key="index">
                <td>{{ earnings.partida_id }}</td>
                <td>{{ earnings.premiacao_tipo }}</td>
                <td>{{ earnings.cartela_id }}</td>
                <td>{{ $currency }} {{ $helpers.formatMoney(earnings.moeda_valor_total) || earnings.valor }}</td>
                <td>{{ $d(new Date(earnings.created_at), 'long') }}</td>
              </tr>
            </table>
          </div>
        </div>
      </template>

      <template v-slot:footer></template>
    </Modal>

    <Modal ref="modalCancelPurchase" class="modal-cancel-purchase">
      <template v-slot:header><h4 class="text-center">{{ $t("Cancel last purchase") }}</h4></template>
      <template v-slot:body>
        <p>{{ $t("You are about to cancel your purchase of cards for the next round.") }}</p>
        <p>{{ $t("Caution!") }}</p>
        <ul class="px-3 py-2">
          <li>{{ $t("Purchased cards will be invalidated.") }}</li>
          <li>{{ $t("You will not be eligible for this round's prize pool with these cards.") }}</li>
        </ul>
        <p>{{ $t("Amount to be credited to your wallet:") }}</p>
        <p>R$ {{ cardPrice * quantityCardsNextRound }}</p>
        <p class="mt-2">{{ $t("Are you sure you want to cancel this purchase?") }}</p>
        <div class="d-flex justify-content-around mt-3">
          <button class="btn btn-success d-flex align-items-center" @click="cancelPurchase" :disabled='loadingCancelPurchase'>
            <div class="spinner-border text-light spinner-border-sm mr-2" role="status" v-show="loadingCancelPurchase">
            </div>
            {{ $t("Yes") }}
          </button>
          <button class="btn btn-danger" @click="$refs.modalCancelPurchase.closeModal()">{{ $t("No") }}</button>
        </div>
      </template>
      <template v-slot:footer></template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import { mapState } from "vuex";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Notification from "@/services/Notification";
import {EventBus} from "@/services/EventBus";
import SFX from "@/services/SFX";

export default {
  name: "Header",
  components: {
    Modal
  },
  props: ["round"],
  data() {
    return {
      username: "",
      volume: false,
      roundActive: null,
      lastestRounds: null,
      userPurchases: null,
      userScheduledPurchases: null,
      userEarnings: null,
      loadingCancelPurchase: false,
      cardPrice: 0,
      showCancelPurchaseButton: false,
      showHistoricGeral: true,
      showHistoricCompras: false,
      showHistoricGanhos: false,
      showMenu: false,
      soundEffect: true,
      currency: null,
      isTournamentAvailable: false
    };
  },
  computed: {
    ...mapState(["user", "session"]),
  },
  mounted() {
    this.currency = this.session?.currency;
    
    setTimeout(() => {
      this.checkBalance();
    }, 5000);
    
    this.username = this.user.name;
    this.roundActive = this.$helpers.getActiveRound();
    
    try {
      this.$gtag.set('user_id', this.user.id);

      this.$gtag.event('set_operador', {
        'event_category' : 'Operador',
        'event_label' : this.user.site.name,
        'non_interaction': true,
        'custom_map': {
          'dimensionINDEX': 'Operador'
        },
        'Operador': this.user.site.name
      });
    } catch (e) {
      console.log('GA error', e);
    }

    this.getLatestRounds();

    let buttonHistory = document.querySelectorAll('.modalHistoric');
    buttonHistory.forEach(element => {
      element.addEventListener("click", this.updateData);
    });

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.roundActive = activeRound;
      this.getBalance();
    });

    EventBus.$on("getBalance", () => {
      this.getBalance();
    });
    
    this.isTournamentAvailable = this.user.site.casino_id == 75 && new Date() < new Date(2024, 3, 14);

    if (this.isTournamentAvailable) {
      this.$refs.modalTournament.toggleModal();
    } else {
      //this.$refs.modalSchedule.toggleModal();
    }

    if (localStorage.getItem('locale') !== 'BR') this.changeLocaleTo('en-US');
  },
  methods: {
    updateUsername() {
      if (this.username.match(/adm/i) || this.username.match(/copa/i)) {
        Notification.error(
            'Error',
            'Prohibited the use of names that contain copa or adm'
        );
        this.username = this.user.name;
        return;
      }
      
      if (this.username.length < 3 || this.username.length > 20) {
        Notification.error(
            'Error',
            'The username must be between 3 and 20 characters'
        );
        this.username = this.user.name;
        return;
      }

      this.$http
          .patch(
              "user/update-username",
              {
                name: this.username,
              }
          )
          .then((res) => {
            if (res.data.errors) {
              Notification.error(
                  'Error',
                  'An error occurred while updating your username, please try again later'
              );
            } else {
              this.user.name = res.data.name;
            }

            this.$refs.modalSettings.closeModal();
          });
    },
    
    checkBalance() {
      let cardValue = localStorage.getItem('card-value');
      if (cardValue > 0
          && this.user.balance < cardValue
      ) {
        setTimeout(() => {
          Notification.error(
              'Low balance',
              'Your balance is low, please make a deposit to continue playing'
          );
        }, 4000);
      }
    },

    getLatestRounds() {
      this.$http
        .get("get-latest-rounds")
        .then((res) => {
          this.lastestRounds = res.data;
        });
    },

    getUserPurchases() {
      this.$http
        .get("get-user-purchases")
        .then((res) => {
          this.userPurchases = res.data[0];
          this.userScheduledPurchases = res.data[1];
        });
    },

    getUserEarnings() {
      this.$http
        .get("get-user-earnings")
        .then((res) => {
          this.userEarnings = res.data;
        });
    },

    getBalance(attemptCount = 0) {
      this.$http
          .post("me")
          .then((res) => {
            this.user.balance = res.data.balance;
          })
          .catch(() => {
            if (attemptCount < 3) {
              setTimeout(() => {
                this.getBalance(attemptCount + 1);
              }, 1000);
            }
          });
    },

    changeShowHistoric(tab) {
      this.showHistoricGeral = false;
      this.showHistoricCompras = false;
      this.showHistoricGanhos = false;

      if (tab === 1) this.showHistoricGeral = true;
      if (tab === 2) this.showHistoricCompras = true;
      if (tab === 3) this.showHistoricGanhos = true;
    },

    updateData() {
      this.getLatestRounds();
      this.getUserEarnings();
      this.getUserPurchases();
      this.quantityCardsNextRound = Number(localStorage.getItem('cardsNextRound'));
      // this.cardPrice = JSON.parse(localStorage.getItem('user')).site.premiacao.valor_cartela;
      this.cardPrice = localStorage.getItem('card-value');
    },

    toggleVolume() {
      if (window.nanoPlayer) {
        if (this.volume) {
          window.nanoPlayer.mute();
        } else {
          window.nanoPlayer.unmute();
        }
        
        this.volume = !this.volume;
      } else {        
        let video = document.getElementById('video-player_html5_api')
            || document.getElementById('remoteVideo');

            if(video) {
              video.muted = !this.volume;
              this.volume = video.muted;
            }
      }

      localStorage.setItem('sound', this.volume);
      this.toggleSoundEffects(this.volume);
    },

    setLocale(locale) {
      const lang = locale.target.value;
      this.$i18n.locale = lang;
    },

    changeLocaleTo(locale) {
      this.$i18n.locale = locale;
    },

    toggleRules() {
      this.showMenu = false
      this.$emit('toggleRules');
    },

    toggleSoundEffects(e) {
      if(e){
        SFX.unMuteAll();
      } else {
        SFX.muteAll();
      }
    },

    cancelPurchase() {
      this.loadingCancelPurchase = true;
      this.$http
        .post("cancel-purchase")
        .then((res) => {
          this.loadingCancelPurchase = false;
          if (!res.data.success) {
            this.showErrorModal(this.$t('Error'), this.$t('Error while cancel card purchase'));
          } else {
            this.$emit('buyCardsEvent');
            this.$refs.modalCancelPurchase.closeModal();
            EventBus.$emit("getBalance");
            Notification.success(this.$t('successfully'), this.$t('Purchase canceled'));
          }
        })
        .catch(() => {
          this.showErrorModal(this.$t('Error'), this.$t('Error while cancel card purchase'));
        });
    },

    showErrorModal(message, line2 = '') {
      Notification.error(
        message, line2
      );
    },
  },
};
</script>
