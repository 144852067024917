<template>
  <div class="h-100 w-90">
    <div class="d-block d-lg-none h-100">
      <div class="d-flex align-items-center premio-acumulado">
        <div class="d-flex flex-column">
          <span class="title">{{ $t("Jackpot") }}</span>
          <span class="valor">{{ $currency }} {{ $helpers.convertAndFormatMoney(accumulated.value) }}</span>
        </div>
      </div>
    </div>
  
    <div class="d-none d-lg-block h-100">
      <div class="d-flex flex-column align-items-center premio-acumulado text-center">
        <img v-lazy-src="require('@/assets/images/player/Bau_premio.webp')"
             class="img-fluid mb-3"
             alt="">
        <span class="valor">{{ $currency }} {{ $helpers.convertAndFormatMoney(accumulated.value) }}</span>
        <span class="title">{{ $t("Jackpot") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {activeRoundUpdaterInstance} from "@/observer/ActiveRoundUpdater";
import Socket from "@/services/Socket";

export default {
  name: "Accumulated",
  props: ["round"],
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      socket: null,
      accumulated: { value: 0, awarded: null },
      partida_id: null,
    };
  },
  beforeMount() {
    this.socket = Socket.initialize();
    this.partida_id = this.round;
  },
  mounted() {
    this.getPrices();
    //Atrela os valores de premiações quando efetuam compra de cartelas
    this.socket.on("list-plans", () => {
      this.getPrices();
    });

    activeRoundUpdaterInstance.registerObserver((activeRound) => {
      this.partida_id = activeRound;
      this.getPrices();
    });
  },
  methods: {
    getPrices() {
      this.$http
        .get("lista-valores-premiacao/" + this.user.site.id, this.cards)
        .then((res) => {
          this.accumulated.value = res.data.acumulado || 0;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
