<template>
  <div id="app">
    <SplashScreen v-if="showSplash" />

    <div id="award">
      <img v-lazy-src="require('@/assets/images/bingo.webp')" id="img-bingo" alt="" />
      <img v-lazy-src="require('@/assets/images/linha.webp')" id="img-line" alt="" />
      <img v-lazy-src="require('@/assets/images/linha_dupla.webp')" id="img-line-two" alt="" />
    </div>

    <div id="portrait-orientation-warning" v-if="isMobile">
      <lottie-player autoplay loop mode="normal" src="/lottie-animations/portrait-rotate-animation.json" class="mx-auto"
        style="width: 150px;height:auto;">
      </lottie-player>
      <h3>{{ $t("Please rotate your device") }}</h3>
    </div>

    <div id="buy-alerts">
      <div class="box box-error">
        <img v-lazy-src="require('@/assets/images/Erro.webp')" alt="" />
        <p id="message-title">
          {{ $t("Your session has expired") }}
        </p>
        <p id="message-error">
          {{ $t("Insufficient funds") }}
        </p>
      </div>

      <div class="box box-time">
        <img v-lazy-src="require('@/assets/images/time.webp')" alt="" />
        <p>
          {{ $t("Scheduled purchase") }}
        </p>
        <p>{{ $t("for next round") }}</p>
      </div>

      <div class="box box-automatic">
        <img v-lazy-src="require('@/assets/images/time.webp')" alt="" />
        <p>
          {{ $t("Automatic purchase") }}
        </p>
        <p>{{ $t("successfully activated") }}</p>
      </div>

      <div class="box box-success">
        <img v-lazy-src="require('@/assets/images/ok.webp')" alt="" />
        <p id="message-title">
          {{ $t("successfully purchased") }}
        </p>
        <p id="message-success">{{ $t("successfully") }}</p>
      </div>

      <div class="box box-cancel-confirm">
        <img v-lazy-src="require('@/assets/images/ok.webp')" alt="" />
        <p>
          {{ $t("Scheduled purchase") }}
        </p>
        <p>{{ $t("successfully canceled") }}</p>
      </div>
    </div>
    <router-view v-if="user"></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import jQuery from "jquery"
import SplashScreen from './components/SplashScreen.vue'
import { EventBus } from "@/services/EventBus";

export default {
  name: 'App',
  components: {
    SplashScreen
  },
  data() {
    return {
      isMobile: false
    }
  },
  computed: {
    ...mapState(['user']),
    showSplash() {
      return ['launch'].includes(this.$route.name)
    },
  },
  beforeMount() {
    this.setUser();

    jQuery('body').dblclick(function (event) {
      event.preventDefault();
      return;
    });

    window.addEventListener('touchstart', function (e) {
      if (e.targetTouches.length >= 2) {
        e.preventDefault();
      }
    }, false);

    document.addEventListener('wheel', function (event) {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    }, { passive: false });

    document.addEventListener('keydown', function (event) {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    });

    localStorage.setItem('roundRunning', '0');

    EventBus.$on("roundStarted", () => {
      localStorage.setItem('roundRunning', '1');
      this.fetchUser();
    });

    EventBus.$on("roundEnded", () => {
      localStorage.setItem('roundRunning', '0');
      this.fetchUser();
    });
  },
  mounted() {
    this.isMobile = this.getIsMobile();
  },
  methods: {
    setUser() {
      this.$store.commit('setUser')
    },
    fetchUser() {
      this.$store.commit('fetchUser')
    },
    getIsMobile() {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      }
      return false;
    }
  }
}
</script>
