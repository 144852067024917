<template>
  <div>
    <div class="row m-0">
      <div class="col-4 pl-4 pt-4 pb-4 text-left head-dealer text-white">
        <span>Rodada {{ roundActive }}</span>
      </div>

      <div class="col-4 text-center pt-4 pb-4 pr-4 head-dealer text-white">
        <span><b> {{ currentDate }}</b></span>
      </div>

      <div class="col-4 text-right pt-4 pb-4 pr-4 head-dealer text-white m-0">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            {{ user.name || "Admin" }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click="nextRound">Avançar rodada</a>
            <a class="dropdown-item" href="#" @click="scheduleRound">Agendar rodada</a>
            <a class="dropdown-item" href="#" @click="toggleAutomaticBalls">Bolas automáticas</a>
            <a class="dropdown-item" href="#" @click="toggleCardVerify">Verificar cartela</a>
            <a class="dropdown-item" href="#" @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Socket from "@/services/Socket";

export default {
  name: "Head",
  props: ["round"],
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      socket: null,
      roundActive: null,
      currentDate: null
    };
  },
  beforeMount() {
    if (this.user.tipo == "jogador") {
      localStorage.removeItem("user");
      this.$router.push("login");
    }

    setInterval(() => {
      this.currentDate = this.showDate();
    }, 1000);
  },
  mounted() {
    this.socket = Socket.initialize();
    //Seta a partida corrente
    this.roundActive = this.round;
    this.socket.on("partida", (partida) => {
      this.roundActive = partida.partida;
    });
  },
  methods: {
    showDate() {
      return moment().format("DD/MM/Y H:mm");
    },

    nextRound() {
      this.roundActive = null;
      /**
       * Emite uma mensagem ao socket dizendo que as apostas para a partida corrente estão encerradas
       * */
      this.socket.emit("status-bet", {
        room   : "bingo",
        message: {
          user          : {
            id  : this.user.id,
            tipo: this.user.tipo,
          },
          status        : false,
          menu_selection: true
        },
      });
    },

    logout() {
      this.$http
        .post("logout")
        .then(() => {
          localStorage.removeItem("user");
          window.location.href = '/login';
        })
        .catch((error) => {
          console.error(error);
        });
    },

    toggleCardVerify() {
      this.$parent.toggleCardVerifyModal();
    },

    toggleAutomaticBalls() {
      this.$parent.toggleAutomaticBallsModal();
    },

    scheduleRound() {
      this.$parent.toggleScheduleRound();
    },
  },
};
</script>
