import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from './router';
import {Translate} from "@/services/Translate";
import {EventBus} from "@/services/EventBus";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: null,
        session: null,
    },
    mutations: {
        async setUser(state) {
            let handleStartError = (e) => {
                console.log(Translate('Erro ao iniciar o jogo, faça login novamente.'));
                console.log(JSON.stringify(e));
                window.location.replace('/alert?message=' + Translate('Erro ao iniciar o jogo, faça login novamente.'));
            };
            
            let launchInitialize = async () => {
                try {
                    await axios.get('launch-initialize', {
                        headers: {
                            'session'            : router.app.$route.params.hash,
                            'browser-fingerprint': localStorage.getItem('browser_fingerprint'),
                        }
                    })
                               .then((res) => {
                                   this.session = res.data;
                                   this.state.session = res.data;
                                   
                                   localStorage.setItem('currency', res.data.currency);
                                   localStorage.setItem('locale', res.data.locale);
                                   localStorage.setItem('browser_fingerprint', res.data.browser_fingerprint);
                                   
                                   axios.defaults.headers.common['browser-fingerprint'] = res.data.browser_fingerprint;
                                   axios.defaults.headers.common['session']             = router.app.$route.params.hash;

                                   if (this.session.locale === "US") {
                                       let changeLanguageToEnUs = () => {
                                           setTimeout(() => {
                                               let enUs = document.getElementById('change-language-to-en-us');

                                               if (!enUs) {
                                                   changeLanguageToEnUs();
                                                   return;
                                               }

                                               enUs.click();
                                           }, 1000);
                                       };

                                       changeLanguageToEnUs();
                                   }

                                   if (this.session.currency !== 'BRL') {
                                       (async () => {
                                           try {
                                               let response = await fetch(
                                                   process.env.VUE_APP_CMS_ENDPOINT + '/api/currency-rate/' + this.session.currency + '-BRL?casino_id=' + this.session.casino_id,
                                               );

                                               if (!response.ok) {
                                                   handleStartError(response);
                                               } else {
                                                   let data = await response.json();
                                                   localStorage.setItem('brl_rate', data.rate);

                                                   let currency = data.currencies[this.session.currency];
                                                   let format   = currency.format[currency.display_format];

                                                   localStorage.setItem('currency_code', currency.code);
                                                   localStorage.setItem('currency_precision', currency.precision);
                                                   localStorage.setItem('currency_symbol', format.symbol);
                                                   localStorage.setItem('currency_format_precision', format.precision);
                                                   localStorage.setItem('currency_presentation_multiplier', format.presentation_multiplier || 1);
                                                   
                                                   Vue.prototype.$currency = format.symbol;
                                               }
                                           } catch (error) {
                                               console.error('There has been a problem with your fetch operation:', error);
                                           }
                                       })();
                                   } else {
                                       Vue.prototype.$currency = 'R$';
                                   }
                               })
                               .catch(handleStartError);
                } catch (e) {
                    handleStartError(e);
                }
            };

            // VibraGaming
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('serverAddress')) {
                let siteUserId = urlParams.get('siteId');
                let userId     = urlParams.get('userId');
                let token      = urlParams.get('token');
                let currency   = urlParams.get('currency');
                let user_name  = urlParams.get('user_name');
                let aggregator = 'vibragaming';
                axios.post('initialize?userId=' + userId + '&siteId=' + siteUserId + '&token=' + token + '&currency=' + currency + '&user_name=' + user_name + '&aggregator=' + aggregator).then((res) => {
                    window.location.replace(res.data.url);
                    return;
                }).catch(error => {
                    console.error(error);
                });
            }

            let localUser      = JSON.parse(localStorage.getItem('user'));
            let currentTime    = new Date().getTime();
            let twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000);
            let lastLogin      = parseInt(localStorage.getItem('last_login') || 0);

            if (router.app.$route.name === 'test22'
                && localUser
                && (
                    lastLogin < twelveHoursAgo
                    || !localUser.email.includes('demo')
                )
            ) {
                localUser = null;
                localStorage.removeItem('user');
            }
            
            if (router.app.$route.name === 'test22'
                && localUser
            ) {
                window.location.replace('/game?site_id=1&user_id=' + localUser.site_user_id + '&token=1&currency=BRL&user_name=' + localUser.name + '&locale=BR');
            }

            if (router.app.$route.name === 'game') {
                let urlParams = new URLSearchParams(window.location.search);
                
                axios.post('launch', {
                    'site_id'  : urlParams.get('site_id'),
                    'user_id'  : urlParams.get('user_id'),
                    'token'    : urlParams.get('token'),
                    'currency' : urlParams.get('currency'),
                    'user_name': urlParams.get('user_name'),
                    'locale'   : urlParams.get('locale'),
                })
                     .then((res) => {
                         window.location.replace('/launch/' + res.data.hash);
                     })
                     .catch(handleStartError);
                return;
            } else if (router.app.$route.name === 'launch') {
                await launchInitialize();
            }

            if (this.state.user != null
                && this.state.user != 'login'
            ) {
                this.state.user = state.user;
            }
            
            //Remove qualquer usuário do localstorage para poder efetuar o login corretamente
            if (router.app.$route.name === 'launch'
                && this.session.casino_id
                && this.session.site_user_id
                && this.session.user_token
            ) {
                axios.get('get-balance')
                     .then((res) => {
                         if (res.data) {
                             if (res.data.errors
                                 && res.data.errors.length > 0
                             ) {
                                 handleStartError(res.data);
                                 return;
                             }

                             this.state.user                                = res.data;
                             let token                                      = res.data.access_token || res.data.token;
                             axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                             localStorage.setItem('user', JSON.stringify(res.data));
                             
                             EventBus.$emit("userFetched");
                         } else {
                             localStorage.removeItem('user');
                         }
                     })
                     .catch(error => {
                         console.error(error);
                     });
            } else if (router.app.$route.name != 'login') {
                //Pega os valores do localStorage
                let userLocal  = localStorage.getItem('user');
                let parseLocal = JSON.parse(userLocal);

                if (parseLocal) {
                    let token                                      = parseLocal.access_token || parseLocal.token;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }

                if (parseLocal && parseLocal.tipo == 'administrador') {
                    this.commit('fetchUser');
                } else if (!userLocal
                    && router.app.$route.name === 'test22'
                ) { //Se não existir ninguém, seta o novo usuário
                    //Criar usuário novo para teste
                    axios.post('generate-user')
                         .then((res) => {
                             localStorage.setItem('last_login', new Date().getTime());
                             window.location.replace('/game?site_id=1&user_id=' + res.data.site_user_id + '&token=1&currency=BRL&user_name=' + res.data.name + '&locale=BR');
                         });
                } else {
                    /**
                     * remover esse else depois
                     * */
                    this.state.user = 'register';
                }
            } else {
                this.state.user = 'login';
            }
            
            // Fica renovando o token do user de tempo em tempo para não deixar a sessão cair
            if (router.app.$route.name === 'dealer') {
                setInterval(() => {
                    this.commit('fetchUser');
                }, 60000);
            }
        },
        fetchUser() {
            axios.post('refresh')
                 .then((res) => {
                     if (res.data) {
                         let token                                      = res.data.access_token || res.data.token;
                         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                         this.state.user = res.data;

                         localStorage.removeItem('user');
                         localStorage.setItem('user', JSON.stringify(res.data));
                     }

                     EventBus.$emit("userFetched");
                 })
                 .catch((error) => {
                     console.error(error);

                     let localUser = JSON.parse(localStorage.getItem('user'));
                     if (error.response.status === 401
                         && localUser.tipo == 'administrador'
                     ) {
                         window.location.replace('/login');
                     }
                 });
        }
    }
})

export { store }
