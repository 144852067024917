<template>
  <div class="modal-automatic-balls">
    <div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <h4>Bolas automáticas</h4>
          <button type="button" @click="close">
            <h4>&#x2715;</h4>
          </button>
        </div>
      </div>
      <hr />
      <div class="form-group">
        <button type="button" class="btn btn-success float-right" @click="changeAutomaticBalls">
          {{ automaticBallsActive ? 'Desativar' : 'Ativar' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Socket from "@/services/Socket";

export default {
  name: "AutomaticBalls",
  props: ["round"],
  data() {
    return {
      socket: null,
      numbers: [],
      roundActive: null,
      automaticBallsActive: null,
      intervalAutomaticBalls: null,
      lastStatus: null,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  beforeMount() {
    this.roundActive = this.round;
  },

  mounted() {
    this.socket = Socket.initialize();
    
    if (localStorage.getItem('automaticBallsActive') === 'true') {
      setTimeout(() => {
        this.enableAutomaticBalls();
      }, 1500);
    }
    
    // Quando sorteia uma bola
    this.socket.on("give-back-ball", (message) => {
      this.ballsSorted(message);
    });

    // Quando tem ganhador
    this.socket.on("dispatch-winners", (message) => {
      const string =
        message[
          message.length - 1
        ].premiacao_tipo.premiacao_tipo.toLowerCase();

      if (this.automaticBallsActive) {
        if (string === 'linha' && this.lastStatus !== 'linha') {
          this.disableAutomaticBalls();
          setTimeout(() => {
            this.enableAutomaticBalls();
          }, 15000);
        }

        if (string === 'linha dupla' && this.lastStatus !== 'linha dupla') {
          this.disableAutomaticBalls();
          setTimeout(() => {
            this.enableAutomaticBalls();
          }, 15000);
        }

        if (string === 'bingo' && this.lastStatus !== 'bingo') {
          this.numbers = [];
          clearInterval(this.intervalAutomaticBalls);
          setTimeout(() => {
            document.getElementById("go-round").click();
            
            setTimeout(() => {
              document.getElementById("set-timer-to-30-s").click();
            }, 5000);
          }, 8000);
        }
      }
      this.lastWinner = string;
    });

    // Ao iniciar uma nova partida
    this.socket.on("partida", (partida) => {
      if (partida.user.tipo == "administrador" && partida.user.name == "admin") {
        if (this.automaticBallsActive) {
          if (this.lastStatus === null) {
            setTimeout(() => {
              this.enableAutomaticBalls();
            }, 4000);
          }
        }
      }
    });

  },

  methods: {
    insertNumbers() {
      this.numbers = [];
      for (let i = 1; i <= 90; i++) {
        this.numbers.push({
          number: i,
          status: 1,
        });
      }
    },

    getBallsInit() {
      this.$http
        .get("bola-sorteada/" + this.round)
        .then((res) => {
          this.sortedNumbers = res.data.lastBalls;
          res.data.lastBalls.forEach((sorted) => {
            this.numbers.forEach((number, index) => {
              if (sorted.bola == number.number) {
                this.numbers[index].status = 0;
              }
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    ballsSorted(ball = null) {
      if (this.round && this.automaticBallsActive) {
        if (ball) {
          this.numbers.forEach((number, index) => {
            if (ball.bola == number.number) {
              this.numbers[index].status = 0;
              this.sortedNumbers.push(ball);
            }
          });

          if (this.sortedNumbers.length === 90) {
            clearInterval(this.intervalAutomaticBalls);
            setTimeout(() => {
              document.getElementById("go-round").click();

              setTimeout(() => {
                document.getElementById("set-timer-to-1-min").click();
              }, 5000);
            }, 8000);
          }
        }
      }
    },

    enableAutomaticBalls() {
      if (window.location.hostname === 'bingo.copacabana.games') return; // Prod
      
      this.automaticBallsActive = true;
      localStorage.setItem('automaticBallsActive', 'true');

      this.insertNumbers();
      this.getBallsInit();

      document.querySelector("#alert-automatic-balls").style.display = 'block';

      clearInterval(this.intervalAutomaticBalls);

      this.intervalAutomaticBalls = setInterval(() => {
        // Colocar no array números que ainda não foram sorteados
        const missingNumbers = [];

        this.numbers.forEach((number) => {
          if (number.status == 1) {
            missingNumbers.push(number.number);
          }
        });

        // Pegar um número aleatorio nesse array
        const randomIndex = Math.ceil(Math.random() * (missingNumbers.length - 1));
        const randomNumber = missingNumbers[randomIndex];

        // Evento de clique nesse número
        document.getElementById("" + randomNumber).click();
        setTimeout(() => {
          document.getElementById("saveBall").click();
        }, 500);
      }, 3000);
    },

    disableAutomaticBalls() {
      document.querySelector("#alert-automatic-balls").style.display = 'none';
      this.automaticBallsActive = false;
      localStorage.setItem('automaticBallsActive', 'false');
      clearInterval(this.intervalAutomaticBalls);
    },

    changeAutomaticBalls() {
      if (this.automaticBallsActive) {
        this.disableAutomaticBalls();
      } else {
        if (window.location.hostname === 'bingo.copacabana.games') return; // Prod
        
        if (document.querySelector('#time')) {
          this.automaticBallsActive = true;
          document.querySelector("#alert-automatic-balls").style.display = 'block';
        } else {
          this.enableAutomaticBalls();
        }
      }
    },

    close() {
      this.$parent.toggleAutomaticBallsModal();
    },
  },
};
</script>
