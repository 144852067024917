import jQuery from "jquery";
import { Translate } from "@/services/Translate";
import SFX from "@/services/SFX";

window.SFX = SFX;
export class Notification {
    timeout = 5000;

    error(title, message = null) {
        this.errorTitleEle = jQuery(".box-error #message-title");
        this.errorMessageEle = jQuery(".box-error #message-error");

        this.oldErrorTitle = this.errorTitleEle.text();
        this.oldErrorMessage = this.errorMessageEle.text();

        this.errorTitleEle.text(
            Translate(title)
        );

        this.errorMessageEle.text(
            message ? Translate(message) : ''
        );

        jQuery("#buy-alerts, .box-error").fadeIn();

        this.timeoutHide = setTimeout(() => {
            this.hideError();
        }, this.timeout);

        setTimeout(() => {
            jQuery(document).on('click.notificationEvent', () => this.hideError());
        }, 500);

        SFX.playErrorMessage();
    }

    success(title, message = null) {
        // TODO: Implement success notification
        this.successTitleEle = jQuery(".box-success #message-title");
        this.successMessageEle = jQuery(".box-success #message-success");

        this.oldSuccessTitle = this.successTitleEle.text();
        this.oldSuccessMessage = this.successMessageEle.text();

        this.successTitleEle.text(
            Translate(title)
        );

        this.successMessageEle.text(
            message ? Translate(message) : ''
        );

        jQuery("#buy-alerts, .box-success").fadeIn();

        this.timeoutHide = setTimeout(() => {
            this.hideSuccess();
        }, this.timeout);

        setTimeout(() => {
            jQuery(document).on('click.notificationEvent', () => this.hideSuccess());
        }, 500);

        SFX.playSuccessMessage();
    }

    hideError() {
        if (this.timeoutHide) {
            clearTimeout(this.timeoutHide);
        }

        jQuery(document).off('click.notificationEvent');

        jQuery("#buy-alerts, .box-error").fadeOut(400, () => {
            this.errorTitleEle.text(this.oldErrorTitle);
            this.errorMessageEle.text(this.oldErrorMessage);
        });
    }

    hideSuccess() {
        if (this.timeoutHide) {
            clearTimeout(this.timeoutHide);
        }

        jQuery(document).off('click.notificationEvent');

        jQuery("#buy-alerts, .box-success").fadeOut(400, () => {
            this.successTitleEle.text(this.oldSuccessTitle);
            this.successMessageEle.text(this.oldSuccessMessage);
        });
    }
}

export default new Notification;
