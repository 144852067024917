<template>
  <div class="interval-game-time-rng">
    <div class="text-center opacity-70" style="z-index: 40;" v-show="showTime">
      <div class="countdown bg-gray-800 rounded-circle text-white m-auto rng-area" style="" :data-progress="percentage">
        <svg viewBox="-50 -50 100 100" stroke-width="8">
          <circle r="45"></circle>
          <circle r="45" pathLength="1" class="transition-all" 
            :style="circleStyle" 
            :class="{'stroke-green': time > 15, 'stroke-yellow': time <= 15 && time > 5, 'stroke-red': time <= 5}">
          </circle>
        </svg>
        <span
          class="text-white">{{ countdown }}
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import Socket from "@/services/Socket";
import SFX from "@/services/SFX";
// import {gsap} from "gsap";

export default {
  name      : "IntervalGameTimeRNG",
  components: {},
  props     : [],
  data() {
    return {
      showTime  : false,
      countdown : 0,
      time      : 0,
      percentage: 100,
    };
  },
  
  computed: {
    circleStyle() {
      return {
        strokeDasharray: (this.percentage/100) + ' 1'
      };
    },
  },

  beforeMount() {
    this.socket       = Socket.initialize();

    window.addEventListener('resize', this.adjustRNGArea);

    this.socket.on("new-game", () => {
      this.animateDisappearance();
    });

    this.socket.on("give-back-ball", () => {
      this.adjustRNGArea();
    });
  },

  mounted() {
    let countdownTime = 59;

    this.socket.on("show-timer", (request) => {
      this.calculateTime(request);
      this.percentage = parseInt((this.time / countdownTime) * 100);
      let isLive = localStorage.getItem('is-rng-running') == 0 ? true : false;
      if ((this.time > 0 && !this.showTime) && !isLive) {
        this.showTime = true;
        this.animateAppearance();

        if (this.time > 45) {
          setTimeout(() => {
            SFX.playBackground();
            SFX.playRng('comprem_suas_cartelas'); // máx. 6s
          }, 6000);
        }
      }
    });

    setTimeout(() => {
      this.adjustRNGArea();
    }, 5000);
  },

  methods: {
    calculateTime(request) {
      const date   = new Date('2000-01-01 ' + request.time);
      const hour   = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();

      if (hour) {
        this.time = ((hour) * 60) * ((minute || 1) * 60) + (second);
      } else {
        this.time = ((minute) * 60) + (second);
      }

      if (this.time === 45) {
        SFX.playRngCuriosidade();
      }

      if(this.time === 10) {
        SFX.playRng('dez_segundos');
      }

      this.countdown = (hour ? hour + ':' : '') + (minute ? minute + ':' : '') + (second >= 10 ? second : '0' + second);

      if (this.time <= 0) {
        this.animateDisappearance();
      }
    },

    animateAppearance() {
      this.adjustRNGArea();
      // gsap.fromTo(
      //     '.interval-game-time-rng',
      //     {scale: 0, opacity: 0},
      //     {scale: 1, opacity: 1, duration: 0.5}
      // );
    },

    animateDisappearance() {
      this.showTime = false;
      // gsap.to('.interval-game-time-rng', {
      //   scale     : 0,
      //   opacity   : 0,
      //   duration  : 0.5,
      //   onComplete: () => {
      //     this.showTime = false;
      //   },
      // });
    },

    adjustRNGArea() {
      let videoArea   = document.querySelector('.responsive-video-container');
      let videoWidth  = videoArea.clientWidth;
      let videoHeight = videoArea.clientHeight;

      let circleSize = Math.min(videoWidth, videoHeight);
      let style      = document.querySelector('style#rng-area-style');

      if (style) {
        style.remove();
      }

      style           = document.createElement('style');
      style.id        = 'rng-area-style';
      style.innerHTML = `
        .rng-area {
          width: calc(${circleSize}px/2);
          height: calc(${circleSize}px/2);
        }
        .ramdon-ball-generator, .interval-game-time-rng {
          top: calc(${videoHeight}px/1.9);
        }
      `;

      document.head.appendChild(style);
    },
  },
};
</script>

<style scoped>
.interval-game-time-rng {
  position: absolute; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 10; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.interval-game-time-rng .rounded-circle {
  font-size: 50px;
  font-weight: bold;
  position: relative;
}

.interval-game-time-rng span {
  position: absolute; 
  top: 0; 
  width: 100%; 
  height: 100%;
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.countdown {
  display: grid;
  margin: 1em auto;
  transition: all 1s linear;
}

.transition-all {
  transition: all 1s linear;
}

@keyframes t {
  to {
    --t: 0 ;
  }
}
.countdown svg {
  grid-column: 1;
  grid-row: 1;
}

.countdown svg [r] {
  fill: none;
  stroke: rgba(245, 245, 245, 0.205);
}

.countdown svg [r] + [r] {
  transform: rotate(-90deg);
  stroke-linecap: round;
}

.stroke-green {
  stroke: rgb(34, 197, 94) !important;
}

.stroke-yellow {
  stroke: rgb(234 179 8) !important;
}

.stroke-red {
  stroke: rgb(239 68 68) !important;
}

@media only screen and (max-width: 600px) {
  .interval-game-time-rng .rounded-circle {
    font-size: 35px;
  }
}
</style>
