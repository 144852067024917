import messages from "@/assets/translate/messages.json";

function Translate(key) {
    let currentLocale  = 'pt-BR';
    let fallbackLocale = 'pt-BR';

    if (localStorage.getItem('locale') === "US")
        currentLocale = "en-US";

    return messages[currentLocale][key] || messages[fallbackLocale][key] || key;
}

export {Translate};
