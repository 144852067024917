import {Subject} from './Subject';

export class LocalStorageValueUpdater extends Subject {
    setValue(value) {
        const currentValue = this.getValue();

        if (value !== currentValue) {
            if (this.parseSet) {
                localStorage.setItem(this.key, this.parseSet(value));
            } else {
                localStorage.setItem(this.key, value);
            }

            this.notifyObservers(value);
        }
    }

    getValue() {
        if (this.parseGet) {
            return this.parseGet(localStorage.getItem(this.key));
        }

        return localStorage.getItem(this.key);
    }
}
