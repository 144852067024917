<template>
  <div class="col-12 box px-0">
    <div class="row mx-0">
      <div class="
          col-12 col-md-3
          head-box
          bg-green
          px-3
          py-2
          text-center text-md-left
        ">
        <table>
          <tr>
            <td>
              <span>Por 1 bola:&nbsp;</span>
            </td>
            <td>
              <span>{{ cardsOneBall }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Por 2 bolas:&nbsp;</span>
            </td>
            <td>
              <span>{{ cardsTwoBalls }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Por 3 bolas:&nbsp;</span>
            </td>
            <td>
              <span>{{ cardsTreeBall }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Por 4 bolas:&nbsp;</span>
            </td>
            <td>
              <span>{{ cardsFourBalls }}</span>
            </td>
          </tr>
        </table>
      </div>

      <div class="col-12 col-md-9 col-lg-6 text-center head-box bg-green">
        <div class="row pt-3">
          <div class="col-12">
            <span class="d-block"
                  v-if="!this.isTimerRunning">
              Total de cartelas: {{ totalCards }}
            </span>
            <span class="d-block"
                  v-if="this.isTimerRunning">
              Total de cartelas próxima rodada: {{ totalCardsNextRound }}
            </span>
          </div>

          <div class="col-12">
            <span id="numbers-sorted" class="d-block">Bolas Sorteadas: {{ sortedNumbers.length }}</span>
            <p class="my-2 text-danger font-weight-bolder" id="alert-automatic-balls">BOLAS AUTOMÁTICAS ATIVADAS</p>
          </div>
        </div>
      </div>

      <div class="d-none d-lg-block col-12 col-md-3 head-box bg-green"></div>
    </div>

    <div class="container-box bg-numbers pb-3 bottom-radius">
      <div id="numbers">
        <div @click="sendNumberConfirm(number)" :class="{ 'bg-red': number.status == 0 }" class="number"
          v-for="(number, index) in numbers" :key="index" :id="number.number">
          <a href="javascript:;" :class="{ 'text-white': number.status == 0 }" class="d-inline-block">{{ number.number }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import jQuery from "jquery";
import Socket from "@/services/Socket";
import {totalCardsNextRoundUpdaterInstance} from "@/observer/TotalCardsNextRoundUpdater";

export default {
  name: "Numbers",
  props: ["round"],
  data() {
    return {
      socket: null,
      numbers: [],
      sortedNumbers: [],
      cardsOneBall: 0,
      cardsTwoBalls: 0,
      cardsTreeBall: 0,
      cardsFourBalls: 0,
      totalCards: 0,
      totalCardsNextRound: 0,
      confirm: null,
      roundActive: null,
      liberate: true,
      cardsPlayer: [],
      lastPrize: 0,
      queue: [],
      hasPrize: false,
      isTimerRunning: false,
      bingoBall: 0
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  beforeMount() {
    this.roundActive = this.round;
    this.insertNumbers();
    this.ballsSorted();
    this.getBallsInit();
    this.checkCards();
  },
  mounted() {
    this.socket = Socket.initialize();
    
    this.socket.on("update-total-cards", (message) => {
      // if (this.isTimerRunning) {
      //   this.fetchCardsNextRound();
      // } else if (message.partida_id == this.round) {
      //   this.totalCards = parseInt(this.totalCards) + parseInt(message.cards);
      // }

      if (this.isTimerRunning) {
        this.totalCardsNextRound = message.cards_count;
      } else if (message.round_id == this.round) {
        this.totalCards = message.cards_count;
      }
    });

    this.socket.on("partida", (partida) => {
      //Lista somente se a partida vier do dealer (troca de partida)
      if (partida.user.tipo == "administrador" && partida.user.name == "admin") {
        this.clearAndSaveQueue();
        this.roundActive = partida.partida;
        this.insertNumbers();
        this.ballsSorted();
        this.checkCards();
        this.getBallsInit();
      }
    });

    //limpa as bolinhas vermelhas caso saia bingo
    this.socket.on("status-bet", (status) => {
      if (status.user.id == this.user.id) {
        if (!status.status && !this.isTimerRunning) {
          this.checkCards();
        }
        
        this.isTimerRunning = !status.status;
      }
      
      if (this.isTimerRunning) {
        this.clearAndSaveQueue();
      }
      
      if (status.user.id == this.user.id && status.status == false) {
        this.insertNumbers();
        this.cardsOneBall = 0;
        this.cardsTwoBalls = 0;
        this.cardsTreeBall = 0;
        this.cardsFourBalls = 0;
        this.totalCards = 0;
        this.sortedNumbers = [];
        this.lastPrize = 0;
        this.cardsPlayer = [];
      }
    });

    this.getCardsAndPlayersCurrentRound();

    this.startProcessing();
  },
  methods: {
    checkCards() {
      if (this.round) {
        this.$http
            .post("/check-cards/" + this.round)
            .then((response) => {
              this.cardsOneBall        = response.data.one;
              this.cardsTwoBalls       = response.data.two;
              this.cardsTreeBall       = response.data.three;
              this.cardsFourBalls      = response.data.four;
              this.totalCards          = response.data.total;
              this.totalCardsNextRound = response.data.totalNextRound;

              totalCardsNextRoundUpdaterInstance.setValue(this.totalCardsNextRound);
            })
            .catch((error) => {
              console.error(error);
            });
      }
    },

    fetchCardsNextRound() {
      if (this.round) {
        this.$http
            .post("/check-cards/" + this.round)
            .then((response) => {
              this.totalCardsNextRound = response.data.totalNextRound;
              totalCardsNextRoundUpdaterInstance.setValue(this.totalCardsNextRound);
            })
            .catch((error) => {
              console.error(error);
            });
      }
    },

    /**
     * Registra a bola sorteada no banco de dados
     * */
    registerBall(ball) {
      // Verificar 1° bola
      if (this.cardsPlayer.length == 0) {
        this.getCardsAndPlayersCurrentRound(ball.bola);
      }
      
      if (this.bingoBall > 0) {
        this.$notify({
          title   : "<b>Erro!</b>",
          text    : 'Não é possível sortear mais bolas, pois já saiu bingo para esta rodada',
          type    : "error",
          duration: 5000,
        });
      
        jQuery('#' + ball.bola).removeClass('number-gray');
      
        this.$emit("listWinners");
       
        return;
      }

      // Verificar se saiu premio com JS
      this.sortedNumbers.push(ball);
      this.preventDuplicateSortedNumbers();
      
      if (this.hasBingoAndNotify(ball.bola)) {
        this.bingoBall = ball.bola;
      }

      let dateRegister = moment().format("Y-MM-DD hh:mm:ss");
      this.numbers.forEach((number) => {
        if (ball.bola == number.number) {
          //Não deixa enviar a chamada para registro se a bola já estiver sido registrada
          if (number.status == 1) {
            ball.dateRegister = dateRegister;
            ball.lastBalls    = this.sortedNumbers.map((ball) => ball.bola);
            ball.partida_id   = this.round;

            this.addToQueue(ball);
            this.addToQueue({'request_type': 'ganhador_user'});
          }
        }
      });

      if (this.sortedNumbers.length == 1) {
        setTimeout(() => {
          this.updateLiveUsersCountCurrentRound();
        }, 1000);
      }
    },

    loadQueue() {
      const loadedQueue = localStorage.getItem('requestQueue');
      return loadedQueue ? JSON.parse(loadedQueue) : [];
    },

    saveQueue() {
      localStorage.setItem('requestQueue', JSON.stringify(this.queue));
    },

    addToQueue(request) {
      this.queue.push(request);
      this.saveQueue();
    },
    
    clearAndSaveQueue() {
      this.queue = [];
      this.saveQueue();
      this.isProcessing = false;

      jQuery('.number-gray').removeClass('number-gray');
    },

    async processQueue() {
      if (this.isProcessing || this.queue.length === 0) {
        return;
      }

      this.isProcessing = true;
      const request = this.queue[0];
      if (request.request_type === 'ganhador_user') {
        try {
          await this.$root.$refs.Winners.getWinners();
          this.queue.shift();
          this.saveQueue();
        } catch (err) {
          console.log(err);
        }
        this.isProcessing = false;
        return;
      }

      if (request.partida_id !== this.round) {
        this.queue.shift();
        this.saveQueue();
        jQuery('#' + request.bola).removeClass('number-gray');
        return;
      }

      try {
        // Replace this with your actual request sending logic
        let ball = await this.sendRequest(request);

        if (ball.data.data && ball.data.data.hasBingo) {
          this.$notify({
            title   : "<b>Erro!</b>",
            text    : ball.data.message,
            type    : "error",
            duration: 5000,
          });

          this.clearAndSaveQueue();
          this.checkCards();
          return;
        }

        // Pintar a bola sorteada
        this.ballsSorted(request);
        
        //Emite evento para salvar a bola e ordenar as cartelas
        if(ball.data.id) {
          this.socket.emit("bola-salva", {
            room: "bingo",
            message: request,
          });
          this.socket.emit("give-back-ball", { room: "bingo", message: request });
        }

        // if(this.hasPrize && this.queue.length == 1) {
        // this.$emit("listWinners");
        //   this.hasPrize = false;
        // }
        
        //Verificar quantidade de cartelas que estão próximas a serem preenchidas
        this.checkCards();

        // Remove processed request from queue and save
        this.queue.shift();
        this.saveQueue();
      } catch (err) {
        console.error('Error processing request:', err);
        // if (request.attempts >= 5) {
        //   // if request has already been attempted 5 times, remove it from queue and save
        //   this.queue.shift();
        //   console.error('Request failed after 5 attempts', request);
        // } else {
        //   // otherwise, increment attempts and save
        //   request.attempts = (request.attempts || 1) + 1;
        // }
        
        // this.saveQueue();
      }

      this.isProcessing = false;
    },

    async sendRequest(request) {
      const response = await this.$http.post("/bola/" + request.partida_id, request);
      if (!response.data.id
          || response.data.errors
      ) {
        throw response.data.errors || 'Unknown error';
      }
      return response;
    },

    startProcessing(interval = 100) {
      if (this.processInterval) {
        return;
      }

      this.queue = this.loadQueue();
      this.hasPrize = this.queue.length > 0;
      this.queue.forEach(request => {
        if(request.request_type !== 'ganhador_user') {
          document.getElementById(request.bola).classList.add("number-gray");
        }
      });

      this.processInterval = setInterval(() => this.processQueue(), interval);
    },

    stopProcessing() {
      if (this.processInterval) {
        clearInterval(this.processInterval);
        this.processInterval = null;
      }
    },
    /**
     * Envia para o socket a bola que acabou de ser selecionada
     * */
    sendNumberConfirm(numberSelected) {
      if (numberSelected.status == 1) { // Remover liberate para deixar marcar varias bolas
        let elements = document.getElementsByClassName("number");
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove("number-selected");
        }
        document
          .getElementById(numberSelected.number)
          .classList.add("number-selected");

        this.numbers.forEach((number) => {
          if (numberSelected.number == number.number) {
            //Seleciona o número se ele ainda não for escolhido
            if (number.status == 1) {
              //Emite o evento para aparecer a bola que será confirmada
              this.$emit("changeNumberSelected", numberSelected.number);
            }
          }
        });
      }
    },
    /**
     * Insere os números para o dealer poder escolher de 1 a 90
     * */
    insertNumbers() {
      this.numbers = [];
      for (let i = 1; i <= 90; i++) {
        this.numbers.push({
          number: i,
          status: 1,
        });
      }
    },
    /**
     * Traz as bolas que já foram sorteadas
     * */
    ballsSorted(ball = null) {
      if (this.round) {
        if (ball) {
          this.numbers.forEach((number, index) => {
            if (ball.bola == number.number) {
              this.numbers[index].status = 0;
              this.sortedNumbers.push(ball);
              this.preventDuplicateSortedNumbers();
            }
          });
        }
      }
    },
    getBallsInit() {
      this.$http
        .get("bola-sorteada/" + this.round)
        .then((res) => {
          this.sortedNumbers = res.data.lastBalls;
          this.preventDuplicateSortedNumbers();
          
          res.data.lastBalls.forEach((sorted) => {
            this.numbers.forEach((number, index) => {
              if (sorted.bola == number.number) {
                this.numbers[index].status = 0;
              }
            });
          });

          if (this.sortedNumbers.length >= 90) {
            this.roundActive = null;
            this.$notify({
              title: "<b>Alerta!</b>",
              text: "Fim desta rodada.",
              type: "warn",
              duration: 5000,
            });
            this.socket.emit("status-bet", {
              room   : "bingo",
              message: {
                user  : {
                  id  : this.user.id,
                  tipo: this.user.tipo,
                },
                status: false
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    preventDuplicateSortedNumbers() {
      this.sortedNumbers = this.sortedNumbers.filter((ball, index, self) => {
        return self.findIndex((b) => b.bola === ball.bola) === index;
      });
    },

    confirmNumberSelection(number) {
      if (document.getElementsByClassName("number-selected").length > 0) {
        document.getElementsByClassName("number-selected")[0].classList.add("number-gray");
      }
      this.registerBall(number);
    },

    liberateBall() {
      this.liberate = true;
    },

    getCardsAndPlayersCurrentRound(ball = 0) {
      this.$http
        .get("get-cards-players-current-round")
        .then((res) => {
          this.cardsPlayer = res.data;
          if (ball > 0) this.hasBingoAndNotify(ball);
        })
        .catch((error) => {
          console.error(error);
        });

      this.$http
        .get("ganhadores")
        .then((res) => {
          if (res.data.length > 0) {
            this.lastPrize = res.data.at(-1).premiacao_tipo_id;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    hasBingoAndNotify() {
      let line = 0;
      let line1 = 0;
      let line2 = 0;
      let line3 = 0;
      let typePrize = 0;
      let hasBingo = false;
      
      // POR CARTELA
      this.cardsPlayer.forEach((element) => {
        line = 0;
        line1 = 0;
        line2 = 0;
        line3 = 0;
        typePrize = 0;

        let bolas = JSON.parse(element.bolas);
        let sorted = this.sortedNumbers.map(item => item.bola);
        
        sorted = sorted.filter((ball, index, self) => {
          return self.findIndex((b) => b === ball) === index;
        });
        
        // POR BOLA
        bolas.forEach((el, idx) => {
          if (idx % 5 === 0) {
            line += 1;
          }

          if(sorted.includes(el)) {
            if(line === 1) line1++;
            if(line === 2) line2++;
            if(line === 3) line3++;
          }
        });

        if(line1 === 5) typePrize++;
        if(line2 === 5) typePrize++;
        if(line3 === 5) typePrize++;

        // Se teve mais linha completada desde a última premiação
        if (typePrize > this.lastPrize) {
          this.lastPrize += 1;

          this.hasPrize = true;

          if (this.lastPrize == 1) {
            jQuery("#award, #img-line").fadeIn();
          }

          if (this.lastPrize == 2) {
            jQuery("#award, #img-line-two").fadeIn();
          }

          if (this.lastPrize == 3) {
            jQuery("#award, #img-bingo").fadeIn();
            hasBingo = true;
          }

          if (this.lastPrize == 1 || this.lastPrize == 2 || this.lastPrize == 3) {
            setTimeout(() => {
              jQuery('#award').addClass('shown');
            }, 500);
            
            if (window.hideAwardAlertTimeout) {
              clearTimeout(window.hideAwardAlertTimeout);
            }
            
            window.hideAwardAlertTimeout = setTimeout(() => {
              jQuery('#award, #award img').fadeOut();
            }, 4000);
          }
        }
      });
      
      return hasBingo;
    },

    updateLiveUsersCountCurrentRound() {
      this.$http.get("/live/update-stats-current-round");
    },
  },
};
</script>
