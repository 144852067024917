import io from 'socket.io-client';

export class Socket {
    constructor() {
        this.socket                      = null;
        this.socketConnecting            = false;
        this.socketConnectingElapsedTime = 0;
    }

    initialize(ioURL = null) {
        return new Socket().initializeSocket(ioURL || process.env.VUE_APP_SOCKET_URL_PORT);
    }

    initializeSocket(ioURL) {
        this.socket = io(ioURL, {
            transports: ['websocket']
        });

        this.socketConnecting            = false;
        this.socketConnectingElapsedTime = 0;

        this.socket.on('connect', () => {
            this.socketConnecting            = false;
            this.socketConnectingElapsedTime = 0;

            this.socket.emit('join_room', 'bingo');
            //console.log('Socket connected: ' + this.socket.id);
        });

        setInterval(() => {
            if (!this.socket.connected) {
                if (!this.socketConnecting || this.socketConnectingElapsedTime > 5) {
                    this.socketConnecting            = true;
                    this.socketConnectingElapsedTime = 0;
                    this.socket.connect();
                }

                this.socketConnectingElapsedTime++;
            }
        }, 1000);

        return this.socket;
    }
}

export default new Socket;
