<template>
  <div class="modal-schedule-round">
    <div class="d-flex justify-content-between">
      <h3>Agendar rodada</h3>
      <button type="button" @click="close">
        <h3>&#x2715;</h3>
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col" class="text-center">Total prêmio</th>
          <th scope="col" class="text-center">Cartelas vendidas</th>
          <th scope="col" class="text-center">Ação</th>
        </tr>
      </thead>
      <tbody v-for="(premio, index) in premiacoes" :key="index">
        <tr>
          <td>{{ premio.data_hora | moment }}</td>
          <td class="text-center">{{ $currency }} {{ premio.total_valor && Number(premio.total_valor) >
            Number(premio.valor_minimo) ? premio.total_valor : premio.premio_garantido }}</td>
          <td class="text-center">{{ premio.total_cartelas || '0' }}</td>
          <td class="text-center">
            <div v-if="!executando">
              <button class="btn btn-success btn-sm" @click="activeRound(premio.id)">Ativar</button>
            </div>
            <div v-else>
              <span>Aguarde...</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";

export default {

  name: "ScheduleRound",
  data() {
    return {
      premiacoes: [],
      executando: false,
    };
  },
  filters: {
    moment: function (date) {
      moment.locale("pt-br");
      return moment(date).format("DD/MM/YYYY LT");
    },
  },
  beforeMount() {
    this.getPremiacaoDesativada();
  },
  methods: {
    moment: function () {
      return moment();
    },

    getPremiacaoDesativada() {
      this.$http.get("premiacoes-desativada").then((res) => {
        if (!res.data.errors) {
          this.premiacoes = res.data;
        } else {
          console.error(res);
        }
      });
    },

    activeRound(id) {
      this.executando = true;
      let data = {
        id: id
      }
      this.$http.post("ativar-premiacao", data).then((res) => {
        if (!res.data.errors) {
          this.executando = false;
          this.getPremiacaoDesativada();
          alert('Rodada agendada ativada com sucesso!');
          this.close();
        } else {
          console.error(res);
        }
      });
    },

    close() {
      this.$parent.toggleScheduleRound();
    },
  }
};
</script>
