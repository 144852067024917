<template>
  <div class="splash-screen"
       v-if="visible">
    <div class="splash-screen__content">
      <img src="~@/assets/images/logo-bingo.webp"
           class="splash-screen__logo"
           alt="Bingo Everest">
      <div class="splash-screen__progress">
        <div class="splash-screen__progress-bar"
             :style="{ width: progress + '%' }">
          <img src="~@/assets/images/player/ball-splash-screen.webp"
               class="splash-screen__progress-bar-icon"
               style="display:none"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible : true,
      progress: 5
    }
  },
  mounted() {
    let logo = document.querySelector('.splash-screen__logo');
    logo.onload = function () {
      document.querySelector('.splash-screen__progress-bar-icon').style.display = 'block';
    };
    
    let interval = setInterval(() => {
      this.progress += 1;
      
      if (this.progress >= 100) {
        this.visible = false;
        clearInterval(interval);
      }
    }, 50);  
  }
}
</script>

<style scoped>
.splash-screen {
  background: url('~@/assets/images/player/bg-splash-screen-md.jpg') no-repeat center center #333;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.splash-screen:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #333;
  opacity: 0.4;
  z-index: -1;
}

.splash-screen__content {
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 150px;
}

.splash-screen__logo {
  max-width: 100%;
}

.splash-screen__progress {
  width: 80%;
  position: relative;
}

.splash-screen__progress:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  height: 2px;
  background-color: #d2d2d2;
  border-radius: 10px;
  z-index: 1;
}

.splash-screen__progress-bar {
  height: 8px;
  background-color: #feb504;
  transition: width 0.3s ease;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}

.splash-screen__progress-bar-icon {
  position: absolute;
  right: -10px;
  top: -14px;
  transform: translateX(-5px);
  width: 40px;
  height: 40px;
}

/*https://getbootstrap.com/docs/5.3/layout/breakpoints*/

@media (max-width: 1600px) {
  .splash-screen__content {
    max-width: 35%;
  }
}

@media (max-width: 1200px) {
  .splash-screen__content {
    max-width: 40%;
  }
}

@media (max-width: 992px) {
  .splash-screen__content {
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .splash-screen__content {
    max-width: 70%;
  }
}

@media (max-width: 576px) {
  .splash-screen__content {
    max-width: 85%;
  }
}
</style>
